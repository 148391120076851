import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MdClose, MdOutlineAdd } from 'react-icons/md';
import ToggleSwitch from "./ToggleSwitch";
import axiosConfig from "../Service/axiosConfig";
import { PopupModal } from "../Components";

const RadiologistAvailability = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const initialSlots = [0];
    const initialAvailable = null;
    const { id } = useParams();
    const [popupType, setpopupType] = useState("")
    const [postData, setPostData] = useState({
        "user": '',
        "schedule": []
    })
    const [daysOfWeek, setDaysOfWeek] = useState(
        days.map((day, index) => ({
            index,
            day,
            slots: initialSlots,
            available: initialAvailable,
        }))
    );

    const handleAvailabilityChange = (e, id) => {
        const updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[id].available = e.target.checked;
        updatedDaysOfWeek[id].slots = [{}];
        setDaysOfWeek(updatedDaysOfWeek);
    };

    const handleAddSlot = (day) => {
        const updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[day.index].slots.push({})
        setDaysOfWeek(updatedDaysOfWeek)
    }

    const handleRemoveSlot = (day, i) => {
        let updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[day.index].slots.splice(i, 1)
        setDaysOfWeek(updatedDaysOfWeek)
    }

    const getRadiologistAvailabilty = async () => {
        try {
            const response = await axiosConfig.get(`/account/user-availabilities/?user=${id}&days=`);
            let apiData = response.data.results;
            console.log(apiData, "apiData...")
            if (apiData) {
                const updatedDaysOfWeek = daysOfWeek.map((dayInfo) => {
                    const matchingSlots = apiData.filter((apiSlot) => apiSlot.days === dayInfo.day);
                    const formattedArray = matchingSlots.map(({ from_time, to_time }) => ({
                        from_time,
                        to_time,
                    }));
                    if (matchingSlots.length > 0) {
                        return {
                            ...dayInfo,
                            slots: formattedArray,
                            available: true
                        };
                    }
                    return dayInfo;
                });
                setDaysOfWeek(updatedDaysOfWeek);
            }
        } catch (error) {
            console.log("Error getting availbility : ", error)
        }
    }

    const addAvailability = async () => {
        let updatedPostData = {
            ...postData,
            user: id,
            schedule: daysOfWeek
                .filter(({ day, slots }) => slots && slots.length > 0)
                .map(({ day, slots, available }) => ({
                    days: day,
                    timeslots: available ? slots.map(({ from_time, to_time }) => ({
                        from_time,
                        to_time,
                    })) : [],
                }))
        }

        console.log(updatedPostData, "inside addAvailability...")
        setPostData(updatedPostData)
        try {
            const response = await axiosConfig.post(`/account/user-availabilities/`, updatedPostData)
            console.log(response)
            setpopupType('success')
        } catch (error) {
            console.log("Error adding availability : ", error)
            setpopupType('error')
        }
    }


    const handleTimeChange = (e, index, i) => {
        const { name, value } = e.target
        const updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[index].slots[i] = {
            ...updatedDaysOfWeek[index].slots[i],
            [name]: value,
        };
        setDaysOfWeek(updatedDaysOfWeek);
    }

    useEffect(() => {
        getRadiologistAvailabilty();
    }, [])

    return (
        <div className="ms-3">
            <div className="slot-parent">
                <div>Day</div>
                <div></div>
                <div>Status</div>
                <div className="d-flex">
                    <div className="form-control time">From</div>
                    <div className="form-control time">To</div>
                </div>
            </div>
            {daysOfWeek.map((day) => {
                return (
                    <>
                        <div className="slot-parent" key={day.index}>
                            <div className="slot-day">{day.day}</div>
                            <ToggleSwitch switch_id={day.index} daysOfWeek={daysOfWeek} handleAvailabilityChange={handleAvailabilityChange} />
                            <div className="slot-day">{day.available ? "open" : "closed"}</div>
                            <div>
                                {day.available && day.slots.map((slot, i) => {
                                    return (
                                        <div className="d-flex align-items-center">
                                            <input type="time" value={slot.from_time} name="from_time" className="form-control" onChange={(e) => handleTimeChange(e, day.index, i)} />
                                            <input type="time" value={slot.to_time} name="to_time" className="form-control" onChange={(e) => handleTimeChange(e, day.index, i)} />
                                            {i === 0 ?
                                                <MdOutlineAdd size={30} onClick={() => handleAddSlot(day)} /> :
                                                <MdClose size={30} onClick={() => handleRemoveSlot(day, i)} />
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                )
            })}
            <button className="btn btn-primary" onClick={() => addAvailability()}>Save</button>
            {popupType != "" &&
                <PopupModal popupType={popupType} Navigation={`/dashboard/radiologist/${id}`} setpopupType={setpopupType} Message="Slots Added Successfully " />
            }
        </div>
    )
}

export { RadiologistAvailability }