import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
const TariffCardAdd = () => {
    let Navigate=useNavigate();
    const [popupType, setpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [formerrorData, SetformErrorData] = useState({})
    const[AlertDeletePopupType,SetAlertDeletePopupType]=useState("")
    const MastersModal = Masters.TariffCard
    const [Error, SetError] = useState("")
    const [EditData, SetEditData] = useState()
    const[tariffid,SetTariffid]=useState()
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("/masters/tariffcategories/", Data).then(res => {
            console.log(res)
            SetTariffid(res.data.id)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.tariffcategory_code)
            let errorData = {}
            console.log(errorData)
            SetformErrorData(errorData)
            setpopupType("error")
        })
    }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Tariff Card</h5>
                    <NavLink to="/dashboard/masters/tariffcard" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="accordion-container">
                    <div className="accordion" id="accordionClient">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Add Tariff
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={`/dashboard/masters/tariffcard/${tariffid}/edit`}/>
                }
            
        </>
    )

}
export { TariffCardAdd }