import React, { useEffect, useState, useRef } from "react";
import { MdOutlineEdit } from "react-icons/md";
import "./Pages.css";
import axiosConfig from "../Service/axiosConfig"
import { useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai"
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles"
import { FormParser, DeletePopupModal, PopupModal } from "../Components";
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import { TiTick } from "react-icons/ti"
import Modal from "react-bootstrap/Modal";
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
import Masters from "../Components/masters.json"
import Select from 'react-select';
const ClientTariffData = ({ Code }) => {
    let { id } = useParams();
    const MastersModal = Masters.ModifyTariff
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const [deletePopupType, setDeletepopupType] = useState("")
    const [actionPermisson, SetActionPermission] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [Service, setService] = useState()
    const [EditTariffChange, setEditTarifChange] = useState([])
    const [check, setCheck] = useState(false)
    const [tableId, setTableId] = useState()
    const [selectedOption, setSelectedOption] = useState();
    const [Data, SetData] = useState([])
    const [Priorities, SetPriorities] = useState([])
    const options = [
        { value: 'CT', label: 'CT' },
        { value: 'MR', label: 'MR' },
        { value: 'DX', label: 'DX' },
        { value: 'PETCT', label: 'PETCT' },
    ];
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const GetPriorities = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/masters/priority/`);
            console.log(response, 'response')
            SetPriorities(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();
    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const HandleMouse = (row) => {
        setTableId(row.service);
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    };

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
        console.log(find_data, data, Code)
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const handleClient = () => {
        axiosConfig.get(`/client/clientagreement/?client=${id}`)
            .then(res => {
                res.data.results.forEach((res, ind) => {
                    GetData(res.primary_tariff)
                })
            })

            .catch(error => {
                console.log(error)
            })
    }
    const GetData = async (tariff_id) => {
        setLoading(true);
        try {
            const subdepartmentCode = selectedOption ? selectedOption.value : "";
            const response = await axiosConfig.get(`/client/clienttariff/${tariff_id}/custom_pagination/?page=${currentPage}&page_size=${RowPerPage}&subdepartment_code=${subdepartmentCode}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`}`);
            SetData(response.data.results);
            setExportData(response.data.results)
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const handleTariff = () => {
        EditTariffChange.map((tarif, idx) => {
            axiosConfig.patch(`/client/clienttariff/${tarif[`priority_id${idx + 1}`]}/`, { "serice_amount": tarif[`serice_amount_${idx + 1}`] }).then(res => {
                console.log(res)
                setpopupType("success")
                handleClient()
                setService()
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            })
        })
    }
    const sortedPriorities = Priorities.sort((a, b) => a.id - b.id);
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Service Name",
            selector: "service_name",
            cell: (row) => `${row.service_name}`,
            sortable: true,
            width: "300px",
        },
        {
            name: "Subdepartment Code",
            selector: "subdepartment_code",
            cell: (row) => `${row.subdepartment_code}`,
            sortable: true,
        },
        ...sortedPriorities.map((priority, idx) => ({
            name: priority.code,
            selector: `serice_amount_${idx + 1}`,
            sortable: true,
            cell: row => {
                const serviceAmount = row[`serice_amount_${idx + 1}`];
                const Actions = Service === row.service;

                if (Actions) {
                    return (
                        <>
                            <input className="form-control" defaultValue={serviceAmount} name={`serice_amount_${idx + 1}`} onChange={(e) => HandleTariffChange(e, row[`priority_id${idx + 1}`], idx + 1)} />
                        </>
                    );
                }

                return (
                    <>
                        <span>{serviceAmount}</span>
                    </>
                );
            }
        })),
        {
            name: "ACTIONS",
            selector: (row) => {
                const HandleEdit = async () => {
                    setService(row.service);
                    setEditTarifChange([
                        {
                            "serice_amount_1": row.serice_amount_1,
                            "priority_id1": row.priority_id1,
                        },
                        {
                            "serice_amount_2": row.serice_amount_2,
                            "priority_id2": row.priority_id2,
                        },
                        {
                            "serice_amount_3": row.serice_amount_3,
                            "priority_id3": row.priority_id3,
                        },
                    ]);
                };
                const ActionsAccrss = Service === row.service;
                const shouldShowActions = tableId === row.service;
                return (
                    <>
                        {shouldShowActions && (
                            <div className="row text-center">
                                <div className="col-12">
                                    {ActionsAccrss ? (
                                        <>
                                            <TiTick className="dashboard-tabe-data-actions  tick-size" onClick={handleTariff} />
                                        </>
                                    ) : (
                                        <>
                                            <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                );
            },
        },
    ];
    const HandleTariffChange = (e, priority, index) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const existingIndex = EditTariffChange.findIndex(item => item[`priority_id${index}`] === priority);
        if (existingIndex === -1) {
            setEditTarifChange(prevState => [...prevState, { [name]: value, [`priority_id${index}`]: priority }]);
        } else {
            setEditTarifChange(prevState => {
                const updatedValues = [...prevState];
                updatedValues[existingIndex] = { [name]: value, [`priority_id${index}`]: priority };
                return updatedValues;
            });
        }
    };
    const [ExportData, setExportData] = useState([])
    function handleDownloadExcel() {
        const header = ["S.No",
            "ServiceName",
            "SubDepartment",
            "General",
            "Night",
            "Stat",
        ];
        const body3 = ExportData && ExportData.map((res, index) => [index + 1, res.service_name, res.subdepartment_code, res.serice_amount_1, res.serice_amount_2, res.serice_amount_3])
        console.log(body3)
        downloadCSV({
            fileName: `${"clientTariff" + "." + "csv"}`,
            sheet: `${"clientTariff" + "." + "csv"}`,
            tablePayload: {
                header,
                body: body3,
            },
        });
    }
    function downloadCSV({ fileName, tablePayload }) {
        const csvContent = [
            tablePayload.header.join(','),
            ...tablePayload.body.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false);
        setpopupType("")
        setError("")
    }
    const handleShow = () => {
        setShow(true);
        setError("")
    }
    const [Error, setError] = useState("");
    const formSubmit = (Data) => {
        setLoading(true);
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.post("/client/upload_tariffs_csv/", { "file": Data.file, "client": id }).then(res => {
            console.log(res)
            setError("");
            setLoading(false);
            setpopupType("success")
            setShow(false)
        }).catch(error => {
            console.log(error.response.data)
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data;
                setError(errorMessage);
            }
            setpopupType("error")
        })
    }
    useEffect(() => {
        handleClient()
        GetPriorities();
        PermitData()

    }, [currentPage, RowPerPage, currentColumn, currentDirection, selectedOption, popupType]);
    return (
        <>
                <div className="table-header-menu">
                    <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                        <Dropdown.Toggle variant="">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                onClick={handleCheckboxClick}
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>Separated link</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <MdOutlineRefresh className="sidebar-icon-table" />
                    <CiMenuKebab className="sidebar-icon-table" />
                </div>
                <div className="table-responsive">
                    <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page, totalRows) => {
                            setCurrentPage(page);
                            GetData(currentColumn, currentDirection);
                        }}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        fixedHeader
                        customStyles={tableCustomStyles}
                        fixedHeaderScrollHeight="450px"
                        highlightOnHover
                        onRowMouseEnter={HandleMouse}
                        onRowMouseLeave={HandleRowmouseLeave}
                        subHeader
                        progressPending={loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                        subHeaderComponent={
                            <div className="w-25">
                                <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={options}
                                />
                            </div>
                        }>
                    </DataTable>
                </div>
                <div className="excel-button">
                    <button type="submit" className="btn btn-primary" onClick={handleDownloadExcel} >Download Excel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleShow}>Upload Excel</button>
                </div>
                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Modify Tariff
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            {
                                loading ?
                                    <div className="loader-container">
                                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                    </div>
                                    : null
                            }
                            <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} button="btn-block" />
                        </div>
                    </Modal.Body>
                </Modal>
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
            {popupType != "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} />
            }
        </>
    )
}

export { ClientTariffData }