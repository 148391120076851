import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";

const CaseTrack = () => {
    let { id } = useParams();
    console.log(id, "worklistid")
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    // const [tableId, setTableId] = useState()
    const [Data, SetData] = useState([])
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    // const HandleMouse = (row) => {
    //     setTableId(row.id)
    // }
    // const HandleRowmouseLeave = () => {
    //     setTableId()
    // }
    const GetData = async () => {
        setLoading(true);
        console.log(id, "jkbjbjb")
        try {
            const response = await axiosConfig.get(
                `/cases/case-tracks/?case=${id}&page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            console.log(response.data.results)
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Date & Time",
            selector: "created_date",
            cell: (row) => `${row.created_date}`,
            sortable: true
        },
        {
            name: "User",
            selector: "case.patient_name",
            cell: (row) => `${row.case.patient_name}`,
            sortable: true
        },
        {
            name: "Transactions/Status",
            selector: "message",
            cell: (row) => `${row.message}`,
            sortable: true
        },
        {
            name: "System IP",
            selector: "created_at",
            cell: (row) => `${row.created_at}`,
            sortable: true
        },
        
    ]
    return (
        <>
            <div className="table-responsive">
                <DataTable columns={columns} data={Data}  paginationTotalRows={totalRows}
                    paginationPerPage={RowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                        GetData(currentColumn, currentDirection);
                    }}
                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                    onSort={handleSort}
                    paginationServer
                    fixedHeader
                    customStyles={tableCustomStyles}
                    fixedHeaderScrollHeight="450px"
                    onSelectedRowsChange={({ selectedRows }) =>
                        console.log(selectedRows)
                    }
                    selectableRowsHighlight
                    highlightOnHover
                    // subHeader
                    // onRowMouseEnter={HandleMouse}
                    // onRowMouseLeave={HandleRowmouseLeave}
                    progressPending={loading}
                    progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}>
                </DataTable>
            </div>
        </>
    )
}

export { CaseTrack };