import { AiTwotoneHome } from "react-icons/ai";
import { GiEarthAmerica } from "react-icons/gi";
import { TbBuildingEstate } from "react-icons/tb";
import { MdLocationCity } from "react-icons/md";
import { MdAddLocation } from "react-icons/md";
import { BsFillBarChartLineFill } from "react-icons/bs";
import { MdAssignment } from "react-icons/md";
import { MdLowPriority } from "react-icons/md";
import { MdOutlineCallMerge } from "react-icons/md";
import { BsFillCreditCardFill } from "react-icons/bs";
import { MdViewModule } from "react-icons/md";
import { MdOutlineQueuePlayNext } from "react-icons/md";
import { FaUserLock } from "react-icons/fa";
import { ImUserCheck } from "react-icons/im";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { IoStatsChartOutline } from "react-icons/io5";
import { MdAddToQueue } from "react-icons/md";
import { MdDomain } from "react-icons/md";
import { MdDomainAdd } from "react-icons/md";
import { FaFileMedical } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { MdDomainDisabled } from "react-icons/md";
import { RiBillFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaSafari } from "react-icons/fa";
import { GoFileSubmodule } from "react-icons/go";
import { MdStopScreenShare } from "react-icons/md";
import { MdOutlineMedicalServices } from "react-icons/md";
let newDate = new Date();
let Newmonth = newDate.getMonth();
let previousMonth = Newmonth === 0 ? 12 : Newmonth;
let year = Newmonth === 0 ? newDate.getFullYear() - 1 : newDate.getFullYear();
if (previousMonth <= 9) {
  Newmonth = "0" + previousMonth;
} else {
  Newmonth = previousMonth;
}
console.log(Newmonth, year);
const sidebarData = {
  accounts: [
    {
      SubTitle: "Accounts",
      NavItems: [
        {
          label: "Accounts",
          path: "*/accounts",
          icon: <IoIosPeople fontSize="small" />,
        },
      ],
    },
    // {
    //     "SubTitle": "Clients",
    //     "NavItems": [
    //         // { "label": "clients", "submodule_code": "ARISMC23", "path": "/dashboard/users/clients", "icon": <IoIosPeople fontSize='small' /> },
    //     ]
    // }
  ],
  masters: [
    {
      SubTitle: "Settings",
      // { "label": "Domain", "submodule_code": "ARISMC1", "path": "/dashboard/masters/domains", "icon": <MdDomain fontSize="small" className=' configiration-icon' /> },
      // { "label": "ReferenceDomain Value", "submodule_code": "ARISMC2", "path": "/dashboard/masters/domains/value", "icon": <MdDomainAdd fontSize='small' className=' configiration-icon' /> }
      NavItems: [
        {
          label: "Settings",
          path: "/settings",
          icon: <IoIosPeople fontSize="small" />,
        },
        {
          label: "Services",
          path: "/dashboard/masters/services",
          icon: <MdOutlineMedicalServices fontSize="small" />,
        },
      ],
    },
    // {
    //     "SubTitle": "Locations",
    //     "NavItems": [
    //         { "label": "Country", "submodule_code": "ARISMC3", "path": "/dashboard/masters/countries", "icon": <GiEarthAmerica fontSize='small' className=' configiration-icon' /> },
    //         { "label": "State", "submodule_code": "ARISMC4", "path": "/dashboard/masters/states", "icon": <TbBuildingEstate fontSize='small' className=' configiration-icon' /> },
    //         { "label": "City", "submodule_code": "ARISMC5", "path": "/dashboard/masters/cities", "icon": <MdLocationCity fontSize='small' className=' configiration-icon' /> },
    //         { "label": "Area", "submodule_code": "ARISMC6", "path": "/dashboard/masters/areas", "icon": <AiTwotoneHome fontSize='small' className=' configiration-icon' /> }
    //     ]
    // },
    // {
    //     "SubTitle": "Configurations",
    //     "NavItems": [
    //         { "label": "Department", "submodule_code": "ARISMC7", "path": "/dashboard/masters/departments", "icon": <MdAddLocation fontSize='small' /> },
    //         { "label": "Sub Department", "submodule_code": "ARISMC8", "path": "/dashboard/masters/sub-departments", "icon": <MdAddToQueue fontSize='small' /> },
    //         { "label": "Services", "submodule_code": "ARISMC9", "path": "/dashboard/masters/services", "icon": <BsFillBarChartLineFill fontSize='small' /> },
    //         { "label": "Service Group", "submodule_code": "ARISMC31", "path": "/dashboard/servicegrouplist", "icon": <IoStatsChartOutline fontSize='small' /> },
    //         { "label": "Priority", "submodule_code": "ARISMC11", "path": "/dashboard/masters/priority", "icon": <MdLowPriority fontSize='small' /> },
    //         { "label": "TariffType", "submodule_code": "ARISMC12", "path": "/dashboard/masters/tarifftype", "icon": <MdOutlineCallMerge fontSize='small' /> },
    //         { "label": "Tariff Card", "submodule_code": "ARISMC13", "path": "/dashboard/masters/tariffcard", "icon": <BsFillCreditCardFill fontSize='small' /> },
    //         { "label": "Module", "submodule_code": "ARISMC14", "path": "/dashboard/masters/module", "icon": <MdViewModule fontSize='small' /> },
    //         { "label": "Role", "submodule_code": "ARISMC18", "path": "/dashboard/masters/role", "icon": <ImUserCheck fontSize='small' /> },
    //         { "label": "Role Module Permission", "submodule_code": "ARISMC19", "path": "/dashboard/masters/rolemodulepermission", "icon": <BsFillGrid3X2GapFill fontSize='small' /> }
    //     ]
    // },
  ],
  billing: [
    {
      SubTitle: "Billing",
      // "NavItems": [
      //     { "label": "Case Upload", "submodule_code": "ARISMC24", "path": "/dashboard/billing/upload-cases", "icon": <FaUpload fontSize='small' /> },
      // ]
      NavItems: [
        {
          label: "Billing",
          path: "*/billing",
          icon: <IoIosPeople fontSize="small" />,
        },
      ],
    },
    // {
    //     "SubTitle": "Client Billed Services",
    //     "NavItems": [
    //         { "label": "Client Bill", "submodule_code": "ARISMC25", "path": `/dashboard/billing/client-bills/?month=${year}-${Newmonth}`, "icon": <RiBillFill fontSize='small' /> },
    //         { "label": "Domestic", "submodule_code": "ARISMC26", "path": "/dashboard/billing/domestic", "icon": <FaUserAlt fontSize='small' /> },
    //         { "label": "International", "submodule_code": "ARISMC27", "path": "/dashboard/billing/international", "icon": <FaGlobe fontSize='small' /> },
    //         { "label": "ARI", "submodule_code": "ARISMC28", "path": "/dashboard/billing/ari", "icon": <FaSafari fontSize='small' /> },
    //     ]
    // },
    // {
    //     "SubTitle": "Final Bill",
    //     "NavItems": [
    //         { "label": "Invoices", "submodule_code": "ARISMC29", "path": `/dashboard/billing/client-final-bills/?month=${year}-${Newmonth}`, "icon": <RiBillFill fontSize='small' /> }
    //     ]
    // },
  ],
  worklists: [
    {
      SubTitle: "Work List",
      NavItems: [
        {
          label: "My Work List",
          path: "/dashboard/crm-dashboard",
          submodule_code: "ARISMC30",
          icon: <IoIosPeople fontSize="small" />,
        },
      ],
    },
    // {
    //     "SubTitle": "Tickets",
    //     "NavItems": [
    //         { "label": "Tickets", "path": "/dashboard/work-list/tickets", "icon": <IoIosPeople fontSize='small' /> }
    //     ]
    // }
  ],
  finance: [
    {
      SubTitle: "Finance",
      NavItems: [
        {
          label: "Finance",
          path: "/dashboard/finance/finance",
          icon: <IoIosPeople fontSize="small" />,
        },
      ],
    },
  ],
  reports: [
    {
      SubTitle: "Reports",
      NavItems: [
        {
          label: "Reports",
          path: "/dashboard/reports/reports",
          icon: <IoIosPeople fontSize="small" />,
        },
      ],
    },
  ],
  assets: [
    {
      SubTitle: "Assets",
      NavItems: [
        {
          label: "Assets",
          path: "*/assets",
          icon: <IoIosPeople fontSize="small" />,
        },
      ],
    },
  ],
};
export default sidebarData;
