import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const BillHeadEdit = () => {
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")

    const MastersModal=Masters.BillHead
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/billheads/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                code:error.response.data.code||["null"],
                name:error.response.data.name||["null"],
                    })
                    console.log(ErrorData[0])
                    if(ErrorData[0].code[0]==="bill head with this code already exists."){
                        SetError("Code already Exists")
                    }
                    if(ErrorData[0].name[0]==="bill head with this name already exists."){
                        SetError("Name Already Exists")
                    }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/billheads/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit BillHead {id}</h5>
                    <NavLink to="/dashboard/masters/billhead" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/billhead" />
                }
            
        </>
    )

}
export {BillHeadEdit}