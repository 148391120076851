import React, {useState} from "react";
import './ToggleSwitch.css'

const ToggleSwitch = ({ switch_id, daysOfWeek, handleAvailabilityChange }) => {
    return (
        <div>
            <input
            className="toggle-switch"
                type="checkbox"
                name="checkbox"
                id={`toggle ${switch_id}`}
                checked={daysOfWeek[switch_id].available}
                onChange={(e) => handleAvailabilityChange(e,switch_id)}
            />
            <label for={`toggle ${switch_id}`} className="switch"></label>
        </div>
    )
}

export default ToggleSwitch