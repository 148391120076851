import React, { useState,useEffect } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';
const ServiceGroup = ({Code}) => {
    const [popupType, setpopupType] = useState("");
    const [formerrorData, SetformErrorData] = useState({})
    const MastersModal = Masters.Country
    const [Error, SetError] = useState("")
    const [search, SetSearch] = useState({})
    const[filteServices,setFilterServices]=useState([])
    let {id}=useParams();
    const[lodaing,setLodaing]=useState(true)
    const [Data, setData] = useState({
        modality_name:"",
        "modality": null,
        "group_name": "",
        "serice_amount_INR": "",
        "serice_amount_US": "",
        "description": "",
        active_from: "",
        active_to: "",
        "service_ids": []
    })
    let options = [
        { value: 1, label: 'CT' },
        { value: 2, label: 'MR' },
        { value: 3, label: 'DX' },
        { value: 4, label: 'PETCT' },
    ];
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/services/?sub_department=${Data.modality_name}&page=1&page_size=20`)
            setFilterServices(responce.data.results)
            let count = Math.ceil(responce.data.count /20)
                        let number = []
                        for (let i = 1; i < count + 1; i++) {
                            if (i != 1) {
                                number.push(i)
                            }
                        }
                        number && number.forEach((page) => {
                            setTimeout(async function () {
                                const response = await axiosConfig.get(`/masters/services/?sub_department=${Data.modality_name}&page=${page}&page_size=20`)
                                const pageData = response.data.results
                                setFilterServices(prevData => [...prevData, ...response.data.results]);
                            }.bind(this), 1000)
                        })
        }
        catch (error) {
            console.log(error)
            
        }

    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/masters/services/?sub_department=${Data.modality_name}&search=${value}`).then(res => {
            setFilterServices(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const GetEditData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/servicegroups/${id}/`) 
            const responce1=await axiosConfig.get(`/masters/services/?sub_department=&service_group=${id}`)
            console.log(responce1.data.results)
            let data=[]
            responce1.data.results.forEach(res=>{
                data.push(res.id)
            })
            console.log(data)
            setData({...responce.data,service_ids:data})
        }
        catch (error) {
            console.log(error)
            
        }

    }
    const formSubmit = (e) => {
       e.preventDefault();
       if(id){
        axiosConfig.patch(`/masters/servicegroups/${id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            
            setpopupType("error")
        })
       }
       else{
        axiosConfig.post("/masters/servicegroups/", Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            
            setpopupType("error")
        })
       }
    }

    const HandleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setData({ ...Data, [name]: value })
    }
    const handleSelectChange = (selectedOption, name) => {
        if(name==="modality"){
            setData({ ...Data, [name]: selectedOption.value,"modality_name":selectedOption.label })
            setLodaing(false)
        }
        else{
            setData({ ...Data, [name]: selectedOption.value })
        }
    };
      console.log(Data)
      const handleModalitys = (e) => {
        const { name, value} = e.target;
      
        setData((prevPreference) => {
            const updatedArray = [...prevPreference[name]]; 
            if (updatedArray.includes(parseFloat(value))) {
             const index = updatedArray.indexOf(parseFloat(value));
              updatedArray.splice(index, 1);
            } else {
              updatedArray.push(parseFloat(value));
            }
        
            return {
              ...prevPreference,
              [name]: updatedArray,
            };
          });
      };
      function extractDate(dateTimeString) {
        const dateOnly =dateTimeString?dateTimeString.split('T')[0]:null;
        return dateOnly;
      }
    useEffect(() => {
        if(id && lodaing){
            GetEditData()
        }
        if(Data.modality){
            GetData();
        }
    }, [Data.modality])
    return (
        <>
            
                {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Country</h5>
                    <NavLink to="/dashboard/servicegrouplist" ><button className="btn btn-white">Manage</button></NavLink>
                </div> */}
                <div className="service-group-form">
                    <form>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label">Modality:</label>
                                    <Select options={options} placeholder="modality" value={options.find(res => res.value === Data.modality)} onChange={(selectedOption) => handleSelectChange(selectedOption, "modality")} required />
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label>Group Title:</label>
                                <input type="text" className="form-control" placeholder="group Title" name="group_name" onChange={HandleChange} value={Data.group_name} required />
                            </div>
                            <div className="form-group col-6">
                                <label>Group Price USD:</label>
                                <input type="text" className="form-control" placeholder="group Title" name="serice_amount_US" onChange={HandleChange} value={Data.serice_amount_US} required />
                            </div>
                            <div className="form-group col-6">
                                <label>Group Price INR:</label>
                                <input type="text" className="form-control" placeholder="group Title" name="serice_amount_INR" onChange={HandleChange} value={Data.serice_amount_INR} required />
                            </div>
                            <div className="form-group col-6">
                                <label >Active From:</label>
                                <input type="date" className="form-control" placeholder="Active From" name="active_from" onChange={HandleChange} value={extractDate(Data.active_from)} required/>
                            </div>
                            <div className="form-group col-6">
                                <label >Active To:</label>
                                <input type="date" className="form-control" placeholder="Active To" name="active_to" onChange={HandleChange} value={extractDate(Data.active_to)} required/>
                            </div>
                            <div className="form-group col-12">
                                <label>Description:</label>
                                <textarea className="form-control" rows="2" placeholder="Description" name="description" onChange={HandleChange} required value={Data.description}></textarea>
                            </div>
                        </div>
                       {Data.modality && <div>
                        <div className="row">
                        <div className="form-group col-6">
                         <label>Service:</label>
                        </div>
                        <div className="form-group col-6 d-flex justify-content-end">
                        <input type="text" className="form-control w-50" name="search" value={search.search} onChange={HandleSearch} />
                        </div>
                        </div>
                       <div className="container cons-fomr row">
                        {
                            filteServices.map(res=>{
                               return(
                                <div className="form-group col-4">
                                        <div class="form-check form-check-inline form-check-lable">
                                            <input class="form-check-input ari-checbox" type="checkbox" id={res.id} value={res.id} checked={Data.service_ids && Data.service_ids.includes(res.id)} onChange={handleModalitys}  name="service_ids"  />
                                            <label class="form-check-label" htmlFor={res.id}>{res.service_name}</label>
                                        </div>
                                    </div>
                               )
                            })
                        }
                        </div>
                        </div>}
                        <div className="text-end">
                        <button type="submit" className={`btn btn-primary`} onClick={formSubmit}>Save</button>
                        </div>
                    </form>
                </div>

                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/servicegrouplist" />
                }
            
        </>
    )

}
export { ServiceGroup }