import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import DataTable from "react-data-table-component";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from "react-bootstrap";
import { MdOutlineRefresh } from "react-icons/md";
import { CiMenuKebab } from "react-icons/ci";
import Modal from "react-bootstrap/Modal";
import Masters from "../Components/masters.json";
import { IoCloseCircle } from "react-icons/io5";
import { FormParserNew } from "../Components/FormParser/FormParserNew";
const ServiceList = () => {
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [deletePopupType, setDeletepopupType] = useState("");
  const [loading, setLoading] = useState(false);
  const [RowId, SetRowId] = useState();
  const [actionPermisson, SetActionPermission] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [currentDirection, setCurrentDirection] = useState(null);
  const [modalTitle, setModalTitle] = useState("Add New");
  const [showModal, setShowModal] = useState(false);
  const [popupType, setpopupType] = useState("");
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [formData, setFormData] = useState({});
  let navigate = useNavigate();
  const [tableId, setTableId] = useState();
  const [search, SetSearch] = useState({
    search: "",
  });
  const [Data, SetData] = useState([]);
  const [Datafilter, SetDatafilter] = useState([]);
  const [mastersModal, setMastersModal] = useState(Masters.services);
  const [PerPage, setPerpage] = useState([]);
  const [show, setShow] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("Add Now");
  const handleModalShow = (modalName, visible, data, buttonTitle) => {
    if (modalName === "addNew") {
      setModalTitle("Add New");
      setShowModal(visible);
      setFormData({
        name: "",
        description: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        image: "",
        sort: "",
        slug: "",
      });
      setButtonTitle("Add Now");
    }
    if (modalName === "actions") {
      GetEditData(data);
      setModalTitle("Add Details");
      setShowModal(visible);
    }
  };
  const GetData = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `catlog/categories/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
          currentDirection === "asc"
            ? currentColumn
            : `-${currentColumn}&is_suspended=false`
        }`
      );
      SetData(response.data.results);
      setLoading(false);
      setTotalRows(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const toggleCheckbox = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    setCheck(true);
  };
  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };
  const [dataTableHeight, setDataTableHeight] = useState("500px");
  useEffect(() => {
    GetData();
  }, [currentPage, RowPerPage, currentColumn, currentDirection]);

  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    axiosConfig
      .get(`catlog/categories?search=${value}`)
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    SetSearch({ ...search, [name]: value });
  };
  const handleSort = (column, direction) => {
    setCurrentColumn(column.selector);
    setCurrentDirection(direction);
  };
  const HandleMouse = (row) => {
    setTableId(row.id);
  };
  const HandleRowmouseLeave = () => {
    setTableId();
  };
  const columns = [
    {
      name: "S.No",
      selector: "id",
      cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => `${row.name}`,
      sortable: true,
    },
    {
      name: "Description",
      cell: (row) => `${row.description || ""}`,
      sortable: true,
    },
    {
      name: "Meta Title",
      cell: (row) => `${row.meta_title}`,
      sortable: true,
    },
    {
      name: "Meta Description",
      cell: (row) => `${row.meta_description || ""}`,
      sortable: true,
    },

    {
      name: "Meta Keywords",
      cell: (row) => `${row.meta_keyword || ""}`,
      sortable: true,
    },
    {
      name: "Image",
      selector: "image",
      cell: (row) => {
        return <img src={row.image} alt="img" style={{ width: "50px" }} />;
      },
      sortable: true,
    },
    {
      name: "Sort",
      cell: (row) => `${row.sort || ""}`,
      sortable: true,
    },
    {
      name: "Slug",
      cell: (row) => `${row.slug}`,
      sortable: true,
    },
    {
      name: "ACTIONS",
      selector: (row) => {
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (id) => {
          await axiosConfig
            .delete(`catlog/categories/${id}/`)
            .then(() => {
              setDeletepopupType("success");
              SetAlertDeletePopupType("error");
              GetData();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const HandleView = () => {
          setShowModal(true);
          setModalTitle("Edit Service");
          setButtonTitle("Update");
          GetEditData(row);
        };

        return (
          <>
            {
              <div className="row mx-auto">
                {/* <div className="col-4">
                  <AiOutlineEye
                    className="dashboard-tabe-data-actions"
                    onClick={HandleView}
                  />
                </div> */}
                <div className="col-4">
                  <MdOutlineEdit
                    className="dashboard-tabe-data-actions"
                    onClick={HandleView}
                  />
                </div>
                <div className="col-4">
                  <MdDeleteOutline
                    className="dashboard-tabe-data-actions"
                    onClick={DeleteAlert}
                  />
                </div>
              </div>
            }
            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleModalClose = () => {
    setShowModal(false);
  };
  const GetEditData = async (data) => {
    try {
      const lead_response = await axiosConfig.get(
        `/catlog/categories/${data?.id}/`
      );
      delete lead_response.data.image;
      setFormData({ ...lead_response.data });
    } catch (error) {
      console.log("Error getting edit data : ", error);
    }
  };

  const addServices = async (Data) => {
    console.log("Data to be added:", Data);
    try {
      let user = localStorage.getItem("userId");
      axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
      if (Data.id) {
        const response = await axiosConfig.patch(
          `/catlog/categories/${Data.id}/`,
          Data
        );
        console.log("Service edited", response);
        setMessage("Service edited successfully");
      } else {
        await axiosConfig.post("/catlog/categories/", {
          ...Data,
          lead: user,
        });
        setMessage("Service added successfully");
      }
      setShowModal(false);
      setpopupType("success");
      GetData(); // Refresh data after adding
    } catch (error) {
      setpopupType("error");
      console.error("Error adding service: ", error);
    }
  };

  const HandleDelete = async (id) => {
    try {
      await axiosConfig.delete(`catlog/categories/${id}/`);
      setDeletepopupType("success");
      SetAlertDeletePopupType("error");
      GetData(); // Refresh data after deletion
    } catch (error) {
      setDeletepopupType("error");
      SetAlertDeletePopupType("error");
      console.error("Error deleting service: ", error);
    }
  };

  return (
    <>
      <div className="dashboard-header">
        <h5 className="dashboard-subtitle">View Services</h5>
        <button
          className="btn btn-add-new"
          onClick={() => handleModalShow("addNew", true, null)}
        >
          + Add New
        </button>
      </div>
      <div className="tablescroll-clientbill-parent">
        <div className="tablescroll-clientbill">
          <div className="table-header-menu"></div>
          <div className="">
            <DataTable
              columns={columns}
              data={Data}
              pagination
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page, totalRows) => {
                setCurrentPage(page);
              }}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              paginationServer
              customStyles={tableCustomStyles}
              fixedHeaderScrollHeight={dataTableHeight}
              selectableRows
              onSelectedRowsChange={({ selectedRows }) =>
                console.log(selectedRows)
              }
              selectableRowsHighlight
              highlightOnHover
              subHeader
              onRowMouseEnter={HandleMouse}
              onRowMouseLeave={HandleRowmouseLeave}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
              subHeaderComponent={
                <input
                  type="text"
                  className="form-control w-25"
                  name="search"
                  value={search.search}
                  onChange={HandleSearch}
                />
              }
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => handleModalShow("addNew", false, null)}
        dialogClassName="add-new-modal"
        aria-labelledby="custom-add-new"
      >
        <Modal.Header>
          <Modal.Title>
            <div className="rowBetweenCenter">
              <span>{modalTitle}</span>
              <IoCloseCircle color="#408083" onClick={handleModalClose} />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="custom-add-new">
          <div className="new-form">
            <FormParserNew
              modelObject={mastersModal}
              formData={formData}
              buttonTitle={buttonTitle}
              formSubmit={addServices}
            />
          </div>
        </Modal.Body>
      </Modal>
      {deletePopupType != "" && (
        <DeletePopupModal
          setDeletepopupType={setDeletepopupType}
          deletePopupType={deletePopupType}
        />
      )}
    </>
  );
};
export { ServiceList };
