import React, { useState, useEffect } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { MdOutlineEdit } from "react-icons/md";
const Preference = () => {
    const { id } = useParams();
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("")
    const [nonpreference, setnonpreference] = useState('');
    const [preferenceModal, setPreferenceModal] = useState('');
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState({
        is_dometic: false,
        is_internation: false,
        is_specialist_openion: false
    })
    const [referencedomainvalue, setreferencedomainvalue] = useState([])
    const [Edit, SetEditData] = useState([])
    const [preference, setpreference] = useState({
        "user": id,
        "preference_modalities": [],
        "nopreferences_modalities": [],
        "preference_speciality": [],
        "nopreferences_speciality": []
    })
    const handleEdit = (e) => {
        e.preventDefault()
        setReadOnly(false)
    }
    let options = [
        { value: 1, label: 'CT' },
        { value: 2, label: 'MR' },
        { value: 3, label: 'DX' },
        { value: 4, label: 'PETCT' },
    ];
    const HandleChange = (e) => {
        const { name, value } = e.target;
        SetData((prevData) => ({
            ...prevData,
            [name]: value.toLowerCase() === "true" ? !prevData[name] : false,
        }));
    };
    console.log(Data)
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/masters/referencedomainvalue/?domain=37&domain__code=`);
            setreferencedomainvalue(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }

    };
    const editData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/account/radiologist/?user=${id}`);
            console.log(response.data.results[0].user.is_dometic)
            console.log(response.data.results[0].user.is_internation)
            console.log(response.data.results[0].user.is_specialist_openion)
            const is_dometic = response.data.results[0].user.is_dometic;
            const is_internation = response.data.results[0].user.is_internation;
            const is_specialist_openion = response.data.results[0].user.is_specialist_openion;
            SetData({
                is_dometic,
                is_internation,
                is_specialist_openion
            });
            const updatedPreferences = response.data.results.map(element => ({
                id: element.id,
                preference_modalities: element.preference_modalities.map(res => res.id),
                nopreferences_modalities: element.nopreferences_modalities.map(res => res.id),
                preference_speciality: element.preference_speciality.map(res => res.id),
                nopreferences_speciality: element.nopreferences_speciality.map(res => res.id)
            }));
            if (updatedPreferences.length > 0) {
                setpreference(...updatedPreferences);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const handleSelectChange = (selectedOption, name) => {
        setpreference({ ...preference, [name]: [selectedOption.value], })
    };

    const handleModalitys = (e) => {
        const { name, value } = e.target;

        setpreference((prevPreference) => {
            const updatedArray = [...prevPreference[name]];
            if (updatedArray.includes(parseFloat(value))) {
                const index = updatedArray.indexOf(parseFloat(value));
                console.log(index)
                updatedArray.splice(index, 1);
            } else {
                updatedArray.push(parseFloat(value));
            }

            return {
                ...prevPreference,
                [name]: updatedArray,
            };
        });
        setPreferenceModal("")
        setnonpreference("")
    };
    console.log(preference)
    const formSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append("user", id)
        preference.preference_modalities.forEach(val => {
            formData.append("preference_modalities", val)
        })
        preference.nopreferences_modalities.forEach(val => {
            formData.append("nopreferences_modalities", val)
        })
        preference.preference_speciality.forEach(val => {
            formData.append("preference_speciality", val)
        })
        preference.nopreferences_speciality.forEach(val => {
            formData.append("nopreferences_speciality", val)
        })
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (preference.id) {
            axiosConfig.put(`/account/radiologist/${preference.id}/`, formData).then(res => {
                axiosConfig.patch(`/account/users/${id}/`, Data).then(res => {
                    console.log(res)
                    setReadOnly(true)
                    setPreferenceModal("")
                    setnonpreference("")
                    setpopupType("success")
                }).catch(error => {
                    console.log(error)
                    setpopupType("error")
                })
            }).catch(error => {
                console.log(error)
                console.log(error.response.data)
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data;
                    if (errorData.nopreferences_modalities) {
                        setnonpreference("non-preference modality is required");
                    }
                    if (errorData.nopreferences_speciality) {
                        setnonpreference("non-preference speciality  is required");
                    }
                    if (errorData.preference_modalities) {
                        setPreferenceModal("preference modality is required");
                    }
                    if (errorData.preference_speciality) {
                        setPreferenceModal("preference specilaity is required");
                    }
                } else {
                    setpopupType("error")
                }
                setpopupType("error")
            })
        }
        else {
            axiosConfig.post(`/account/radiologist/`, formData).then(res => {
                axiosConfig.patch(`/account/users/${id}/`, Data).then(res => {
                    console.log(res)
                    setReadOnly(true)
                    setPreferenceModal("")
                    setnonpreference("")
                    setpopupType("success")
                }).catch(error => {
                    console.log(error)
                    setpopupType("error")

                })
            }).catch(error => {
                console.log(error)
                console.log(error.response.data)
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data;
                    if (errorData.nopreferences_modalities) {
                        setnonpreference("non-preference modality is required");
                    }
                    if (errorData.nopreferences_speciality) {
                        setnonpreference("non-preference speciality  is required");
                    }
                    if (errorData.preference_modalities) {
                        setPreferenceModal("preference modality is required");
                    }
                    if (errorData.preference_speciality) {
                        setPreferenceModal("preference specilaity is required");
                    }
                } else {
                    setpopupType("error")
                }
            })
        }
    }
    useEffect(() => {
        GetData()
        editData();
    }, [])
    return (
        <div>
            <div className="prefrence-paddin">
                <div className="row">
                    <div className="col-3">
                        <div class="form-check form-check-inline form-check-lable">
                            <input class="form-check-input ari-checbox"
                                type="checkbox" id="is_dometic" value={true}
                                name="is_dometic"
                                checked={Data.is_dometic}
                                disabled={readOnly}
                                onChange={HandleChange}
                            />
                            <label class="form-check-label" for="is_dometic">Domestic</label>
                        </div>
                    </div>
                    <div className="col-3">
                        <div class="form-check form-check-inline form-check-lable">
                            <input class="form-check-input ari-checbox"
                                type="checkbox" id="is_internation" value={true}
                                name="is_internation"
                                checked={Data.is_internation}
                                disabled={readOnly}
                                onChange={HandleChange}
                            />
                            <label class="form-check-label" for="is_internation">International</label>
                        </div>
                    </div>
                    <div className="col-3">
                        <div class="form-check form-check-inline form-check-lable">
                            <input class="form-check-input ari-checbox"
                                type="checkbox" id="is_specialist_openion"
                                value={true} name="is_specialist_openion"
                                checked={Data.is_specialist_openion}
                                disabled={readOnly}
                                onChange={HandleChange}
                            />
                            <label class="form-check-label" for="is_specialist_openion">Specialist Opinion</label>
                        </div>
                    </div>
                </div>
                <div>
                    <h5 className="dashboard-subtitle">Preferences</h5>
                </div>
            </div>
            <div className="prefernce-border"></div>
            <div className="prefrence-paddin-add">
                {preferenceModal && (
                    <div className="alert alert-danger" role="alert">
                        {preferenceModal}
                    </div>
                )}
                <label >Modality:</label>
                <div className="form-group-pages">
                    {
                        options.map(val => {
                            return (
                                <div key={val.value}>
                                    <div className="chec-margin">
                                        <div class="form-check form-check-inline form-check-lable">
                                            <input class="form-check-input ari-checbox"
                                                type="checkbox" id={val.label} value={val.value}
                                                checked={preference.preference_modalities && preference.preference_modalities.includes(val.value)}
                                                name="preference_modalities"
                                                onChange={handleModalitys}
                                                disabled={readOnly}
                                            />
                                            <label class="form-check-label" for={val.label}>{val.label}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="form-group">
                    <label >Sub Speciality:</label>
                    <Select options={referencedomainvalue.map((res) => (
                        { "value": res.id, "label": res.code }
                    ))}
                        value={referencedomainvalue.map(res => ({ "value": res.id, "label": res.code })).find(val => preference.preference_speciality && preference.preference_speciality.includes(val.value))}
                        placeholder="Sub Speciality"
                        isDisabled={readOnly}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, "preference_speciality")} />
                </div>
            </div>
            <div className="prefrence-paddin">
                <h5 className="dashboard-subtitle">non - Preferences</h5>
            </div>
            <div className="prefernce-border"></div>
            <div className="prefrence-paddin-add">
                {nonpreference && (
                    <div className="alert alert-danger" role="alert">
                        {nonpreference}
                    </div>
                )}
                <label >Modality:</label>
                <div className="form-group-pages">
                    {
                        options.map(val => {
                            return (
                                <div key={val.value}>
                                    <div className="chec-margin">
                                        <div class="form-check form-check-inline form-check-lable">
                                            <input class="form-check-input ari-checbox"
                                                type="checkbox" id={val.label} value={val.value}
                                                checked={preference.nopreferences_modalities && preference.nopreferences_modalities.includes(val.value)}
                                                placeholder="Sub Speciality"
                                                name="nopreferences_modalities"
                                                onChange={handleModalitys}
                                                disabled={readOnly}
                                            />
                                            <label class="form-check-label" for={val.label}>{val.label}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="form-group">
                    <label >Sub Speciality:</label>
                    <Select options={referencedomainvalue.map((res) => (
                        { "value": res.id, "label": res.code }
                    ))}
                        value={referencedomainvalue.map(res => ({ "value": res.id, "label": res.code })).find(val => preference.nopreferences_speciality && preference.nopreferences_speciality.includes(val.value))}
                        placeholder="Sub Speciality"
                        isDisabled={readOnly}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, "nopreferences_speciality")} />
                </div>
            </div>
            {readOnly ? (
                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={(e) => handleEdit(e)}>
                    <div>Edit</div>
                    <MdOutlineEdit className="ms-5" />
                </button>
            ) : (
                <button className="btn btn-primary" style={{ marginLeft: '30px' }} onClick={formSubmit}>Save</button>
            )}
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </div>

    )
}
export { Preference }