import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import axiosConfig from "../Service/axiosConfig"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FormParser, DeletePopupModal, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import { Domestic } from "./Domestic";
import { International } from "./International";

const Remuneration = () => {
    const MastersModal = Masters.FixedPay;
    const { id } = useParams();
    console.log(id)
    const [key, setKey] = useState('Domestic');
    const [popupType, setpopupType] = useState("")
    const [readOnly, setReadOnly] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOption, setSelectedOption] = useState();
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [formData, setFormData] = useState({
        salary_type: 'Fixed Pay',
        monthly_salary: '',
        radiologist: id,
        fixed_target: '',
        no_of_cases_per_day: '',
        incentives: ''
    })
    const handleFormRadio = (name, value) => {
        setFormData({ ...formData, [name]: value })
    };
    const formSubmit = (formData) => {
        console.log(formData)
        const UpdateData = {
            salary_type: formData.salary_type,
            monthly_salary: formData.monthly_salary,
            radiologist: formData.radiologist,
            fixed_target: formData.fixed_target,
            no_of_cases_per_day: formData.no_of_cases_per_day,
            incentives: formData.incentives
        };
        console.log(UpdateData, "updatedata")
        console.log(formData.id)
        if (formData.id) {
            console.log(formData)
            axiosConfig.put(`/account/remunerationtype/${formData.id}/`, UpdateData).then(res => {
                console.log(res.data)
                setFormData(res.data)
                setpopupType("success")
            })
                .catch(error => {
                    console.log(error)
                    setpopupType("error")
                })
            setReadOnly(true)
        }
        else {
            axiosConfig.post(`/account/remunerationtype/`, UpdateData).then(res => {
                console.log(res.data)
                setFormData(res.data)
                setpopupType("success")
            })
                .catch(error => {
                    console.log(error)
                    setpopupType("error")
                })
            setReadOnly(true)
        }
    }
    const fetchData = () => {
        axiosConfig.get(`/account/remunerationtype/?radiologist=${id}`).then(res => {
            let NewData = { ...res.data.results[0], radiologist: res.data.results[0].radiologist.id }
            console.log(NewData)
            setFormData(NewData)
        })
            .catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        fetchData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection, selectedOption])
    return (
        <div className="printing-preference container-form">
            <form>
                <div className="form-group">
                    <div className="form-check-inline">
                        <label className="form-check-label ari-radio-label">
                            <input type="radio" className="ari-radio"
                                name="salary_type"
                                checked={formData.salary_type === "Fixed Pay"}
                                onChange={(e) => handleFormRadio("salary_type", "Fixed Pay")}
                            />Fixed Pay
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <label className="form-check-label ari-radio-label">
                            <input type="radio" className="ari-radio"
                                name="salary_type"
                                checked={formData.salary_type === "Pay Per Case"}
                                onChange={(e) => handleFormRadio("salary_type", "Pay Per Case")}
                            />Pay Per Case
                        </label>
                    </div>
                </div>
            </form>
            {formData.salary_type === "Fixed Pay" && (
                <div>
                    <FormParser modelObject={MastersModal}
                        formData={formData}
                        formSubmit={formSubmit}
                        buttonTitle='Save'
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                    />
                    {readOnly &&
                        <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                            <div>Edit</div>
                            <MdOutlineEdit className="ms-5" />
                        </button>
                    }
                </div>
            )}
            {formData.salary_type === "Pay Per Case" && (
                <div>
                    <div className="form-group">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="Domestic" title="Domestic">
                                <Domestic />
                            </Tab>
                            <Tab eventKey="International" title="International">
                                <International />
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            )
            }
            {
                popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </div >
    )
}
export { Remuneration }