import React, { useEffect, useRef, useState } from "react";
import { DashboardLayout, PopupModal, EditPopupModal } from "../Components";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { MdOutlineEdit, MdOutlineLocationOn, MdOutlineSupportAgent } from "react-icons/md";
import { FaRegHospital } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai"
import { useParams } from "react-router-dom";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import ConfirmModal from "../Components/PopupModal/ConfirmModal";
import { MdDeleteOutline } from "react-icons/md"
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab,CiEdit } from "react-icons/ci"
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
const ClientFinalBillsViewtwo = () => {
    const [clientData, setClientData] = useState([])
    const [popupType, setpopupType] = useState("");
    const [confirmType, setConfirmType] = useState("");
    const [EditPopupType, setEditpopupType] = useState("");
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [objectData, SetObjectData] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const downloadref = useRef();
    const [Page, setPage] = useState(0)
    let navigate = useNavigate();
    let { id, month } = useParams();
    const [Count, setCount] = useState(0)
    const [Datafilter, SetDatafilter] = useState([])
    const [smShow, setSmShow] = useState(false)
    const [check, setCheck] = useState(false)
    const [UpdateData, SetUpdateData] = useState({
        cbs_id: undefined,
        service_id: undefined,
        amount: "",
        service_name: ""
    })
    const [BillsInfo, SetBillsInfo] = useState({
        no_of_cases: 0,
        total_amount: 0,
        discount: 0,
        percentage: 0,
        net_amount: 0.0,
    })
    const [Search, setSearch] = useState()
    const [CategoryInfo, SetCategoryInfo] = useState({})
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };


    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [search, SetSearch] = useState({
        search: "",
    })
    const [select, Setselct] = useState({
        Month: ""
    })
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const [Services, SetServices] = useState([])
    const GetData = async (id, month) => {
        setLoading(true)
        console.log(id, month, 'id,month')
        try {
            const response = await axiosConfig.get(`/client/clientbilledservices/?casedetail__caseinfo__group=${id}&casedetail__caseinfo__billing_for_the_month__startswith=${month}&page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`)
            console.log(response.data.count)
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        }
        catch (error) {
            console.log(error)
        }
    }
    const GetServices = async () => {
        setLoading(true)
        if (localStorage.getItem("/master/services/")) {
            let data = localStorage.getItem("/master/services/")
            let jsondata = JSON.parse(data)
            SetServices(jsondata)
        }
        else {
            try {
                const responce = await axiosConfig.get(`/masters/services/?page=1&page_size=20`)
                const count = Math.ceil(responce.data.count / 20);
                    const allData = [...responce.data.results];
    
                    const fetchAllPages = [];
                    for (let i = 2; i <= count; i++) {
                        fetchAllPages.push(
                            axiosConfig.get(`/masters/services/?page=${i}&page_size=20`)
                                .then(response => response.data.results)
                                .catch(error => {
                                    console.log(error);
                                    return [];
                                })
                        );
                    }
    
                    const pagesData = await Promise.all(fetchAllPages);
    
                    pagesData.forEach(data => {
                        allData.push(...data);
                    });
    
                    SetServices(allData);
                    localStorage.setItem("/master/services/",JSON.stringify(allData))
                setLoading(false)
            }
            catch (error) {
                console.log(error)
            }
        }

    }
    const ClientData = async () => {
        try {
            const responce = await axiosConfig.get(`/client/get_invoice/?client=${id}&month=${month}`)
            console.log(responce.data)
            setClientData(responce.data[0])
            SetCategoryInfo({ 'CT': responce.data[1].CT ? responce.data[1].CT : 0, 'DX': responce.data[1].DX ? responce.data[1].DX : 0, "PETCT": responce.data[1].PETCT ? responce.data[1].PETCT : 0, "MR": responce.data[1].MR ? responce.data[1].MR : 0 })
            SetBillsInfo({ net_amount: responce.data[0] && responce.data[0].total_net_amount, total_amount: responce.data[0] && responce.data[0].total_amount, discount: responce.data[0] && responce.data[0].discount, })
            const discountAmount = responce.data[0].discount;
            const totalAmount = responce.data[0].total_amount;
            const discountPercentage = ((discountAmount / totalAmount) * 100).toFixed(0);
            console.log(discountPercentage)
            setPercentage({ "discount": discountPercentage > 0 ? discountPercentage : 0 })
        }
        catch (error) {
            console.log(error)
        }

    }
    const [dataTableHeight, setDataTableHeight] = useState('450px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`; 
        setDataTableHeight(newHeight);
    };
    useEffect(() => {
        calculateDataTableHeight();
        // GetServiceId()
        ClientData()
        GetData(id, month);
        GetServices();
        Setselct({ ...select, ['Month']: month })
        handlePercentage()
    }, [id, month, currentPage, RowPerPage, currentColumn, currentDirection]);
    const [URL, setURL] = useState()
    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        setURL(value)
        return navigate(`/dashboard/billing/client-bills/${id}/view/${value}`);

    }
    console.log(Data)
    const [CBSItem, SetclientbillEditData] = useState([])
    const [CBSEditData, SetCBSEditData] = useState({})

    const [count, setcount] = useState(0)
    const handleSave = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        const result = (BillsInfo.total_amount - value).toFixed(2)
        SetBillsInfo({ ...BillsInfo, "discount": value, 'net_amount': result })
        const discountPercentage = ((value / BillsInfo.total_amount) * 100).toFixed(2);
        setPercentage({ "discount": discountPercentage })
    };
    const [percentage, setPercentage] = useState({
        discount: 0
    })

    const handlePercentage = (e) => {
        const discountAmount = BillsInfo.discount;
        const totalAmount = BillsInfo.total_amount;
        const discountPercentage = ((discountAmount / totalAmount) * 100).toFixed(2);
        console.log(discountPercentage)
        setPercentage({ "discount": discountPercentage > 0 ? discountPercentage : 0 })
    };
    const PercentageChange = (e) => {
        const { name, value } = e.target
        const totalAmount = BillsInfo.total_amount;
        const discountAmount = (totalAmount * (value / 100)).toFixed(2);
        console.log(discountAmount)
        const net_amount = (totalAmount - discountAmount).toFixed(2)
        setPercentage({ ...percentage, [name]: value })
        SetBillsInfo({ ...BillsInfo, "discount": discountAmount, net_amount: net_amount })
    }

    const HandleSubmit = async () => {
        objectData.forEach(async (res) => {
            await axiosConfig.post(`/client/clientbilledservices/${res.cbs_id}/update_price/`, { 'service_id': res.service_id, 'amount': res.amount }).then(res => {
                console.log(res)
                // ClientData()
            }).catch(error => {
                console.log(error)
            })
        })
        await axiosConfig.post(`/client/client-invoices/${clientData.id}/update_invoice/`, { discount: BillsInfo.discount, invoice_type: "save" }).then(res => {
            console.log(res)
            ClientData()
            setEditpopupType("success")
        }).catch(error => {
            console.log(error)
            setEditpopupType("error")
        })
    }
    const HandleDownload = async () => {
        await axiosConfig.get(`/client/pdf/${BillsInfo.id}/`).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${clientData.client_name}.pdf`);
            document.body.appendChild(link);
            link.click();
        })
            .catch(error => {
                console.error('There was an error downloading the PDF', error);
            });
    }

    const HandleSearch = (e) => {
        const { name, value } = e.target
        const responce = Datafilter.filter(res => res.casedetail.caseinfo.patient_id && res.casedetail.caseinfo.patient_id.toLowerCase().includes(value.toLowerCase()) || res.casedetail.caseinfo.modality && res.casedetail.caseinfo.modality.toLowerCase().includes(value.toLowerCase()) || res.casedetail.case_study_description && res.casedetail.case_study_description.toLowerCase().includes(value.trim().toLowerCase()) || res.casedetail.case_study_description && res.casedetail.case_study_description.toLowerCase().includes(value.trim().toLowerCase()) || res.net_amount && res.net_amount.toString().includes(value) || res.casedetail.caseinfo.patient_name && res.casedetail.caseinfo.patient_name.toLowerCase().includes(value.toLowerCase()))
        SetData(responce)
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    console.log(clientData, BillsInfo)
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Patient ID",
            selector: "patient_id",
            cell: (row) => `${row.casedetail.caseinfo.patient_id}`,
            sortable: true,

        },
        {
            name: "Patient Name",
            selector: "patient_name",
            cell: (row) => `${row.casedetail.caseinfo.patient_name}`,
            sortable: true,
        },
        {
            name: "Age",
            selector: "age",
            cell: (row) => `${row.casedetail.caseinfo.age}`,
            sortable: true,
        },
        {
            name: "Gender",
            selector: "gender",
            cell: (row) => `${row.casedetail.caseinfo.gender}`,
            sortable: true,
        },
        {
            name: "Modality",
            selector: "modality",
            cell: (row) => `${row.casedetail.caseinfo.modality}`,
            sortable: true,
        },
        {
            name: "Study Description",
            selector: "case_study_description",
            cell: (row) => `${row.casedetail.case_study_description}`,
            sortable: true,
        },
        {
            name: "Billing Description",
            selector: "patient_name",
            cell: (row) => `${row.casedetail.billing_description}`,
            sortable: true,
        },
        {
            name: "Amount",
            selector: "price",
            cell: (row) => `${row.net_amount}`,
            sortable: true,
        },
        {
            name: "ACTIONS",
            selector: (row) => {
                const HandleEdit = async () => {
                    SetCBSEditData({ "id":row.id, "patient_name":row.casedetail.caseinfo.patient_name, "modality":row.casedetail.caseinfo.modality, "study_description":row.casedetail.case_study_description, "billing_description":row.casedetail.billing_description, "net_amount":row.net_amount })
                    try {
                        const response = await axiosConfig.get(`/client/clientbilledservices/${row.id}/get_estimation/`)
                        SetUpdateData({ 'amount': row.net_amount, 'cbs_id':row.id, 'service_id': response.data[1], "service_name":row.casedetail.billing_description })
                        setSmShow(true)
                        const existingIndex = objectData.findIndex((item) => item.cbs_id === id);
                        if (existingIndex === -1) {
                            SetObjectData([...objectData, { 'amount': row.net_amount, 'cbs_id': id, 'service_id': response.data[1], "service_name":row.casedetail.billing_description }]);
                        }
                        else {
                            const updatedValues = [...objectData];
                            updatedValues[existingIndex] = { 'amount': row.net_amount, 'cbs_id': id, 'service_id': response.data[1], "service_name":row.casedetail.billing_description };
                            SetObjectData(updatedValues);
                        }
                    }
                    catch (error) {
                        console.log(error)
                    }
                }
                return (
                    <>
                        <div className="row text-center" >
                        <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                            </div>
                        </div>
                    </>
                )

            },
        },
    ]
    const HandleUpdate = async () => {
        await axiosConfig.post(`/client/clientbilledservices/${UpdateData.cbs_id}/update_price/`, { 'service_id': UpdateData.service_id, 'amount': UpdateData.amount }).then(res => {
            console.log(res)
            // ClientData()
            setEditpopupType("success")
        }).catch(error => {
            console.log(error)
            setEditpopupType("error")
        })
        const findData = Data.findIndex(res => res.id === UpdateData.cbs_id)
        const updatedValues = [...Data];
        updatedValues[findData].casedetail.billing_description = UpdateData.service_name
        updatedValues[findData].net_amount = UpdateData.amount
        SetData(updatedValues)
        let sum = updatedValues.reduce(function (prev, current) {
            return prev + +current.net_amount
        }, 0);
        const result = (sum - BillsInfo.discount).toFixed(2)
        const discountPercentage = ((BillsInfo.discount / sum) * 100).toFixed(2);
        setPercentage({ "discount": discountPercentage > 0 ? discountPercentage : 0 })
        SetBillsInfo({ ...BillsInfo, net_amount: result, total_amount: sum })
        setSmShow(false)
    }
    const handleSelectChange = async (selectedOption, name) => {
        try {
            const response = await axiosConfig.post(`/client/clientbilledservices/${UpdateData.cbs_id}/get_estimation/`, { 'service_id': selectedOption.value })
            SetUpdateData({ ...UpdateData, [name]: selectedOption.value, ["service_name"]: selectedOption.label, ['amount']: response.data[0] })
            const existingIndex = objectData.findIndex((item) => item.cbs_id === UpdateData.cbs_id);
            if (existingIndex === -1) {
                SetObjectData([...objectData, { [name]: selectedOption.value, ["service_name"]: selectedOption.label, "cbs_id": UpdateData.cbs_id, ['amount']: response.data[0] }]);
            }
            else {
                const updatedValues = [...objectData];
                updatedValues[existingIndex] = { [name]: selectedOption.value, ["service_name"]: selectedOption.label, "cbs_id": UpdateData.cbs_id, ['amount']: response.data[0] };
                SetObjectData(updatedValues);
            }
        }
        catch (error) {
            console.log(error)
        }
    };
    const HandleChangeEdit = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        SetUpdateData({ ...UpdateData, [name]: value })
        const existingIndex = objectData.findIndex((item) => item.cbs_id === UpdateData.cbs_id);
        if (existingIndex === -1) {
            SetObjectData([...objectData, { ["service_id"]: UpdateData.service_id, ["service_name"]: UpdateData.service_name, "cbs_id": UpdateData.cbs_id, ['amount']: value }]);
        }
        else {
            const updatedValues = [...objectData];
            updatedValues[existingIndex] = { ["service_id"]: UpdateData.service_id, ["service_name"]: UpdateData.service_name, "cbs_id": UpdateData.cbs_id, ['amount']: value };
            SetObjectData(updatedValues);
        }
    }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Bills:</h5>
                    <input type="Month" name="Month" className="form-control month-selector" value={select.Month} onChange={HandleSelect} />
                </div>
                <div className="clientbill-info">
                    <div className="clientinformation">
                        <h5 className="total-amount">Client Details:</h5>
                        <div className="row">
                            <div className="col-md-3">
                                <p><FaRegHospital /> #{clientData.client && clientData.client} {clientData.client_name && clientData.client_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><MdOutlineLocationOn /> {clientData.email && clientData.email} | {clientData.phone && clientData.phone}</p>
                            </div>
                            <div className="col-md-3">
                                <p><MdOutlineSupportAgent /> {clientData.address && clientData.address}</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="billingModality">
                                <p className="billingModality">Total No. of Cases: {clientData.quantity}</p>
                                <p className="billingModality">CT: {CategoryInfo.CT}</p>
                                <p className="billingModality">DX: {CategoryInfo.DX}</p>
                                <p className="billingModality">MR: {CategoryInfo.MR}</p>
                                <p className="billingModality">PETCT: {CategoryInfo.PETCT}</p>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-primary" onClick={HandleSubmit}>Save</button>
                                </div>
                                <div className="col-md-4">
                                    <button type="submit" className="btn btn-primary" onClick={HandleDownload}>Download Invoice</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <p><span className="total-amount">Total Amount:</span>{Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(parseFloat(BillsInfo.total_amount).toFixed(2))}</p>
                            <div className="d-flex gap-1">
                                <div className="form-group">
                                    <label className="form-check-label" for="inlineRadio1">Flat Discount</label>
                                    <input type="text" placeholder="Discount" className="form-control form-discount" name="discount" value={BillsInfo.discount} onChange={handleSave} />
                                </div>
                                <div className="form-group">
                                    <label>Discount percentage</label>
                                    <div className="d-flex align-items-center">
                                        <input type="text" placeholder="Discount" className="form-control form-discount" name="discount" value={percentage.discount} maxLength="2" onChange={PercentageChange} />
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                            <p><span className="net-amount">Net Amount:</span> {Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(parseFloat(BillsInfo.net_amount).toFixed(2))}</p>
                        </div>
                    </div>
                    <div className="tablescroll-clientbill">
                        <div className="table-header-menu">
                            <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                <Dropdown.Toggle variant="">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onClick={handleCheckboxClick}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item>Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else here</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <MdOutlineRefresh className="sidebar-icon-table" />
                            <CiMenuKebab className="sidebar-icon-table" />
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page, totalRows) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer
                                fixedHeader
                                customStyles={tableCustomStyles}
                                fixedHeaderScrollHeight={dataTableHeight}
                                selectableRows
                                onSelectedRowsChange={({ selectedRows }) =>
                                    console.log(selectedRows)
                                }
                                selectableRowsHighlight
                                highlightOnHover
                                subHeader
                                progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                subHeaderComponent={
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h5 className="dashboard-subtitle">Update Client Bills</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="success-icon">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Patient Name</th>
                                    <th>Modality</th>
                                    <th>Study Description</th>
                                    <th>Billing Description</th>
                                    <th>Billed Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {CBSEditData && (
                                    <tr>
                                        <td>{CBSEditData.id}</td>
                                        <td>{CBSEditData.patient_name}</td>
                                        <td>{CBSEditData.modality}</td>
                                        <td>{CBSEditData.study_description}</td>
                                        <td>{CBSEditData.billing_description}</td>
                                        <td>{CBSEditData.net_amount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div>
                            <form>
                                <div className="row">
                                    <div className="col-6">
                                        <label>Select Service</label>
                                        <Select options={CBSEditData && Services.map((res) => (
                                            { "key": res.id, "value": res.id, "label": res.service_name }))}
                                            placeholder="Select Service"
                                            name="service_id"
                                            defaultValue={CBSEditData && Services.map((res) => ({ "value": res.id, "label": res.service_name })).find(res => res.label === CBSEditData.billing_description)}
                                            onChange={(selectedOption) => {
                                                handleSelectChange(selectedOption, "service_id")
                                            }
                                            } required />
                                    </div>
                                    <div className="col-6">
                                        <label>Amount</label>
                                        <input type="number" name="amount" className="form-control" value={UpdateData.amount} onChange={HandleChangeEdit} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                            Cancel
                        </button>
                        <button variant="primary" className="btn btn-primary" onClick={HandleUpdate}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
                {
                    AlertDeletePopupType === "success" &&
                    <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} CancelAlerrt={CancelAlerrt} />
                }
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
                {EditPopupType != "" &&
                    <EditPopupModal EditPopupType={EditPopupType} setEditpopupType={setEditpopupType} Message="Record Updated Successfully" />
                }
                {
                    confirmType === "success" &&
                    <ConfirmModal confirmType={confirmType} />
                }
            
        </>
    )
}
export { ClientFinalBillsViewtwo }