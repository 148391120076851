import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
const ServiceTemplateModal = ({ modalShow, setModalShow,setFormDataTemplate,setError,FormSubmit, Error,data,setreadOnly,readOnly, formDataTemplate,GetPreviewData,GetData}) => {
    console.log(readOnly)
    const [show, setShow] = useState(false);
    const HandleChange=(e)=>{
     const{name,value}=e.target
     setFormDataTemplate({...formDataTemplate,[name]:value})
    }
    const handleClose = () => {
        setreadOnly(false)
        setShow(false);
        setError("")
        setFormDataTemplate({...formDataTemplate,service_template:"",preview:""})

        setModalShow && setModalShow(false)
    }
    const handleShow = () => setShow(true);
    const handleSelectChange = async (selectedOption, name) => {
        try {
            let editData = await GetData(selectedOption.value);
            console.log(editData);
            setFormDataTemplate(prevData => ({
                ...prevData,
                [name]: selectedOption.value,
                preview: editData.summary
              }));
        } catch (error) {
            console.error('Error handling select change:', error);
        }
    };
    console.log(formDataTemplate)
    
    
    
    useEffect(() => {
        if (modalShow) {
            handleShow()
            GetData();
        }
        else {
            handleClose();
        }
    }, [])
    return (
        <div>
            <Modal centered show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Select Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e)=>FormSubmit(e,formDataTemplate.id)}>
                    {Error && <div className="Loginerror">
                            <p>{Error}</p>
                        </div>}
                        <div className="form-group">
                            <label className="form-label">Select Template:</label>
                            <Select options={data.map((res) => (
                                { "value": res.id, "label": res.template_name }
                            ))} placeholder="Select Template"  isDisabled={readOnly}  value={data.map(res => ({ "value": res.id, "label": res.template_name })).find(res => res.value === formDataTemplate.service_template)} onChange={(selectedOption) => handleSelectChange(selectedOption, "service_template")} required />
                        </div>
                       {formDataTemplate.service_template&&<>
                        <div className="form-group">
                            <label>Preview::</label>
                            <textarea className="form-control" rows="2" placeholder="Preview" readOnly={readOnly} name="preview" onChange={HandleChange} required value={formDataTemplate.preview} ></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block" disabled={readOnly}>Confirm Template</button>
                       </>}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export { ServiceTemplateModal };
