import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const ServicesEdit = () => {
    const[Error,SetError]=useState("")
    const [popupType, setpopupType] = useState("")

    const MastersModal = Masters.Services
    const formSubmit = (Data) => {
        axiosConfig.put(`/masters/services/${id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                service_name:error.response.data.service_name||["null"],
                service_code:error.response.data.service_code||["null"],
                    cpt_code:error.response.data.cpt_code||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].service_code[0]==="service with this service code already exists."){
                SetError("Service Code Already Exists")
            }
            if(ErrorData[0].cpt_code[0]==="service with this cpt code already exists."){
                SetError("Cpt Code Already Exists")
            }
            if(ErrorData[0].cpt_code[0]==="Only 0-9 are allowed."){
                SetError("Enter Valid Cpt Code")
            }
            if(ErrorData[0].service_name[0]==="service with this service name already exists."){
                SetError("Tittle Already Exists ")
            }
            setpopupType("error")
        })
    }
    const [PriceData, SetPriceData] = useState({})

    const HandleChange = (e) => {
        e.preventDefault();

        let priority = e.currentTarget.dataset.priority
        let tariff_type = e.currentTarget.dataset.tariff_type
        let service_prices = []
        const value = e.target.value
        ServicePrices.forEach((sitem, index) => {
            if (sitem['tariff_type'] == tariff_type) {
                let price_list = []
                let price_found = false;
                sitem['prices'].forEach((pitem, idx) => {
                    if (pitem['priority'] == priority) {
                        price_list.push({ "priority": priority, "price": value })
                        price_found = true;
                    } else {
                        price_list.push({ "priority": pitem['priority'], "price": pitem['price'] })
                    }
                })
                if (!price_found) {
                    price_list.push({ "priority": priority, "price": value })
                }
                service_prices.push(
                    {
                        "tariff_type": sitem['tariff_type'],
                        "prices": price_list
                    }
                )
            } else {
                service_prices.push(
                    {
                        "tariff_type": sitem['tariff_type'],
                        "prices": sitem['prices']
                    }
                )
            }
        })
        SetServicePrices(service_prices)
        // tariff_type = 1
        // {
        //     "tariff_type": "1",
        //     "prices": [
        //         {"priority_id": "1","price": 100},
        //         {"priority_id": "2","price": 150},
        //         {"priority_id": "3","price": 200}
        //     ]
        // }
        console.log(value)
        console.log(TariffType)
        console.log(ServicePrices)
        // SetData({ ...Data, [name]: value })
    }

    const OnSubmit = (e) => {
        e.preventDefault()
        console.log(ServicePrices)
        let formData = {
            "service_prices": ServicePrices
        }
        console.log(formData)
        axiosConfig.post(`/masters/services/${id}/prices/`, formData).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                service_name:error.response.data.service_name||["null"],
                service_code:error.response.data.service_code||["null"],
                    cpt_code:error.response.data.cpt_code||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].service_code[0]==="service with this service code already exists."){
                SetError("Service Code Already Exists")
            }
            if(ErrorData[0].cpt_code[0]==="service with this cpt code already exists."){
                SetError("Cpt Code Already Exists")
            }
            if(ErrorData[0].service_name[0]==="service with this service name already exists."){
                SetError("Tittle Already Exists ")
            }
            setpopupType("error")
        })
    }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const [TariffType, SetTariffType] = useState([])
    const [Priority, SetPriority] = useState([])
    const [ServicePrices, SetServicePrices] = useState([])
    const [DefaultPrices, SetDefaultPrices] = useState([])
    const [DefaultValues, setDefaultValues] = useState({})
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/services/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetTariffTypes = async () => {
        try {
            const resp = await axiosConfig.get(`/masters/tarifftype/`)
            let service_list = []
            resp.data.forEach((item, index) => {
                service_list.push({ "tariff_type": item.id, "prices": [] })
            })
            SetServicePrices(service_list)
            SetTariffType(resp.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetPriorities = async () => {
        try {
            const response = await axiosConfig.get(`/masters/priority/`)
            SetPriority(response.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetPrices = async () => {
        try {
            const response = await axiosConfig.get(`/masters/services/${id}/getprices/`)
            let defobj = {}
            response.data.forEach((obj, index) => {
                defobj['name_' + obj.tariff_type + '_' + obj.priority] = obj.serice_amount
                // setDefaultValues({ ...DefaultValues, ['name_'+obj.tariff_type+'_'+obj.priority_id]: obj.serice_amount });
            })
            setDefaultValues(defobj)

        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetPrices();
        GetData();
        GetTariffTypes();
        GetPriorities();
    }, [])
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Services {id}</h5>
                    <NavLink to="/dashboard/masters/services" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="accordion-container">
                    <div className="accordion" id="accordionClient">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <h5 className="dashboard-subtitle">View/Edit Services</h5>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <h5 className="dashboard-subtitle"> View/Edit Service Prices</h5>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse  show" aria-labelledby="headingTwo" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <form onSubmit={OnSubmit}>
                                        {TariffType.map((item, idx) => {
                                            return (
                                                <div key={item.id}>
                                                    <h4 className="triff-type"><u>{item.name}</u></h4>
                                                    {Priority.map((priority_item, idx) => {
                                                        return (
                                                            <div key={priority_item.id} className="form-group  form-inline">
                                                                <label>{priority_item.code}:</label>
                                                                <input type="text" className="form-control" placeholder="Enter Amount"
                                                                    data-tariff_type={item.id}
                                                                    data-priority={priority_item.id}
                                                                    name={item.id + '_' + priority_item.id}
                                                                    onChange={HandleChange}
                                                                    defaultValue={DefaultValues['name_' + item.id + '_' + priority_item.id]}
                                                                />
                                                                {console.log(DefaultValues)}
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            )
                                        })
                                        }
                                        <div className="">
                                            <button type="submit" className="btn btn-primary" >Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/services" />
                }
            
        </>
    )

}
export { ServicesEdit }