import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const RoleModulePermissionEdit = () => {
    const MastersModal=Masters.RoleModulePermission
    const formSubmit=(Data)=>{
        axiosConfig.put(`/account/roleModulepermission/${id}/`,Data).then(res=>{
                   console.log(res)
        }).catch(error=>{
            console.log(error)
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/account/roleModulepermission/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit RoleModule Permession {id}</h5>
                    <NavLink to="/dashboard/masters/rolemodulepermission" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit}/>
            
        </>
    )

}
export {RoleModulePermissionEdit}