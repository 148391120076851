import React, { useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const CountryAdd = () => {
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const MastersModal = Masters.Country
    const [Error, SetError] = useState("")
    const formSubmit = (Data,setFieldErrors) => {
        console.log(Data)
            SetError([])
            axiosConfig.post("/masters/countries/", Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                                  Object.keys(error.response.data).forEach(fieldName => {
                    switch (fieldName) {
                        case 'country_name':
                            if (error.response.data[fieldName][0] === "This field must be unique.") {
                                setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                            }
                            break;
                        default:
                            break;
                    }
                });
                setpopupType("error")
            })
    }
    console.log(formerrorData)
    const error={CountryName:Error.countryName}
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Country</h5>
                    <NavLink to="/dashboard/masters/countries" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/countries"/>
                }
            
        </>
    )

}
export { CountryAdd }