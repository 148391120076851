import React, { useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const CityAdd = () => {
    const [formerrorData, SetformErrorData] = useState({})
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("");

    const MastersModal = Masters.City
    const formSubmit = (Data,setFieldErrors) => {
        console.log(Data)
        axiosConfig.post("/masters/cities/", Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data)
            Object.keys(error.response.data).forEach(fieldName => {
                switch (fieldName) {
                    case 'state_name':
                        if (error.response.data[fieldName][0] === "This field must be unique.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                        }
                        break;
                    default:
                        break;
                }
            });
            setpopupType("error")
        })
    }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add City</h5>
                    <NavLink to="/dashboard/masters/cities" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/cities" />
                }
            
        </>
    )

}
export { CityAdd }