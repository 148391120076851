import React, { useState, useEffect } from "react";
import { FormParser, PopupModal } from "../Components";
import { useParams } from "react-router-dom";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineEdit } from "react-icons/md"

const RadiologistInfo = () => {
    const masterModal = Masters.RadiologistEdit;
    const [popupType, setpopupType] = useState("");
    const [info, setInfo] = useState([]);
    const { id } = useParams();
    const [readOnly, setReadOnly] = useState(true);
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        const formData = new FormData()
        formData.append("first_name", Data.first_name);
        formData.append("last_name", Data.last_name);
        formData.append("email", Data.email);
        formData.append("sort", Data.sort);
        formData.append("mobileno", Data.mobileno);
        Data.profile_image && formData.append("profile_image", Data.profile_image);
        formData.append("about", Data.about);
        formData.append("address", Data.address);
        axiosConfig.patch(`/account/users/${id}/`, formData).then(res => {
            console.log(res, "patched personal info...")
            setpopupType("success")
            setReadOnly(true)
        }).catch(error => {
            console.log("error patching info : ", error)
            setpopupType("error")
        })
    }

    const GetInfo = async () => {
        try {
            const radiologistInfo = await axiosConfig.get(`/account/users/${id}/`)
            console.log(radiologistInfo, "radiologist info")
            setInfo(radiologistInfo.data)
        } catch (error) {
            console.log("Error getting radiologist info : ", error)
        }
    }

    useEffect(() => {
        GetInfo();
    }, [])

    return (
        <div>
            <FormParser
                modelObject={masterModal}
                formData={info}
                buttonTitle="Save"
                formSubmit={formSubmit}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
            />
            {readOnly &&
                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                    <div>Edit</div>
                    <MdOutlineEdit className="ms-5" />
                </button>
            }
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </div>
    )
}

export { RadiologistInfo }