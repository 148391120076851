import React, { useState, useEffect } from "react";
import { MdFilterList, MdOutlineSearch, MdClose } from "react-icons/md";
import axiosConfig from "../../Service/axiosConfig";
import Modal from 'react-bootstrap/Modal';

const RadiologistsDetails = ({ setRadiologists, setActiveItem, smShow, setAssignData }) => {
    const [assignedData, setAssignedData] = useState([])
    const [activities, setUserActivities] = useState([])
    const [selected, setSelected] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [showSearch, setShowSearch] = useState(false)
    const [ordering, setOrdering] = useState('totalcount')
    const [radiologistDetails, setRadiologistDetails] = useState([])

    const handleOrdering = () => {
        if (ordering === 'totalcount') {
            setOrdering('-totalcount')
        } else if (ordering === '-totalcount') {
            setOrdering('totalcount')
        }
    }

    const GetAssignedData = async (activities) => {
        try {
            const response = await axiosConfig.get(`/cases/assigned_cases_to_radiologist/?search=${searchValue}&ordering=${ordering}`);
            let options = [];
            let updatedAssignedData = [];
            response.data.forEach(item => {
                for (const [key, value] of Object.entries(item)) {
                    const filtered_activity = activities.filter((activity) => JSON.stringify(activity.user.id) === key);
                    if (filtered_activity.length > 0 && filtered_activity[0].is_online) {
                        value.is_online = filtered_activity[0].is_online;
                    } else {
                        value.is_online = false;
                    }

                    value.id = key;
                    options.push({
                        label: value.name,
                        value: key,
                        is_online: value.is_online
                    });

                    updatedAssignedData.push(value);
                }
            });
            setAssignedData(updatedAssignedData);
            typeof setRadiologists !== undefined && setRadiologists(options);
            setRadiologistDetails(options)
        } catch (error) {
            console.log("Error getting radiologists: ", error);
        }
    };

    const handleClose = () => {
        setActiveItem(null)
        localStorage.setItem('activeSideData', null)
    }
    const handleClick = (item) => {
        setSelected(item.id)
        smShow && setAssignData(item)
    }

    const getUserActivities = async () => {
        try {
            const response = await axiosConfig.get(`/account/user-activities/?page=1&page_size=250`)
            setUserActivities(response.data.results)
            GetAssignedData(response.data.results)
        } catch (error) {
            console.log("Error getting user activities : ", error)
        }
    }
    const HandleSearch = (e) => {
        console.log(e.target.value)
        setSearchValue(e.target.value)
    }
    useEffect(() => {
        getUserActivities();
    }, [searchValue, ordering])
    return (
        <>
            <div className="radiologist-top">
                <div>
                    <span>Radiologist</span>
                    <MdFilterList className="sidepanel-icons" onClick={handleOrdering} />
                </div>
                <div className="d-flex align-items-center">
                    {showSearch && <input className="form-control" type="text" value={searchValue} onChange={(e) => HandleSearch(e)} />}
                    <MdOutlineSearch className="sidepanel-icons" onClick={() => setShowSearch(true)} />
                    {!smShow && <MdClose className="sidepanel-icons" onClick={() => handleClose()} />}
                </div>
            </div>
            <div className="radiologist-detail">
                <div className="radiologist-img"></div>
                <div className="radiologist-name"></div>
                <div className="radiologist-stats-parent">
                    <div className="radiologist-stats">Mod</div>
                    <div className="radiologist-stats">Rpt</div>
                    <div className="radiologist-stats">Pen</div>
                </div>
            </div>
            <div className="radiologists-list">
                {assignedData.length > 0 ?
                    assignedData.map((item) => {
                        return (
                            <div className={`radiologist-detail ${selected === item.id ? 'selected' : ''}`} key={item.id} onClick={() => handleClick(item)}>
                                <div className="radiologist-img position-relative">
                                    <img src={item.profile} />
                                    <div className={`online-indicator ${item.is_online ? 'online' : 'offline'}`}></div>
                                </div>
                                <div className="radiologist-name">
                                    <div>Dr.{item.name}</div>
                                    <div className="radiologist-pref">
                                        <p className="pref-modality">{item.preference_modalities.join(',')}</p>
                                        <p className="pref-modality">({item.nopreferences_modalities.join(',')})</p>
                                    </div>
                                </div>
                                <div className="radiologist-stats-parent">
                                    {item.modalities.map((modality) => {
                                        const assigned = modality.Assigned !== undefined ? modality.Assigned : 0;
                                        const readDraft = modality['Read Draft'] !== undefined ? modality['Read Draft'] : 0;
                                        return (
                                            <>
                                                <div className="radiologist-stats">{modality.type}</div>
                                                <div className="radiologist-stats">{assigned}</div>
                                                <div className="radiologist-stats">{readDraft}</div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="loader-container">
                        <img src={require('../FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>
                }
            </div>
        </>
    )
}

export { RadiologistsDetails }
