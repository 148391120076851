import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const SubDepartmentAdd = () => {
     const[popupType,setpopupType]=useState("")
     const[Error,SetError]=useState("")
    const MastersModal=Masters.SubDepartment
    const formSubmit=(Data,setFieldErrors)=>{
        console.log(Data)
        axiosConfig.post("/masters/subdepartments/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            Object.keys(error.response.data).forEach(fieldName => {
                switch (fieldName) {
                    case 'subdepartment_name':
                        if (error.response.data[fieldName][0] === "This field must be unique.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                        }
                        case 'subdepartment_code':
                        if (error.response.data[fieldName][0] === "This field must be unique.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                        }
                        break;
                    default:
                        break;
                }
            });
                setpopupType("error")
        })
      }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Sub-Department</h5>
                    <NavLink to="/dashboard/masters/sub-departments" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/sub-departments"/>
                }
            
        </>
    )

}
export {SubDepartmentAdd}