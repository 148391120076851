import React, { useState, useEffect } from "react";
import { NotFound } from "../../Pages";
import axiosConfig from "../../Service/axiosConfig";
import Select from "react-select";
import { MdOutlineEdit } from "react-icons/md";
import { ReactQuillComponent } from "./ReactQuill/ReactQuill";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdInfo } from "react-icons/md";
const FormParserNew = ({
  modelObject,
  formData,
  formSubmit,
  error,
  button,
  buttonTitle,
  readOnly,
}) => {
  const [Data, SetData] = useState({});
  const [loading, setLoading] = useState(true);
  const [dynamicOptions, SetDynamicOptions] = useState({});
  const [DataFilter, SetDataFilter] = useState([]);
  const [Option, setOption] = useState({});
  const [Error, SetError] = useState("");
  const [Nextbutton, setNextbutton] = useState("");
  const [FieldErrors, setFieldErrors] = useState({});
  const GetData = async () => {
    try {
      for (const field_obj of field_objects) {
        if (field_obj.fieldType === "dynamicdropdown") {
          const response = await axiosConfig.get(field_obj.options_url);
          const count = Math.ceil(response.data.count / 20);
          SetDynamicOptions((prevOptions) => ({
            ...prevOptions,
            [field_obj.fieldName]: response.data.results,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetMultiData = async () => {
    let data = [];
    try {
      for (const field_obj of field_objects) {
        if (
          field_obj.fieldType === "dynamicdropdown" ||
          field_obj.fieldType === "dynamicMultiSelectdropdown"
        ) {
          const response = await axiosConfig.get(field_obj.options_url);
          const count = Math.ceil(response.data.count / 20);
          SetDynamicOptions((prevOptions) => ({
            ...prevOptions,
            [field_obj.fieldName]: response.data.results,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const HandleRadioChange = (e, ItemName) => {
    const { name } = e.target;
    SetData({ ...Data, [name]: ItemName });
  };
  const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([]);
  const SelectOptions = (id) => {
    field_objects.forEach((item) => {
      if (item.fieldType === "dynamicSelectdropdown") {
        console.log(id, `${item.options_url}${id}`);
        let url = `${item.options_url}${id}`;
        axiosConfig
          .get(url)
          .then((res) => {
            console.log(res.data.results);
            SetSelectDynamicOptions(res.data.results);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const editDataRender = async (formData) => {
    field_objects.forEach(async (field_obj, idx) => {
      if (formData) {
        Data[field_obj.fieldName] = formData[field_obj.fieldName];
        SetData(formData);
        if (field_obj.fieldType === "dynamicSelectdropdown") {
          if (formData[field_obj.renderField]) {
            let url = `${field_obj.options_url}${
              formData[field_obj.renderField]
            }`;
            console.log(url);
            await axiosConfig
              .get(url)
              .then((res) => {
                SetSelectDynamicOptions(res.data.results);
                const find = res.data.results.find(
                  (find) => find.id === formData[field_obj.fieldName]
                );
                setOption({
                  value: find.id,
                  label: find[field_obj.displayField],
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
        if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
          let data = [];
          formData &&
            formData[field_obj.fieldName] &&
            formData[field_obj.fieldName].forEach((value) => {
              console.log(value, dynamicOptions);
              const findData = dynamicOptions[field_obj.fieldName].find(
                (find) => find.id === value
              );
              data.push(findData);
            });
          setSelectedOptions(data);
        }
      }
    });
    setTimeout(
      function () {
        setLoading(false);
      }.bind(this),
      500
    );
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  console.log(selectedOptions);
  useEffect(() => {
    GetData();
    GetMultiData();
    editDataRender(formData);
  }, [formData]);
  console.log(modelObject);
  const [FilterData, SetFilterdata] = useState([]);
  const [Name, SetName] = useState("");
  const HandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    SetData({ ...Data, [name]: value });
    validateField(name, value);
    SetName(value);
  };
  const handleSelectChange = (selectedOption, name) => {
    console.log(selectedOption.value, name);
    SetData({ ...Data, [name]: selectedOption.value });
    SelectOptions(selectedOption.value);
  };
  const handleMultiSelectChange = (selectedOption, Value, name) => {
    let data = [];
    selectedOption.forEach((res) => {
      data.push(res.value);
    });
    console.log(data);
    SetData({ ...Data, [name]: data });
  };
  const HandleFiles = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const name = e.target.name;
    const value = file;
    SetData({ ...Data, [name]: value });
  };
  const HandleMultiPulFiles = (e) => {
    e.preventDefault();
    const file = e.target.files;
    const name = e.target.name;
    const value = file;
    SetData({ ...Data, [name]: value });
  };
  const OnSubmit = (e) => {
    e.preventDefault();
    const requiredFieldErrors = validateRequiredFields();

    if (requiredFieldErrors) {
      console.log(requiredFieldErrors);
      setFieldErrors(requiredFieldErrors);
      return;
    }

    // Reset field-level errors
    setFieldErrors({});
    formSubmit(Data, setFieldErrors);
  };

  console.log(Data);
  const validateField = (name, value) => {
    const regex = {
      country_name: /^[a-zA-Z]+$/,
      state_name: /^[a-zA-Z]+$/,
      city_name: /^[a-zA-Z]+$/,
      pincode: /^[0-9]+$/,
      email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      cpt_code: /^[0-9]+$/,
    };
    const isInputValid = regex[name] ? regex[name].test(value) : true;
    const fields = field_objects.find((fields) => fields.fieldName === name);
    if (!isInputValid) {
      let name = fields.fieldName;
      setFieldErrors({ [name]: `Enter a valid ${fields.label}` });
    } else {
      setFieldErrors({});
    }
  };
  let field_objects = [];
  modelObject.orderBy.forEach((order_obj, idx) => {
    modelObject.fields.forEach((field_obj, index) => {
      if (field_obj.fieldName === order_obj) {
        let flagAddClass = true;
        modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
          if (classObj[field_obj.fieldName]) {
            field_obj["groupClass"] = classObj[field_obj.fieldName];
            flagAddClass = false;
          }
        });
        if (modelObject.layoutClass && flagAddClass) {
          field_obj["groupClass"] = modelObject.layoutClass;
        }
        field_objects.push(field_obj);
      }
    });
  });
  const validateRequiredFields = () => {
    const requiredFieldErrors = {};

    field_objects.forEach((fieldItem) => {
      if (fieldItem.required && !Data[fieldItem.fieldName]) {
        requiredFieldErrors[
          fieldItem.fieldName
        ] = `${fieldItem.label} is required`;
      }
    });

    return Object.keys(requiredFieldErrors).length > 0
      ? requiredFieldErrors
      : null;
  };

  return (
    <>
      <div className="">
        {loading ? (
          <div className="loader-container">
            <img src={require("./images/loader.gif")} alt="Loading..." />
          </div>
        ) : (
          <form onSubmit={OnSubmit} encType="multipart/form-data">
            <div className="Loginerror">
              <p>{error}</p>
            </div>
            <div className="row">
              {field_objects &&
                field_objects.map((fieldItem, i) => {
                  const isRequired = fieldItem.required ? "*" : "";
                  const hasError =
                    Error && Error.includes(`Enter a valid ${fieldItem.label}`);
                  const isRequiredAndEmpty =
                    fieldItem.required && !Data[fieldItem.fieldName];
                  return (
                    <div
                      key={fieldItem.fieldName}
                      className={fieldItem.groupClass}
                    >
                      {fieldItem.fieldType === "string" && (
                        <div
                          className={`form-group ${
                            hasError ||
                            (FieldErrors[fieldItem.fieldName] && "has-error")
                              ? "has-error"
                              : ""
                          }`}
                        >
                          <div className="input-box">
                            <input
                              type="text"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              id="validationCustom01"
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              maxLength={
                                fieldItem.max_length
                                  ? fieldItem.max_length
                                  : null
                              }
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "email" && (
                        <div
                          className={`form-group ${
                            hasError ||
                            (FieldErrors[fieldItem.fieldName] && "has-error")
                              ? "has-error"
                              : ""
                          }`}
                        >
                          <div className="input-box">
                            <input
                              type="email"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              id="validationCustom01"
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              maxLength={
                                fieldItem.max_length
                                  ? fieldItem.max_length
                                  : null
                              }
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "url" && (
                        <div
                          className={`form-group ${
                            hasError ||
                            (FieldErrors[fieldItem.fieldName] && "has-error")
                              ? "has-error"
                              : ""
                          }`}
                        >
                          <div className="nuput-box">
                            <input
                              type="url"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              id="validationCustom01"
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              maxLength={
                                fieldItem.max_length
                                  ? fieldItem.max_length
                                  : null
                              }
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              required={fieldItem.required}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "boolean" && (
                        <div className="form-group">
                          <label className="form-check-label ari-radio-label">
                            <input
                              className="ari-radio"
                              type="checkbox"
                              name={fieldItem.fieldName}
                              value="true"
                              onChange={HandleChange}
                              required={fieldItem.required}
                              disabled={readOnly}
                            />{" "}
                            {fieldItem.label}
                          </label>
                          {FieldErrors[fieldItem.fieldName] && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  Tooltip for <strong>{fieldItem.label}</strong>
                                  .
                                </Tooltip>
                              }
                            >
                              <button
                                style={{ border: "none", background: "#FFF" }}
                              >
                                <MdInfo />
                              </button>
                            </OverlayTrigger>
                          )}
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "file" && (
                        <div className="form-group">
                          {Data && Data[fieldItem.fieldName] && (
                            <a href={Data[fieldItem.fieldName]} target="_blank">
                              <img
                                src={Data[fieldItem.fieldName]}
                                width="30"
                                className="form-edit-img"
                              />
                            </a>
                          )}
                          <div className="input-box">
                            <input
                              type="file"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              onChange={HandleFiles}
                              required={fieldItem.required}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}

                      {fieldItem.fieldType === "date" && (
                        <div className="form-group">
                          <div className="input-box">
                            <input
                              type="date"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              required={fieldItem.required}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "time" && (
                        <div className="form-group">
                          <div className="input-box">
                            <input
                              type="time"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              required={fieldItem.required}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "integer" && (
                        <div
                          className={`form-group ${
                            hasError ||
                            (FieldErrors[fieldItem.fieldName] && "has-error")
                              ? "has-error"
                              : ""
                          }`}
                        >
                          <div className="input-box">
                            <input
                              type="number"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              maxLength={
                                fieldItem.max_length
                                  ? fieldItem.max_length
                                  : null
                              }
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "password" && (
                        <div className="form-group">
                          <div className="input-box">
                            <input
                              type="password"
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              maxLength={
                                fieldItem.max_length
                                  ? fieldItem.max_length
                                  : null
                              }
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              disabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "radio" && (
                        <div className="form-group">
                          <br />
                          {fieldItem.options &&
                            fieldItem.options.map((optionItem, index) => {
                              return (
                                <div className="form-check-inline" key={index}>
                                  <label className="form-check-label ari-radio-label">
                                    <input
                                      type="radio"
                                      className="ari-radio"
                                      name={fieldItem.fieldName}
                                      checked={
                                        Data[fieldItem.fieldName] === optionItem
                                      }
                                      defaultValue={Data[fieldItem.fieldName]}
                                      onChange={(e) =>
                                        HandleRadioChange(e, optionItem)
                                      }
                                      required={fieldItem.required}
                                      disabled={readOnly}
                                    />
                                    {optionItem}
                                  </label>
                                </div>
                              );
                            })}

                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "textarea" && (
                        <div className="form-group">
                          <div className="input-box">
                            <textarea
                              className={`form-control ${
                                hasError ||
                                (FieldErrors[fieldItem.fieldName] &&
                                  "invalid-error")
                                  ? "invalid-error"
                                  : ""
                              }`}
                              rows="2"
                              placeholder={fieldItem.label}
                              name={fieldItem.fieldName}
                              onChange={HandleChange}
                              defaultValue={Data[fieldItem.fieldName]}
                              disabled={readOnly}
                            ></textarea>
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}

                      {fieldItem.fieldType === "dropdown" && (
                        <div key={i} className="form-group">
                          <div className="input-box">
                            <div>
                              <Select
                                className={
                                  FieldErrors[fieldItem.fieldName]
                                    ? "invalid-error"
                                    : "custom-select-container"
                                }
                                classNamePrefix={
                                  FieldErrors[fieldItem.fieldName]
                                    ? "invalid-error"
                                    : "custom-select"
                                }
                                options={fieldItem.options}
                                placeholder={fieldItem.label}
                                defaultValue={fieldItem.options.find(
                                  (res) =>
                                    res.value === Data[fieldItem.fieldName]
                                )}
                                onChange={(selectedOption) =>
                                  handleSelectChange(
                                    selectedOption,
                                    fieldItem.fieldName
                                  )
                                }
                                isDisabled={readOnly}
                              />
                            </div>
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "dynamicdropdown" && (
                        <div key={i} className="form-group">
                          <div className="input-box">
                            <div>
                              <Select
                                className={
                                  FieldErrors[fieldItem.fieldName]
                                    ? "invalid-error"
                                    : "custom-select-container"
                                }
                                classNamePrefix={
                                  FieldErrors[fieldItem.fieldName]
                                    ? "invalid-error"
                                    : "custom-select"
                                }
                                options={
                                  dynamicOptions[fieldItem.fieldName] &&
                                  dynamicOptions[fieldItem.fieldName].map(
                                    (res) => ({
                                      value: res.id,
                                      label: res[fieldItem.displayField],
                                    })
                                  )
                                }
                                placeholder={fieldItem.label}
                                value={
                                  dynamicOptions[fieldItem.fieldName] &&
                                  dynamicOptions[fieldItem.fieldName]
                                    .map((res) => ({
                                      value: res.id,
                                      label: res[fieldItem.displayField],
                                    }))
                                    .find(
                                      (res) =>
                                        res.value === Data[fieldItem.fieldName]
                                    )
                                }
                                onChange={(selectedOption) =>
                                  handleSelectChange(
                                    selectedOption,
                                    fieldItem.fieldName
                                  )
                                }
                                isDisabled={readOnly}
                              />
                            </div>
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}

                      {fieldItem.fieldType === "dynamicSelectdropdown" && (
                        <div key={i} className="form-group">
                          {console.log(Data[fieldItem.fieldName])}
                          <div className="input-box">
                            <Select
                              className={
                                FieldErrors[fieldItem.fieldName]
                                  ? "invalid-error"
                                  : ""
                              }
                              options={
                                SelectDynamicOptions &&
                                SelectDynamicOptions.map((res) => ({
                                  value: res.id,
                                  label: res[fieldItem.displayField],
                                }))
                              }
                              placeholder={fieldItem.label}
                              value={
                                SelectDynamicOptions &&
                                SelectDynamicOptions.map((res) => ({
                                  value: res.id,
                                  label: res[fieldItem.displayField],
                                })).find(
                                  (find) =>
                                    find.value === Data[fieldItem.fieldName]
                                )
                              }
                              onChange={(selectedOption) =>
                                handleSelectChange(
                                  selectedOption,
                                  fieldItem.fieldName
                                )
                              }
                              isDisabled={readOnly}
                            />
                            {FieldErrors[fieldItem.fieldName] && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Tooltip for{" "}
                                    <strong>{fieldItem.label}</strong>.
                                  </Tooltip>
                                }
                              >
                                <button
                                  style={{ border: "none", background: "#FFF" }}
                                >
                                  <MdInfo />
                                </button>
                              </OverlayTrigger>
                            )}
                          </div>
                          {FieldErrors[fieldItem.fieldName] && (
                            <div className="invalid">
                              {FieldErrors[fieldItem.fieldName]}
                            </div>
                          )}
                        </div>
                      )}
                      {fieldItem.fieldType === "dynamicMultiSelectdropdown" && (
                        <div key={i} className="form-group">
                          <Select
                            isMulti
                            options={
                              dynamicOptions[fieldItem.fieldName] &&
                              dynamicOptions[fieldItem.fieldName].map(
                                (res) => ({
                                  value: res.id,
                                  label: res[fieldItem.displayField],
                                })
                              )
                            }
                            placeholder={fieldItem.label}
                            value={dynamicOptions[fieldItem.fieldName]
                              .map((res) => ({
                                value: res.id,
                                label: res[fieldItem.displayField],
                              }))
                              .filter((option) =>
                                (Data[fieldItem.fieldName] || []).includes(
                                  option.value
                                )
                              )}
                            onChange={(selectedOption) =>
                              handleMultiSelectChange(
                                selectedOption,
                                selectedOption.value,
                                fieldItem.fieldName
                              )
                            }
                            required={fieldItem.required}
                          />
                        </div>
                      )}
                      {fieldItem.fieldType === "mulitipulFiles" && (
                        <div key={i} className="form-group">
                          <input
                            className="form-control"
                            type="file"
                            id="formFileMultiple"
                            name={fieldItem.fieldName}
                            multiple
                            onChange={HandleMultiPulFiles}
                            required={fieldItem.required}
                            disabled={readOnly}
                          />
                        </div>
                      )}
                      {fieldItem.fieldType === "status" && (
                        <div
                          key={i}
                          className="form-group form-check form-switch"
                        >
                          <div>
                            <label className="form-check-label">STATUS</label>
                          </div>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            name={fieldItem.fieldName}
                            defaultValue={Data[fieldItem.fieldName]}
                            onChange={HandleChange}
                            disabled={readOnly}
                          />
                        </div>
                      )}
                      {fieldItem.fieldType === "ReactQuill" && (
                        <div className="form-group">
                          <ReactQuillComponent
                            SetData={SetData}
                            Data={Data}
                            name={fieldItem.fieldName}
                            value={Data[fieldItem.fieldName]}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            {!readOnly && (
              <button
                type="submit"
                className={`btn ${button ? button : "btn-primary"}`}
              >
                {buttonTitle ? buttonTitle : "Submit"}
              </button>
            )}
          </form>
        )}
      </div>
    </>
  );
};
export { FormParserNew };
