import React, { useEffect, useState } from "react";
import { FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate, useParams, useNavigation } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import Masters from "../Components/masters.json";
import { MdOutlineEdit } from "react-icons/md"
const PatientMLCInfo = () => {
    let { id } = useParams()
    console.log(id)
    const [readOnly, setReadOnly] = useState(true);
    const MastersModal = Masters.PatientMLCInfo
    let navigate = useNavigate();
    const [Data, setData] = useState({
        email_id: "",
        mobile_no: "",
        data_required: "",
        referring_physician: "",
        mlc_description: ""
    })
    const [popupType, setpopupType] = useState("");
    const formSubmit = (formData) => {
        console.log(id)
        console.log(formData)
        const formDataToUpdate = new FormData();
        formDataToUpdate.append("email_id", formData.email_id);
        formDataToUpdate.append("mobile_no", formData.mobile_no);
        formDataToUpdate.append("data_required", formData.data_required);
        formDataToUpdate.append("referring_physician", formData.referring_physician);
        formDataToUpdate.append("mlc_description", formData.mlc_description);
        console.log(formDataToUpdate)
        axiosConfig.patch(`/cases/cases/${id}/`, formDataToUpdate).then(res => {
            console.log(res)
            setData(res.data)
            setpopupType("success")
            setReadOnly(true)
        })
            .catch(error => {
                console.log(error)
                setpopupType("error")
                setReadOnly(false)
            })
    }
    const fetchData = () => {
        axiosConfig
            .get(`/cases/cases/${id}/`)
            .then((res) => {
                console.log(res.data)
                setData(res.data);
            })
            .catch((error) => {
                console.log(error);
                setpopupType("error");
            });
    };
    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <>
            <FormParser formData={Data} modelObject={MastersModal} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} buttonTitle='Save' />
            {readOnly &&
                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                    <div>Edit</div>
                    <MdOutlineEdit className="ms-5" />
                </button>
            }
            {popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="" />
            }

        </>
    )
}
export { PatientMLCInfo }