import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles"
import { FormParser, DeletePopupModal, PopupModal } from "../Components";
import { TiTick } from "react-icons/ti"
import { MdOutlineEdit } from "react-icons/md";
import axiosConfig from "../Service/axiosConfig";
import Select from 'react-select';
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Masters from "../Components/masters.json";

const International = () => {
    const { id } = useParams();
    console.log(id, "iddddd");
    const MastersModal2 = Masters.remunerationFixedPay
    const [ExportData, setExportData] = useState([]);
    const [Data, SetData] = useState([])
    const [selectedOption, setSelectedOption] = useState();
    const [RowPerPage, setRowPerPage] = useState(20)
    const [show, setShow] = useState(false)
    const [readOnly, setReadOnly] = useState(true);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [Service, setService] = useState()
    const [EditTariffChange, setEditTarifChange] = useState([])
    const [tableId, setTableId] = useState()
    const [Priorities, SetPriorities] = useState([])
    const [loading, setLoading] = useState(false)
    const [popupType, setpopupType] = useState("")
    const handleChange = (selectedOption) => {
        console.log(selectedOption)
        // e.preventDefault();
        setSelectedOption(selectedOption);
    };
    const options = [
        { value: 'CT', label: 'CT' },
        { value: 'MR', label: 'MR' },
        { value: 'DX', label: 'DX' },
        { value: 'PETCT', label: 'PETCT' },
    ];
    const sortedPriorities = Priorities.sort((a, b) => a.id - b.id);
    console.log(sortedPriorities)
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            width: "50px",
        },
        {
            name: "Service Code",
            selector: "service",
            cell: (row) => `${row.service}`,
            sortable: true,
            width: "70px",
        },
        {
            name: "Service Name",
            selector: "service_name",
            cell: (row) => `${row.service_name}`,
            sortable: true,
            width: "250px",
        },
        {
            name: "Subdepartment name",
            selector: "subdepartment_code",
            cell: (row) => `${row.subdepartment_code}`,
            sortable: true,
        },
        ...sortedPriorities.map((priority, idx) => ({
            name: priority.code,
            selector: `professional_charges_${idx + 1}`,
            sortable: true,
            cell: row => {
                const professionalCharges = row[`professional_charges_${idx + 1}`];
                const Actions = Service === row.service;

                if (Actions) {
                    return (
                        <>
                            <input className="form-control" defaultValue={professionalCharges} name={`professional_charges_${idx + 1}`}
                                onChange={(e) => HandleTariffChange(e, row[`priority_id${idx + 1}`], idx + 1)}
                            />
                        </>
                    );
                }

                return (
                    <>
                        <span>{professionalCharges}</span>
                    </>
                );
            }
        })),
        {
            name: "ACTIONS",
            width: "100px",
            selector: (row) => {
                const HandleEdit = async () => {
                    console.log(row, "wedshkxzfcghj")
                    setService(row.service);
                    setEditTarifChange([
                        {
                            "professional_charges_1": row.professional_charges_1,
                            "priority_id1": row.priority_id1,
                        },
                        {
                            "professional_charges_2": row.professional_charges_2,
                            "priority_id2": row.priority_id2,
                        },
                        {
                            "professional_charges_3": row.professional_charges_3,
                            "priority_id3": row.priority_id3,
                        },
                    ]);
                };
                const ActionsAccrss = Service === row.service;
                const shouldShowActions = tableId === row.service;
                return (
                    <>
                        {shouldShowActions && (
                            <div className="row text-center">
                                <div className="col-4">
                                    {ActionsAccrss ? (
                                        <>
                                            <TiTick className="dashboard-tabe-data-actions"
                                                onClick={handleTariff}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <MdOutlineEdit className="dashboard-tabe-data-actions"
                                                onClick={HandleEdit}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                );
            },
        },
    ];
    const GetData = async () => {
        setLoading(true);
        try {
            const subdepartmentCode = selectedOption ? selectedOption.value : "";
            const response = await axiosConfig.get(
                `/account/remunerationitem/custom_pagination/?radiologist_id=${id}&subdepartment_code=${subdepartmentCode}&client_type=2&?page=${currentPage}&page_size=${RowPerPage}&subdepartment_code=${subdepartmentCode}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`}`
            );
            console.log(response)
            console.log(response.data.results)
            setExportData(response.data.results)
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const GetPriorities = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/masters/priority/`);
            console.log(response.data.results, 'response')
            SetPriorities(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const HandleMouse = (row) => {
        setTableId(row.service);
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    };
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const handleTariff = () => {
        EditTariffChange.map((tarif, idx) => {
            axiosConfig.patch(`/account/remunerationitem/${tarif[`priority_id${idx + 1}`]}/`, { "professional_charges": tarif[`professional_charges_${idx + 1}`] }).then(res => {
                console.log(res)
                setpopupType("success")
                GetData();
                // handleClient()
                setService()
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            })
        })
    }
    const HandleTariffChange = (e, priority, index) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const existingIndex = EditTariffChange.findIndex(item => item[`priority_id${index}`] === priority);
        if (existingIndex === -1) {
            setEditTarifChange(prevState => [...prevState, { [name]: value, [`priority_id${index}`]: priority }]);
        } else {
            setEditTarifChange(prevState => {
                const updatedValues = [...prevState];
                updatedValues[existingIndex] = { [name]: value, [`priority_id${index}`]: priority };
                return updatedValues;
            });
        }
    };
    function handleDownloadExcel() {
        const header = ["S.No",
            "RemunerationName",
            "ServiceName",
            "SubDepartment",
            "General",
            "Night",
            "Stat",
        ];
        const body3 = ExportData && ExportData.map((res, index) => [index + 1, res.report_type, res.service_name, res.subdepartment_code, res.professional_charges_1, res.professional_charges_2, res.professional_charges_3])
        console.log(body3)
        downloadCSV({
            fileName: `${"RemunerationInternation" + "." + "csv"}`,
            sheet: `${"RemunerationInternation" + "." + "csv"}`,
            tablePayload: {
                header,
                body: body3,
            },
        });
    }
    function downloadCSV({ fileName, tablePayload }) {
        const csvContent = [
            tablePayload.header.join(','),
            ...tablePayload.body.map(row => row.join(','))
        ].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }
    const [Error, setError] = useState("");
    const ModifyPay = (Data) => {
        console.log(Data, "exellll")
        setLoading(true);
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.post("/account/update_remunaration_tariff/", { "file": Data.file, "radiologist_id": id }).then(res => {
            console.log(res)
            setError("")
            setLoading(false);
            setpopupType("success")
            GetData();
            setShow(false)
        }).catch(error => {
            console.log(error)
            console.log(error.response.data)
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data;
                setError(errorMessage);
            }
            setpopupType("error")
        })
    }
    const handleShow = () => {
        setShow(true);
        setError("")
    }
    const handleClose = () => {
        setShow(false);
        setpopupType("")
        setError("")
    }
    useEffect(() => {
        GetData()
        GetPriorities();
    }, [currentPage, RowPerPage, currentColumn, currentDirection, selectedOption])
    return (
        <>
            <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                    paginationPerPage={RowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                        GetData(currentColumn, currentDirection);
                    }}
                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50, 100]}
                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, 100]}
                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                    onSort={handleSort}
                    paginationServer
                    fixedHeader
                    customStyles={tableCustomStyles}
                    fixedHeaderScrollHeight="450px"
                    highlightOnHover
                    onRowMouseEnter={HandleMouse}
                    onRowMouseLeave={HandleRowmouseLeave}
                    subHeader
                    progressPending={loading}
                    progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}
                    subHeaderComponent={
                        <div className="w-25">
                            <Select
                                value={selectedOption}
                                onChange={handleChange}
                                options={options}
                                placeholder="Modality"
                            />
                        </div>
                    }
                >
                </DataTable>
            </div>
            <div className="excel-button">
                <button type="submit" className="btn btn-primary"
                    onClick={handleDownloadExcel}
                >Download Excel</button>
                <button type="submit" className="btn btn-primary" onClick={handleShow}>Upload Excel</button>
            </div>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Modify Pay
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        {
                            loading ?
                                <div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>
                                : null
                        }
                        <FormParser modelObject={MastersModal2} formSubmit={ModifyPay} error={Error} button="btn-block" />
                    </div>
                </Modal.Body>
            </Modal>
            {
                popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </>
    )
}
export { International }