import React, { useState, useEffect } from "react";
import "./ModuleList.css";
import { MdDeleteOutline, MdOutlineEdit, MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import axiosConfig from "../Service/axiosConfig";
import { DashboardLayout, FormParser, PopupModal, DeletePopupModal } from "../Components";
import Masters from "../Components/masters.json";
import Modal from 'react-bootstrap/Modal';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";

const ModuleScreen = ({ Code }) => {
    const [modules, setModules] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [show, setShow] = useState(false)
    const [Error, SetError] = useState("")
    const [formData, setFormData] = useState({})
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState('')
    const [deletePopupType, setDeletepopupType] = useState("")
    const [popupType, setpopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const MastersModal = Masters.Module
    const MastersModal2 = Masters.SubModuleScreen
    const [submoduleShow, setSubmoduleShow] = useState(false)
    const [subModuleData, setSubModuleData] = useState({})
    const [message, setMessage] = useState()
    const [AlertDeletePopupType2, SetAlertDeletePopupType2] = useState('')
    const [errormessage, setErrorMessage] = useState()
    const getModules = async () => {
        try {
            const response = await axiosConfig.get(`/masters/module/`)
            console.log(response.data.results)
            setModules(response.data.results)
        } catch (error) {
            console.log("Error getting modules : ", error)
        }
    }

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data.modulescreen)
    }

    const handleClick = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setSubmoduleShow(false)
    }

    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }

    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const CancelAlert = () => {
        SetAlertDeletePopupType2("error")
    }
    const DeleteAlert2 = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType2("success")
    }
    const HandleDelete = async (id) => {
        try {
            const response = await axiosConfig.delete(`/masters/module/${id}/`)
            console.log(response)
            setDeletepopupType("success")
            setErrorMessage('')
            SetAlertDeletePopupType("error")
            getModules();
        } catch (error) {
            console.log("Error deletting module : ", error.response.data.message)
            const ErrorMessage = error.response.data.message
            setErrorMessage(ErrorMessage)
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
        }
    }

    const formSubmit = (Data) => {
        console.log(Data)
        if (Data.id) {
            axiosConfig.patch(`/masters/module/${Data.id}/`, Data).then(res => {
                console.log(res)
                getModules();
                setShow(false);
                setFormData({})
                setpopupType('success')
            }).catch(error => {
                const ErrorData = []
                ErrorData.push({
                    name: error.response.data.name || ["null"],
                    code: error.response.data.code || ["null"],
                })
                if (ErrorData[0].name[0] === "This field must be unique.") {
                    SetError("Name Already Exists.")

                }
                if (ErrorData[0].code[0] === "This field must be unique.") {
                    SetError("Name Already Exists.")

                }
                setpopupType("error")
            })
        } else {
            axiosConfig.post("/masters/module/", Data).then(res => {
                console.log(res)
                getModules();
                setShow(false);
                setFormData({})
                setpopupType("success")
            }).catch(error => {
                const ErrorData = []
                ErrorData.push({
                    name: error.response.data.name || ["null"],
                    code: error.response.data.code || ["null"],
                })
                if (ErrorData[0].name[0] === "This field must be unique.") {
                    SetError("Name Already Exists.")

                }
                if (ErrorData[0].code[0] === "This field must be unique.") {
                    SetError("Name Already Exists.")

                }
                setpopupType("error")
            })
        }
    }

    const HandleEdit = async (id) => {
        try {
            const response = await axiosConfig.get(`/masters/module/${id}/`)
            console.log(response.data, "edit data")
            setFormData(response.data)
            setShow(true)
        } catch (error) {
            console.log("Error getting module edit data : ", error)
        }
    }
    const handleShow = (moduleId) => {
        console.log(moduleId, "moduleId")
        setSubModuleData({
            ...subModuleData,
            module: moduleId
        })
        setSubmoduleShow(true)
        SetError('')
    }
    const SubmitSubmodule = async (Data) => {
        console.log(Data)
        console.log(Data.module_actions)
        const updatedData = {
            code: Data.code,
            name: Data.name,
            description: Data.description,
            order_by: Data.order_by,
            actions: Data.actions.split(', '),
            module: Data.module
        }
        console.log(updatedData)
        if (Data.id) {
            axiosConfig.put(`/masters/submodule/${Data.id}/`, updatedData).then(res => {
                console.log(res)
                getModules();
                setSubmoduleShow(false);
                setSubModuleData({})
                SetError('')
                setMessage('Sub Module Updated Successfully')
                setpopupType('success')
            }).catch(error => {
                console.log(error)
                SetError(error.response.data.error)
                setpopupType("error")
                setSubmoduleShow(true)
            })
        } else {
            axiosConfig.post("/masters/submodule/", updatedData).then(res => {
                console.log(res)
                getModules();
                setSubmoduleShow(false);
                setSubModuleData({});
                setMessage('')
                SetError('')
                setpopupType("success")
            }).catch(error => {
                console.log(error.response.data.error)
                SetError(error.response.data.error)
                setpopupType("error")
                setSubmoduleShow(true)
            })
        }
    }
    const HandleSubmoduleEdit = async (id) => {
        try {
            const response = await axiosConfig.get(`/masters/submodule/${id}/`)
            console.log(response.data, "edit data")
            const actions = response.data.module_actions.map(action => action.action_name);
            const actionsString = actions.join(', ');
            setSubModuleData({
                ...response.data,
                actions: actionsString,
                module: response.data.module.id
            });
            setSubmoduleShow(true)
        } catch (error) {
            console.log("Error getting module edit data : ", error)
        }
    }
    const HandleSubmoduleDelete = async (id) => {
        try {
            const response = await axiosConfig.delete(`/masters/submodule/${id}/`)
            console.log(response, "submodule delete")
            setDeletepopupType("success")
            SetAlertDeletePopupType2("error")
            getModules();
        } catch (error) {
            console.log("Error deletting module : ", error)
            setDeletepopupType("error")
            SetAlertDeletePopupType2("error")
        }
    }
    useEffect(() => {
        getModules();
        PermitData();
    }, [])

    return (
        <>
            <div className="dashboard-header">
                <h5 className="dashboard-subtitle">View Module</h5>
                {actionPermisson && actionPermisson.can_add && <button className="btn btn-white" onClick={handleClick}>Add New</button>}
            </div>
            <div className="tablescroll-clientbill-parent">
                <div className="tablescroll-clientbill">
                    {modules.length > 0 &&
                        modules.map(module => {
                            return (
                                <>
                                    <div className="module-header">
                                        <div className="module-left">
                                            <div>{module.name} - {module.code}</div>
                                            <div>
                                                <MdOutlineEdit className="module-icon" onClick={() => HandleEdit(module.id)} />
                                                <MdDeleteOutline className="module-icon" onClick={() => DeleteAlert(module.id)} />
                                            </div>
                                        </div>
                                        {/* <div className="up-down">
                                            <MdArrowDropUp className="module-icon" />
                                            <MdArrowDropDown className="module-icon" />
                                        </div> */}
                                    </div>
                                    {module.sub_module.map(submodule => {
                                        return (
                                            <div className="submodule">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="submodule-col">
                                                            <div className="submodule-line">Sub Module</div>
                                                            <div>{submodule.name} - {submodule.code}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="submodule-col">
                                                            <div className="submodule-line">Actions</div>
                                                            <div className="submodule-permissions">
                                                                {submodule.module_actions.map(moduleaction => {
                                                                    return (
                                                                        <div>{`${moduleaction.action_name},`}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="submodule-actions">
                                                            <MdOutlineEdit className="module-icon" onClick={() => HandleSubmoduleEdit(submodule.id)} />
                                                            <MdDeleteOutline className="module-icon" onClick={() => DeleteAlert2(submodule.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="up-down">
                                                    <MdArrowDropUp className="module-icon" />
                                                    <MdArrowDropDown className="module-icon" />
                                                </div> */}
                                            </div >
                                        )
                                    })}
                                    <div className="add-new" onClick={() => handleShow(module.id)}>+ Add New Sub Module</div>
                                </>
                            )
                        })
                    }
                </div>
            </div >
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Module</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} />
                </Modal.Body>
            </Modal>
            <Modal show={submoduleShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Sub Module</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={MastersModal2} formData={subModuleData} formSubmit={SubmitSubmodule} error={Error} />
                </Modal.Body>
            </Modal>
            {
                popupType !== "" &&
                <PopupModal Navigation="/dashboard/masters/module" popupType={popupType} setpopupType={setpopupType} Message={message} />
            }
            {
                deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} Message={errormessage} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
            {
                AlertDeletePopupType2 === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType2} HandleDelete={HandleSubmoduleDelete} CancelAlerrt={CancelAlert} row={RowId} />
            }
        </>
    )
}

export { ModuleScreen };