import React from "react";
import { DashboardLayout } from "../Components";
const ClientBillInternational=()=>{
    return(
        <div>
             
            <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client International</h5>
                </div>
                <div className="mt-5">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page is work in progress</p>
                </div>
            
        </div>
    )
}
export {ClientBillInternational}