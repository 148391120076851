import React from "react";
import "./SidePanelDetail.css";
import { RadiologistsDetails } from "./RadiologistsDetails";

const SidePanelDetail = ({ setActiveItem }) => {

    return (
        <div className="sidepanel-details">
            {localStorage.getItem('activeSideData') === 'Radiologist' &&
                <RadiologistsDetails setActiveItem={setActiveItem} />
            }
            {localStorage.getItem('activeSideData') === 'Clients' &&
                <div>Clients Content</div>
            }
            {localStorage.getItem('activeSideData') === 'Chat' &&
                <div>Chat Content</div>
            }
            {localStorage.getItem('activeSideData') === 'Others' &&
                <div>Others Content</div>
            }
        </div>
    )
}

export { SidePanelDetail }