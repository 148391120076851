import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const DepartmentEdit = () => {
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.Department
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/departments/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                    department_code:error.response.data.department_code||["null"],
                    department_name:error.response.data.department_name||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].department_code[0]==="This field may not be blank."){
                SetError("Department Code field may not be blank.")
            }
            if(ErrorData[0].department_name[0]==="This field may not be blank."){
                SetError("Department Name field may not be blank.")
            }
            if(ErrorData[0].department_name[0]==="enter department name"){
                SetError("Department Name Already Exists")
            }
            if(ErrorData[0].department_code[0]=== "enter department code"){
                SetError("Department code Already Exists")
            }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/departments/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Department {id}</h5>
                    <NavLink to="/dashboard/masters/departments" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/departments" />
                }
            
        </>
    )

}
export {DepartmentEdit}