import React, { useEffect, useState, useRef } from "react";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import { DeletePopupModal } from "../Components";
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import { DashboardLayout, Pagination } from "../Components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
import { Button, Dropdown } from 'react-bootstrap';
const ClientBilledServices = ({ Code }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tableRef = useRef(null);
    let month = searchParams.get("month")
    console.log(month)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [deletePopupType, setDeletepopupType] = useState("")
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [tableId, setTableId] = useState()
    const [details, setDetails] = useState({
        Total_Clients: 0,
        Total_Cases: 0,
        Total_Price: 0.0,
    });
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [ExportData, setExportData] = useState([])
    const [search, SetSearch] = useState({
        search: "",
        month: ""
    })
    const [select, Setselct] = useState({
        month: ""
    })

    const [Datafilter, SetDatafilter] = useState([])
    const [page, NextPage] = useState(1)
    console.log(page)
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const GetData = async () => {
        if (month && month.length > 0) {

            setLoading(true)
            try {

                const responce = await axiosConfig.get(`client/group_by_client/?month=${month}&page=${currentPage}&page_size=${RowPerPage}`);
                console.log(responce.data)
                SetData(responce.data.results);
                SetDatafilter(responce.data.results)
                setLoading(false);
                setTotalRows(responce.data.count);
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    const GetDeatail = async () => {
        if (month && month.length > 0) {

            setLoading(true)
            try {

                const responce = await axiosConfig.get(`client/total_count_in_cbsummary/${month}/`);
                console.log(responce.data)
                setDetails(responce.data);
                setLoading(false);
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
    const HandleExportData = async () => {
        const response = await axiosConfig.get(`client/group_by_client/?month=${month}&page=1&page_size=20`)
        console.log(response)
        setExportData(response.data.results)
        let count = Math.ceil(response.data.count / 20)
        let number = []
        console.log(count)
        for (let i = 1; i < count + 1; i++) {
            if (i != 1) {
                number.push(i)
            }
        }
        setTimeout(async function () {
            number.forEach(async (page) => {
                try {
                    const response = await axiosConfig.get(`client/group_by_client/?month=${month}&page=${page}&page_size=20`)
                    setExportData(prevData => [...prevData, ...response.data.results])
                    setLoading(false)
                }
                catch (error) {
                    console.log(error)
                    setLoading(false)
                }
            })
        }, 500)
    }

    useEffect(() => {
        HandleExportData();
        GetData();
        PermitData();
        GetDeatail();
        calculateDataTableHeight();
    }, [currentPage, RowPerPage, currentColumn, currentDirection, month]);
    const HandleNextPage = () => {
        NextPage(page + 1)
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        const response = Datafilter.filter(data => data["Inst.Group"] && data["Inst.Group"].toLowerCase().includes(value.toLowerCase()) || data.client_code && data.client_code.toLowerCase().includes(value.toLowerCase()) || data.client_name && data.client_name.toLowerCase().includes(value.toLowerCase()) || data.total_amount && data.total_amount.toString().includes(value) || data.no_of_cases && data.no_of_cases.toString().includes(value) || data.client_id && data.client_id.toString().includes(value))
        SetData(response)
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        month = value
        setSearchParams({ 'month': value });
        GetData();
    }
    const HandleMouse = (row) => {
        setTableId(row.client_id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const columns = [
        // <th>ACTIONS</th>
        {
            name: "CLIENT CODE",
            selector: "client_code",
            cell: (row) => `${row.client_code}`,
            sortable: true,
            maxWidth: "250px",
            left: true

        },
        {
            name: "CLIENT NAME",
            selector: "client_name",
            cell: (row) => `${row.client_name}`,
            sortable: true,
            maxWidth: "250px",
            left: true

        },

        {
            name: "ADDRESS",
            selector: "address",
            cell: (row) => `${row.address}`,
            sortable: true,
            maxWidth: "250px",
            hide: 'sm',

        },
        {
            name: "NO OF CASES",
            selector: "no_of_cases",
            cell: (row) => `${row.no_of_cases}`,
            sortable: true,
            maxWidth: "250px",
            hide: 'sm',

        },
        {
            name: "NO OF CASES",
            selector: "no_of_cases",
            cell: (row) => `${row.no_of_cases}`,
            sortable: true,
            maxWidth: "250px",
            hide: 'sm',

        },
        {
            name: "Client Type",
            selector: "Client_Type",
            cell: (row) => `${row.Client_Type}`,
            sortable: true,
            maxWidth: "250px",
            hide: 'sm',

        },
        {
            name: "Total Amount",
            selector: "total_amount",
            cell: (row) => {
                return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(parseFloat(row.total_amount).toFixed(2));
            },
            sortable: true,
            maxWidth: "250px",
        },
        {
            name: 'Actions',
            cell: row => {
                const HandleView = () => {
                    return navigate(`/dashboard/billing/client-bills/${row.client_id}/view/${month}`);
                };
                const shouldShowActions = tableId === row.client_id;

                return (
                    <div className={shouldShowActions ? 'show' : 'hide'}>
                        {shouldShowActions && (
                            <div onClick={HandleView}>
                                <AiOutlineEye />
                                /
                                <MdOutlineEdit />
                            </div>
                        )}
                    </div>
                );
            },
        }

    ]
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`;
        setDataTableHeight(newHeight);
    };
    const HandleView = (clientid) => {
        return navigate(`/dashboard/billing/client-bills/${clientid}/view/${month}`);
    }
    function handleDownloadExcel() {
        const header = ["S.No",
            "Inst.Group",
            "CLIENT CODE",
            "CLIENT NAME",
            "ADDRESS",
            "NO OF CASES",
            "TOTAL AMOUNT",
        ];
        const body3 = ExportData && ExportData.map((res, index) => [index + 1, res["Inst.Group"], res.client_code, res.client_name, res.address, res.no_of_cases, Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(parseFloat(res.total_amount).toFixed(2))])
        console.log(body3)
        downloadExcel({
            fileName: `${"clientBillServices" + "." + "xls"}`,
            sheet: `${"clientBillServices" + "." + "xls"}`,
            tablePayload: {
                header,
                body: body3,
            },
        });
    }
    return (
        <>
            
                {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Final Bills</h5>
                    <input type="month" name="month" className="form-control month-selector" value={month} onChange={HandleSelect} />
                </div> */}
                <div className="tablescroll-clientbill-parent">
                    <div className="tablescroll-clientbill">
                        <div className="table-header-menu">
                            <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                <Dropdown.Toggle variant="">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onClick={handleCheckboxClick}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item>Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else here</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <MdOutlineRefresh className="sidebar-icon-table" />
                            <CiMenuKebab className="sidebar-icon-table" />
                            <input type="month" name="month" className="form-control month-selector" value={month} onChange={HandleSelect} />
                        </div>
                        <div className="table-responsive table-resp">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page, totalRows) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer
                                fixedHeader
                                customStyles={tableCustomStyles}
                                fixedHeaderScrollHeight={dataTableHeight}
                                selectableRows
                                onSelectedRowsChange={({ selectedRows }) =>
                                    console.log(selectedRows)
                                }
                                selectableRowsHighlight
                                highlightOnHover
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                subHeader
                                progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                subHeaderComponent={
                                    <div className="serach-class">
                                        <h5 className="dashboard-subtitle1">NO.Of Clients: <br></br> IND:{details.Total_Clients_ind}<br></br>INT:{details.Total_Clients_int}</h5>
                                        <h5 className="dashboard-subtitle1">NO.Of Cases:  <br></br>IND:{details.Total_Cases_ind}<br></br>INT:{details.Total_Cases_int}</h5>
                                        <h5 className="dashboard-subtitle1">Total Price: <br></br>IND:{details.Total_Price_ind}<br></br>INT:{details.Total_Price_int}</h5>
                                        <button type="submit" className="btn btn-primary" onClick={handleDownloadExcel}>Export</button>
                                        <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />
                                    </div>
                                } />
                        </div>
                    </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            
        </>
    )
}
export { ClientBilledServices }