import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import { MdOutlineEdit } from "react-icons/md";
import { useParams } from "react-router-dom";

const AccountSetting = () => {
    let { id } = useParams();
    const MastersModal = Masters.AccountSetting;
    const MastersModal1 = Masters.Reset_Password;
    const [popupType, setpopupType] = useState("");
    const [readOnly, setReadOnly] = useState(true);
    const [readonlyy, setReadonlyy] = useState(true);
    const [passwordError, setPasswordError] = useState("");
    const [clientUser, setClientUser] = useState("")
    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: ''
    })
    const [Data, setData] = useState({
        contract_period_start_date: '',
        contract_period_end_date: '',
        reminders: ''
    })
    const [validation, setValidation] = useState(null);
    const isreminder = (reminder, startDate, endDate) => {
        return (
            new Date(reminder) >= new Date(startDate) &&
            new Date(reminder) <= new Date(endDate)
        );
    }
    console.log(Data)
    const formSubmit1 = (Data) => {
        console.log(Data)
        let formDataSend = {}
        formDataSend["contract_period_start_date"] = Data.contract_period_start_date
        formDataSend["contract_period_end_date"] = Data.contract_period_end_date
        formDataSend["reminders"] = Data.reminders
        console.log(formDataSend)
        if (!isreminder(Data.reminders, Data.contract_period_start_date, Data.contract_period_end_date)) {
            setValidation('Reminders date must be in the range of  start date and end date.');
            return;
        }
        setValidation(null);
        axiosConfig.patch(`/client/clients/${id}/`, formDataSend).then(res => {
            console.log(res.data)
            setData(res.data)
            setReadonlyy(true)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data.error)
            setpopupType("error")
        })
    }
    const GetData = () => {
        axiosConfig.get(`/client/clients/${id}/`).then(res => {
            console.log(res.data)
            setData(res.data)
            const users = res.data.user;
            console.log(users)
            const ClientUser = users.filter(user => {
                return user.roles[0].role_name === "Client Admin";
            });
            console.log(ClientUser)
            const clientUserId = ClientUser.map(user => user.id);
            console.log(clientUserId)
            setClientUser(clientUserId)
        }).catch(error => {
            console.log(error)
        })
    }
    const formSubmit = (formData) => {
        setPasswordError("")
        console.log(formData)
        let postData = { ...formData };
        console.log(postData)
        axiosConfig.put(`/account/users/${clientUser}/password_reset/`, postData).then(res => {
            console.log(res)
            setFormData(res)
            setPasswordError("")
            setReadOnly(true)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data.error)
            let errorMessage = error.response.data.error
            setPasswordError(errorMessage)
            setpopupType("error")
        })
    }
    useEffect(() => {
        GetData()
    }, [])
    return (
        <>
            <div>
                {validation && (
                    <div className="alert alert-danger password-alert" role="alert">
                        {validation}
                    </div>
                )}
                <FormParser modelObject={MastersModal}
                    formData={Data}
                    buttonTitle='Save'
                    formSubmit={formSubmit1}
                    error={Error}
                    readOnly={readonlyy}
                    setReadOnly={setReadonlyy} />
                {readonlyy &&
                    <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadonlyy(false)}>
                        <div>Edit</div>
                        <MdOutlineEdit className="ms-5" />
                    </button>
                }
            </div>
            <div>
                {passwordError && (
                    <div className="alert alert-danger password-alert" role="alert">
                        {passwordError}
                    </div>
                )}
                <FormParser
                    modelObject={MastersModal1}
                    buttonTitle='Reset Password'
                    formData={formData}
                    formSubmit={formSubmit}
                    error={Error}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                />
                {readOnly &&
                    <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                        <div>Edit</div>
                        <MdOutlineEdit className="ms-5" />
                    </button>
                }
            </div>
            {popupType !== "" &&
                <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} />
            }
        </>
    );
};
export { AccountSetting };


