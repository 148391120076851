import React from "react";
import { DashboardLayout, FormParser } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const RoleModulePermissionAdd = () => {
    const MastersModal=Masters.RoleModulePermission
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/account/roleModulepermission/",Data).then(res=>{
                   console.log(res)
        }).catch(error=>{
            console.log(error)
        })
      }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add RoleModule Permession</h5>
                    <NavLink to="/dashboard/masters/rolemodulepermission" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit}/>
            
        </>
    )

}
export {RoleModulePermissionAdd}