import React, { useEffect, useState } from "react";
import { DashboardLayout,DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';

const UploadCasesList = () => {
    const[deletePopupType,setDeletepopupType]=useState("")
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [Datafilter, SetDatafilter] = useState([])
    const [search, SetSearch] = useState({
        search: ""
    })
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get("/cases/Uploadfile/")
            console.log(responce.data)
            SetData(responce.data)
            SetDatafilter(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }

    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        const response = Datafilter.filter(data => data.client_name.toLowerCase().includes(value.toLowerCase())||data.priority.toLowerCase().includes(value.toLowerCase())||data.modality.toLowerCase().includes(value.toLowerCase())||data.patient_name.toLowerCase().includes(value.toLowerCase())|| data.age.toString().includes(value)||data.gender.toLowerCase().includes(value.toLowerCase()))
        console.log(response)
        SetData(response)
    }
    useEffect(() => {
        GetData();
    }, [])

    const columns = [
        {
            name: "S.No",
            selector: (row,i) => i+1,
            sortable: true
        },
        {
            name: "Id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Patient Name",
            selector: row => row.patient_name,
            sortable: true
        },
        {
            name: "Client",
            selector: row => row.client_name,
            sortable: true
        },
        {
            name: "Priority",
            selector: row => row.priority,
            sortable: true
        },
        {
            name: "Modality",
            selector: row => row.modality,
            sortable: true
        },
        {
            name: "Age",
            selector: row => row.age,
            sortable: true
        },
        {
            name: "Gender",
            selector: row => row.gender,
            sortable: true
        },
        //  {
        //     name: "ACTIONS",
        //     selector: row => {
        //         const HandleEdit = async() => {
        //             return navigate(`/dashboard/masters/sub-departments/${row.id}/edit`);
        //         }
        //         const HandleDelete = async() => {
        //             await axiosConfig.delete(`/masters/subdepartments/${row.id}/`).then(()=>{
        //                 setDeletepopupType("success")
        //                 GetData()
        //             }).catch((error)=> {
        //                 setDeletepopupType("error")
        //                 console.log(error)
        //             })
        //         }
        //         return (
        //             <>
        //                 <div className="row">
        //                     <div className="col-4">
        //                         <img className="dashboard-tabe-data-actions" src={require("./images/view.png")} alt="view" />
        //                     </div>
        //                     <div className="col-4">
        //                         <img className="dashboard-tabe-data-actions" onClick={HandleEdit} src={require("./images/edit.png")} alt="edit" />
        //                     </div>
        //                     <div className="col-4">
        //                         <img className="dashboard-tabe-data-actions" onClick={HandleDelete} src={require("./images/delete.png")} alt="delete" />
        //                     </div>
        //                 </div>
        //             </>
        //         )

        //     }
        // }
    ]
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Cases</h5>
                    <NavLink to="/dashboard/billing/upload-cases" ><button className="btn btn-white">+ Upload Data</button></NavLink>
                </div>
                <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination fixedHeader progressPending={loading}
                        progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}
                    subHeader
                     subHeaderComponent={
                        <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}/>
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal deletePopupType={deletePopupType} />
                }
            
        </>
    )
}
export { UploadCasesList }