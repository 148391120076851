import React from 'react';
import "../styles/styles.scss"
import { DashboardLayout } from '../Components';

function Scss() {
  return (
    <div className="main-container">
      <h1>Hello, React with</h1>
      <p>Hello</p>
    </div>
  );
}

export  {Scss};
