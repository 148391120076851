import React, { useState, useEffect, useRef } from 'react';
import "./CaseMesaages.css";
import axiosConfig from "../Service/axiosConfig";
import { useParams } from "react-router-dom";
import { IoMdAttach } from "react-icons/io";
import { MdOutlineImage } from "react-icons/md";
import { BsSend } from "react-icons/bs";
import { useWebSocket } from '../Components/DashboardLayout/DashboardLayout';

const CaseMessage = () => {
    const webSocket = useWebSocket();
    console.log(webSocket)
    let { id } = useParams();
    let user_id = JSON.parse(localStorage.getItem("userId"))
    const [chatLog, setChatLog] = useState([]);
    const [chatMessage, setChatMessage] = useState(null);
    const [displayTopMessage, setDisplayTopMessage] = useState(false);
    const [count, setCount] = useState()
    const [page, setPage] = useState(1);
    const textContainerRef = useRef(null);
    const [triggerScrollToTop, setTriggerScrollToTop] = useState(true);
    const handleSendMessage = () => {
        const messageObject = { message: chatMessage, case_id: id };
        console.log(chatMessage, messageObject, "inside handle send", JSON.stringify(messageObject))
        webSocket.send(JSON.stringify(messageObject));
        setChatMessage('');
        GetChat(page);
    };
    const GetChat = async (pageNumber) => {
        try {
            const response = await axiosConfig.get(`/message_framework/case-messages/?case=${id}&user=&page=${pageNumber}`);
            setCount(Math.ceil(response.data.count / 20));
            const chatLogData = response.data.results.map(result => ({
                sort: result.id,
                content: result.content,
                role: result.user.roles.length > 0 ? result.user.roles[0].role_name : '',
                created_on: new Date(result.created_on).toLocaleString('en-US', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                }).replace(',', ''),
                userId: result.user.id,
                profile_image: result.user.profile_image,
                name: `${result.user.first_name} ${result.user.last_name}`
            }));
            chatLogData.sort((a, b) => a.sort - b.sort);
            console.log(chatLogData)
            setChatLog(chatLogData);
        } catch (error) {
            console.log(error);
        }
    };
    const formatTimeDifference = (createdOn) => {
        const now = new Date();
        const createdDate = new Date(createdOn);
        const diffInMilliseconds = now - createdDate;
        const days = Math.floor(diffInMilliseconds / (24 * 60 * 60 * 1000));
        const hours = Math.floor((diffInMilliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((diffInMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((diffInMilliseconds % (60 * 1000)) / 1000);
        if (days > 0) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        } else if (hours > 0) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutes > 0) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else {
            return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
        }
    };
    const DisplayDateLine = (currentIndex, chatLog) => {
        if (currentIndex > 0) {
            const currentMessageDate = new Date(chatLog[currentIndex].created_on);
            const previousMessageDate = new Date(chatLog[currentIndex - 1].created_on);
            const isSameDay = (date1, date2) => {
                return date1.getFullYear() === date2.getFullYear() &&
                    date1.getMonth() === date2.getMonth() &&
                    date1.getDate() === date2.getDate();
            };
            if (!isSameDay(currentMessageDate, previousMessageDate)) {
                if (isSameDay(currentMessageDate, new Date())) {
                    return { displayLine: true, date: 'Today' };
                } else {
                    const formattedDate = currentMessageDate.toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                    });
                    return { displayLine: true, date: formattedDate };
                }
            }
        }
        return { displayLine: false, date: null };
    };
    const scrollToBottom = () => {
        textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
        console.log(textContainerRef, "textContainerRef")
    };
    const handleScroll = () => {
        if (textContainerRef.current.scrollTop === 0 && triggerScrollToTop) {
            setPage((prevPage) => prevPage + 1);
            setTriggerScrollToTop(false);
        }
    };
    useEffect(() => {
        scrollToBottom();
    }, [chatLog, page]);
    useEffect(() => {
        if (page > count) {
            setDisplayTopMessage(true);
        } else {
            setDisplayTopMessage(false);
            setTriggerScrollToTop(true);
            GetChat(page);
        }
        if (webSocket && chatMessage !== null) {
            handleSendMessage()
        }
    }, [webSocket, id, page, count]);
    return (
        <div>
            <div className='text-container' ref={textContainerRef} onScroll={handleScroll} >
                {/* {displayTopMessage && (
                    <div className="top-message">
                        You've reached the top of the messages.
                    </div>
                )} */}
                {chatLog.map((message, index) => (
                    <React.Fragment key={index}>
                        {DisplayDateLine(index, chatLog).displayLine && (
                            <div className="line">
                                <span className='hr-text'>{DisplayDateLine(index, chatLog).date}</span>
                            </div>
                        )}
                        <div className={` ${user_id === message.userId ? 'send-text' : 'receive-text'}`}>
                            <div className={` ${user_id === message.userId ? 'profile-details' : 'profile-detail'}`}>
                                <img src={message.profile_image} alt="Profile" className="profile-image" />
                                <p className='user-name'>{message.name}{user_id !== message.userId && <span className='user-role'>({message.role})</span>}  <span className='user-role'>{message.created_on}</span></p>
                            </div>
                            <div className='message-content'>
                                <p className={`${user_id === message.userId ? 'chat-send' : 'chat-receive'}`}>{message.content}</p>
                                <p className='text-days'>{formatTimeDifference(message.created_on, index, chatLog)}</p>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="chat-container">
                <div className="message-container">
                    <div className="left-side-icons">
                        <IoMdAttach size={24} />
                        <MdOutlineImage size={24} />
                    </div>
                    <input
                        type="text"
                        className='msg-text form-control'
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                        placeholder="Type a message..."
                    />
                    <button className="send-button" onClick={handleSendMessage}>
                        <BsSend size={24} />
                    </button>
                </div>
            </div>
        </div >
    )
}
export { CaseMessage };