import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import { MdOutlineRateReview } from "react-icons/md"
import { AiOutlineEye, AiOutlinePrinter } from "react-icons/ai"
import { FiDownload } from "react-icons/fi"
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { FormParser, PopupModal } from "../Components";

const CaseReports = () => {
    let { id } = useParams();
    console.log(id, "worklistid")
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [popupType, setpopupType] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [tableId, setTableId] = useState()
    const [Data, SetData] = useState([])
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleMouse = (row) => {
        setTableId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const GetData = async () => {
        setLoading(true);
        console.log(id, "casereport")
        try {
            const response = await axiosConfig.get(
                `/cases/case-reports/?case=${id}&page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            console.log(response.data.results)
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);
    const handlePdfView = (pdf_path) => {
        console.log(pdf_path)
        window.open(pdf_path, '_blank');
    };
    const handleDownload = async (pdf_path, pdf_filename) => {
        try {
            const response = await fetch(pdf_path);
            console.log(response, "download response")
            const blob = await response.blob();
            console.log(blob, "download blob")
            const url = window.URL.createObjectURL(blob);
            console.log(url, "download url")
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", pdf_filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading the PDF:", error);
        }
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Service Name",
            selector: "service_template.service_id.id",
            cell: (row) => {
                if (row.service_template && row.service_template.service_id) {
                    return row.service_template.service_id.service_name;
                } else {
                    return null;
                }
            },
            sortable: true
        },
        {
            name: "Date & Time",
            selector: "created_at",
            cell: (row) => `${row.created_at}`,
            sortable: true
        },
        {
            name: "Reported By",
            selector: "radiologist.user",
            cell: (row) => `${row.radiologist.first_name} ${row.radiologist.last_name}`,
            sortable: true
        },
        {
            name: "Actions",
            selector: row => {
                const shouldShowActions = tableId === row.id
                return (
                    <>
                        {
                            shouldShowActions && (
                                <div className="row">
                                    <div className="col-3">
                                        <a href={row.pdf_path} target="_blank" className="report-view" ><AiOutlineEye className="dashboard-tabe-data-actions" /></a>
                                    </div>
                                    <div className="col-3">
                                        <a href={row.pdf_path} download="report.pdf" target="_blank" className="report-view"  ><FiDownload className="dashboard-tabe-data-actions" /></a>
                                    </div>
                                    <div className="col-3">
                                        <AiOutlinePrinter className="dashboard-tabe-data-actions" onClick={() => handlePdfView(row.pdf_path)} />
                                    </div><div className="col-3">
                                        <MdOutlineRateReview className="dashboard-tabe-data-actions" />
                                    </div>
                                </div>
                            )
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <div className="table-responsive dtble-noheader">
                <DataTable columns={columns} data={Data} paginationTotalRows={totalRows}
                    paginationPerPage={RowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                        GetData(currentColumn, currentDirection);
                    }}
                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                    onSort={handleSort}
                    paginationServer
                    fixedHeader
                    customStyles={tableCustomStyles}
                    fixedHeaderScrollHeight="450px"
                    selectableRows
                    onSelectedRowsChange={({ selectedRows }) =>
                        console.log(selectedRows)
                    }
                    selectableRowsHighlight
                    highlightOnHover
                    // subHeader
                    onRowMouseEnter={HandleMouse}
                    onRowMouseLeave={HandleRowmouseLeave}
                    progressPending={loading}
                    progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}>
                </DataTable>
                {popupType !== "" &&
                    <PopupModal popupType={popupType} setpopupType={setpopupType} />
                }
            </div>
        </>
    )
}

export { CaseReports };