import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const UsersAdd = () => {
    const MastersModal=Masters.User
    const[popupType,setpopupType]=useState("")
    const[id,setId]=useState()
    const[Error,SetError]=useState("")
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/account/users/",Data,{is_active:"yes"}).then(res=>{
                   console.log(res)
                   setId(res.data.id)
                   setpopupType("success")
        }).catch(error=>{
            let formData=[]
            formData.push({email:error.response.data.email||["null"],
                           mobileno:error.response.data.mobileno||["null"],
        })
        if (formData[0].email[0] === "user with this email address already exists.") {
            SetError("Email Already Exists")
        }
        if (formData[0].mobileno[0] === "user with this mobileno already exists.") {
            SetError("Mobile Number Already Exists")
        }
            setpopupType("error")
            console.log(formData)
        })
      }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Users</h5>
                    <NavLink to="/dashboard/masters/users" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={`/dashboard/masters/users`} Message="Users Added Successfully "/>
                }
            
        </>
    )

}
export {UsersAdd}