import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineEdit } from "react-icons/md";
const Signature = () => {
    const { id } = useParams();
    const [Data, setData] = useState({})
    const [popupType, setpopupType] = useState("")
    const [signatureImage, setSignatureImage] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        console.log(name, value)
        setData({ ...Data, [name]: value })
        handleFileChange(value)
    }
    const handleEdit = (e) => {
        e.preventDefault()
        setReadOnly(false)
    }
    const handleFileChange = (file) => {
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setSignatureImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };
    const formSubmit = (e) => {
        e.preventDefault();
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.patch(`/account/users/${id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
            setReadOnly(true)
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    useEffect(() => {
        axiosConfig.get(`/account/users/${id}/`).then((res) => {
            const digitalsignator = res.data.digital_signator;
            console.log(digitalsignator, " capture signature")
            setSignatureImage(digitalsignator);
        }).catch((error) => {
            console.log(error);
            setSignatureImage("");
        });
    }, [id]);
    return (
        <div>
            <form className="prefrence-paddin">
                <div className="row">
                    <div className="col-6 form-group">
                        <label>Change or Upload Signature:</label>
                        <input type="file" className="form-control"
                            placeholder="Current Signature"
                            name="digital_signator"
                            onChange={HandleFiles}
                            disabled={readOnly} 
                            required
                        />
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label >Current Signature:</label>
                            <div className="input-container">
                                {signatureImage ? (
                                    <img
                                        src={signatureImage}
                                        alt="Digital Signature"
                                        className="current-signature"
                                    />
                                ) : <div>Current Signature</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {readOnly ? (
                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={(e) => handleEdit(e)}>
                    <div>Edit</div>
                    <MdOutlineEdit className="ms-5" />
                </button>
            ) : (
                <button className="btn btn-primary" style={{ marginLeft: '30px' }} onClick={formSubmit}>Save</button>
            )}
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </div>
    )
}
export { Signature }