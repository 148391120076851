import React, { useEffect, useState } from "react";
import { FormParser, PopupModal } from "../Components";
import { useNavigate } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
// import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
const ClientAdd = () => {
    const [popupType, setpopupType] = useState("");
    const MastersModal = Masters.CreateClient
    const [clientid, SetClientid] = useState()
    const [passwordError, setPasswordError] = useState("");
    const formSubmit = (Data) => {
        console.log(Data)
        let postData = { ...Data };
        if (postData.clienttype === "DOMESTIC") {
            postData.clienttype = 1
        }
        if (postData.clienttype === "INTERNATIONAL") {
            postData.clienttype = 2
        }
        console.log(postData, "postData")
        if (postData.password === postData.confirm_password) {
            axiosConfig.post("/client/clients/", postData).then(res => {
                console.log(res)
                SetClientid(res.data.client_id)
                setpopupType("success")
                setPasswordError(""); 
            }).catch(error => {
                console.log(error)
                if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.message;
                    setPasswordError(errorMessage);
                }
                setpopupType("error")
            })
        } else {
            console.log("Password and confirmpassword do not match");
            setPasswordError("Password and confirm password do not match");
        }
    }
    useEffect(() => {

    }, [])
    return (
        <div>
            <div className="">
                {passwordError && (
                    <div className="alert alert-danger" role="alert">
                        {passwordError}
                    </div>
                )}
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} button="btn-block" />
            </div>
            {popupType !== "" &&
                <PopupModal popupType={popupType} Navigation={`/dashboard/users/clients/${clientid}/edit`} />
            }
        </div>
    )

}
export { ClientAdd }
