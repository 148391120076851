import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ReactQuillComponent({SetData,name,Data,value, readOnly }) {
  const [text, setText] = useState('');
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }], // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
    [{ 'direction': 'rtl' }], // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean'] // remove formatting button
  ];
  const handleChange = (value) => {
   SetData({...Data,[name]:value});
  };
  return (
    <div>
      <ReactQuill  modules={{ toolbar: toolbarOptions }} value={value} name={name} onChange={handleChange}  readOnly={readOnly}   />
    </div>
  );
}

export  {ReactQuillComponent};
