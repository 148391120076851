import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const AreaAdd = () => {
    const [Error, SetError] = useState("")
    const [data, SetData] = useState({
        city_or_town_id: "",
        code: "",
        name: "",
        pincode: "",
        address: ""
    })
    const[CustomError1,SetCustomError1]=useState("")
    const [CustomError, SetCustomError] = useState("")
    const [popupType, setpopupType] = useState("")
    const MastersModal = Masters.Area
    const formSubmit = (Data,setFieldErrors) => {
        console.log(Data)
        axiosConfig.post("/masters/area/", Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            Object.keys(error.response.data).forEach(fieldName => {
                switch (fieldName) {
                    case 'code':
                        if (error.response.data[fieldName][0] === "This field must be unique.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                        }
                        case 'pincode':
                        if (error.response.data[fieldName][0] === "Only 0-9 are allowed.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `Only 0-9 are allowed.` }));
                        }
                        case 'address':
                            if (error.response.data[fieldName][0] === "This field must be unique.") {
                                setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                            }
                        break;
                    default:
                        break;
                }
            });
                setpopupType("error")
        })
    }
    return (
        <>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Area</h5>
                    <NavLink to="/dashboard/masters/areas" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} CustomError={CustomError} CustomError1={CustomError1
                }  />
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/areas" />
                }
        </>
    )

}
export { AreaAdd }