import React, { useState, useEffect } from "react";
import Masters from "../Components/masters.json"
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineModeEditOutline, MdDeleteOutline } from 'react-icons/md';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";

const ProfessionalDetails = () => {
    const [masterModal, setMasterModal] = useState({})
    const [modalTitle, setModalTitle] = useState('')
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [loading, setLoading] = useState(false);
    const [qualifications, setQualifications] = useState([])
    const [registrations, setRegistrations] = useState([])
    const [experience, setExperience] = useState([])
    const [roleNames, setRoleNames] = useState([])
    // const [detailType, setDetailType] = useState('')
    const [totalExperience, setTotalExperience] = useState()
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({})
    const handleClose = () => {
        setShow(false);
        SetError('')
    }
    const handleShow = (e) => {
        const { name } = e.target
        setShow(true)
        setFormData('')
        if (name === 'Qualifications') {
            setMasterModal(Masters.Qualifications)
            setModalTitle('Add Qualifications')
        } else if (name === 'Registrations') {
            setMasterModal(Masters.Registrations)
            setModalTitle('Add Registration Details')
        } else if (name === 'Experience') {
            setMasterModal(Masters.Experience)
            setModalTitle('Add Experience')
        }
    }
    const convertUrlToFile = async (imageUrl) => {
        console.log("inside convertUrlToFile...")
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            console.log(blob);
            const file = new File([blob], imageUrl, { type: imageUrl });
            console.log(file);
            return file
        } catch (error) {
            console.error('Error converting URL to file:', error);
        }
    };

    const handleEdit = async (name, index) => {
        setShow(true)
        let form_data;
        if (name === "Qualifications") {
            setMasterModal(Masters.Qualifications)
            let image_file = await convertUrlToFile(qualifications[index].documnets)
            form_data = {
                ...qualifications[index],
                qualification_type: qualifications[index].qualification_type.id,
                qualification: qualifications[index].qualification.id,
                documnets: image_file
            }
            setFormData(form_data)
        } else if (name === "Registrations") {
            setMasterModal(Masters.Registrations)
            let image_file = await convertUrlToFile(registrations[index].documnets)
            form_data = {
                ...registrations[index],
                documnets: image_file
            }
            setFormData(form_data)
        } else if (name === "Experience") {
            setMasterModal(Masters.Experience)
            let image_file = await convertUrlToFile(experience[index].documnets)
            form_data = {
                ...experience[index],
                designation_role: experience[index].designation_role.id,
                documnets: image_file
            }
            setFormData(form_data)
        }
    }

    const DeleteAlert = (index) => {
        SetRowId(index)
        SetAlertDeletePopupType("success")
    }

    const handleDelete = async (index) => {
        let url = ``;
        if (masterModal === Masters.Qualifications) {
            url = `/account/qualification/${qualifications[index].id}/`
        } else if (masterModal === Masters.Registrations) {
            url = `/account/registration/${registrations[index].id}/`
        } else if (masterModal === Masters.Experience) {
            url = `/account/experience/${experience[index].id}/`
        }
        try {
            const response = await axiosConfig.delete(url)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
            GetQualifications()
        } catch (error) {
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
            console.log("Error deleting qualification : ", error)
        }
    }

    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }

    const GetQualifications = async () => {
        try {
            const qualification_details = await axiosConfig.get(`/account/qualification/?user=${id}`)
            const registration_details = await axiosConfig.get(`/account/registration/?user=${id}`)
            const experience_details = await axiosConfig.get(`/account/experience/?user=${id}`)
            setQualifications(qualification_details.data.results)
            setRegistrations(registration_details.data.results)
            setExperience(experience_details.data.results)
            const sumExperience = experience_details.data.results.reduce(
                (accumulator, result) => accumulator + (result.experience_in_years || 0),
                0
            );
            console.log(experience_details.data.results, sumExperience, "experience....")
            setTotalExperience(sumExperience)
        } catch (error) {
            console.log("Error getting radiologist qualifications : ", error)
        }
    }
    const formSubmit = (Data) => {
        let from;
        let to;
        let dataToPatch = {};
        let url = ``;
        if (masterModal === Masters.Qualifications) {
            dataToPatch = {
                "qualification_type": Data.qualification_type,
                "qualification": Data.qualification,
                "institution": Data.institution,
                "from_date": Data.from_date,
                "to_date": Data.to_date,
                "documnets": Data.documnets
            }
            from = new Date(Data.from_date);
            to = new Date(Data.to_date);
            url = Data.id ? `/account/qualification/${Data.id}/` : `/account/qualification/`;
        } else if (masterModal === Masters.Registrations) {
            dataToPatch = {
                "council_name": Data.council_name,
                "registration_no": Data.registration_no,
                "valid_from": Data.valid_from,
                "valid_upto": Data.valid_upto,
                "documnets": Data.documnets
            }
            from = new Date(Data.valid_from);
            to = new Date(Data.valid_upto);
            url = Data.id ? `/account/registration/${Data.id}/` : `/account/registration/`;
        } else if (masterModal === Masters.Experience) {
            dataToPatch = {
                "designation_role": Data.designation_role,
                "institution_name": Data.institution_name,
                "experience_in_years": Data.experience_in_years,
                "from_date": Data.from_date,
                "to_date": Data.to_date,
                "documnets": Data.documnets
            }
            from = new Date(Data.from_date);
            to = new Date(Data.to_date);
            url = Data.id ? `/account/experience/${Data.id}/` : `/account/experience/`;
        }
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (from > to) {
            SetError('From date should not be greater than To date')
            return
        }
        if (Data.id) {
            axiosConfig.patch(url, dataToPatch)
                .then(res => {
                    console.log(res.data);
                    handleClose();
                    setMessage("Updated successfully")
                    setpopupType("success");
                    GetQualifications();
                })
                .catch(error => {
                    setpopupType("error");
                    console.log("Error updating details", error);
                });
        } else {
            console.log(url, dataToPatch, "inside else...")
            let dataToPost = { ...dataToPatch, user: id }
            axiosConfig.post(url, dataToPost)
                .then(res => {
                    console.log(res.data);
                    handleClose();
                    setMessage("Added successfully")
                    setpopupType("success");
                    GetQualifications();
                })
                .catch(error => {
                    setpopupType("error");
                    console.log("Error creating details", error);
                });
        }
    }

    const GetRoleNames = async () => {
        try {
            const response = await axiosConfig.get(`/account/roleview/`)
            console.log(response.data.results)
            setRoleNames(response.data.results)
        } catch (error) {
            console.log("Error getting role names : ", error)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'experience') {
            setTotalExperience(value)
        }
    }

    useEffect(() => {
        GetQualifications();
        GetRoleNames();
    }, [])

    return (
        <>
            <div className="add-detail">
                <div className="detail-name">Qualifications</div>
                <button className="btn btn-primary" name="Qualifications" onClick={(e) => handleShow(e)}>ADD</button>
            </div>
            <div className="timeline">
                {qualifications.length > 0 && qualifications.map((qualification, index) => {
                    return (
                        <div className="timeline-item" key={index}>
                            <div className="timeline-dot"></div>
                            <div className="timeline-content row">
                                <div className="qualification-date col-4">
                                    <div>
                                        {qualification.qualification_type.code} - {qualification.from_date.split('-')[0]} - {qualification.to_date.split('-')[0]}
                                    </div>
                                    <div className="qualification">{qualification.qualification.code}</div>
                                    <div>{qualification.institution}</div>
                                </div>
                                <div className="certificate-file col-6">
                                    <div className="timeline-dot"></div>
                                    <div className="file-link">
                                        <a href={qualification.documnets} target="blank">{qualification.documnets}</a>
                                    </div>
                                </div>
                                <div className="timeline-icons col-2">
                                    <MdOutlineModeEditOutline onClick={() => handleEdit("Qualifications", index)} />
                                    <MdDeleteOutline onClick={() => DeleteAlert(index)} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="add-detail">
                <div className="detail-name">Registrations</div>
                <button className="btn btn-primary" name="Registrations" onClick={(e) => handleShow(e)}>ADD</button>
            </div>
            <div className="timeline">
                {registrations.length > 0 && registrations.map((registration, index) => {
                    return (
                        <div className="timeline-item" key={index}>
                            <div className="timeline-dot"></div>
                            <div className="timeline-content row">
                                <div className="qualification-date col-4">
                                    <div>
                                        {registration.council_name} - {registration.valid_from.split('-')[0]} - {registration.valid_upto.split('-')[0]}
                                    </div>
                                    <div className="qualification">{registration.registration_no}</div>
                                </div>
                                <div className="certificate-file col-6">
                                    <div className="timeline-dot"></div>
                                    <div className="file-link">
                                        <a href={registration.documnets} target="blank">{registration.documnets}</a>
                                    </div>
                                </div>
                                <div className="timeline-icons col-2">
                                    <MdOutlineModeEditOutline onClick={() => handleEdit("Registrations", index)} />
                                    <MdDeleteOutline onClick={() => DeleteAlert(index)} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="add-detail">
                <div className="detail-name">Experience</div>
                <button className="btn btn-primary" name="Experience" onClick={(e) => handleShow(e)}>ADD</button>
            </div>
            <div className="timeline">
                <div className="row ms-3 mb-3">
                    <div className="col-2">Total experience - </div>
                    <div className="col-2 d-flex">
                        <div>{totalExperience}</div>
                        <span className="ms-2">Years</span>
                    </div>
                </div>
                {experience.length > 0 && roleNames.length > 0 && experience.map((exp, index) => {
                    return (
                        <div className="timeline-item" key={index}>
                            <div className="timeline-dot"></div>
                            <div className="timeline-content row">
                                <div className="qualification-date col-4">
                                    {/* <div>
                                        {roleNames.find(role => role.id === exp.designation_role.id).role_name} - {exp.from_date.split('-')[0]} - {exp.to_date.split('-')[0]}
                                    </div> */}
                                    <div className="qualification">{exp.institution_name}</div>
                                    <div>{exp.experience_in_years} yrs</div>
                                </div>
                                <div className="certificate-file col-6">
                                    <div className="timeline-dot"></div>
                                    <div className="file-link">
                                        <a href={exp.documnets} target="blank">{exp.documnets}</a>
                                    </div>
                                </div>
                                <div className="timeline-icons col-2">
                                    <MdOutlineModeEditOutline onClick={() => handleEdit("Experience", index)} />
                                    <MdDeleteOutline onClick={() => DeleteAlert(index)} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser
                        modelObject={masterModal}
                        formSubmit={formSubmit}
                        formData={formData}
                        error={Error}
                    />
                </Modal.Body>
            </Modal>
            {popupType != "" && !show &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={handleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
            {deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
        </>
    )
}
export { ProfessionalDetails }