import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const ServicesAdd = () => {
    const[Data,SetData]=useState("");
    const[serviceid,SetServiceid]=useState()
    const[Error,SetError]=useState("")
     const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.Services
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/services/",Data).then(res=>{
                   console.log(res)
                   SetServiceid(res.data.id)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                service_name:error.response.data.service_name||["null"],
                service_code:error.response.data.service_code||["null"],
                    cpt_code:error.response.data.cpt_code||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].service_code[0]==="This field must be unique."){
                SetError("Service Code Already Exists")
            }
            if(ErrorData[0].cpt_code[0]==="This field must be unique."){
                SetError("Cpt Code Already Exists")
            }
            if(ErrorData[0].service_name[0]==="This field must be unique."){
                SetError("Tittle Already Exists ")
            }
            setpopupType("error")
        })
      }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Services</h5>
                    <NavLink to="/dashboard/masters/services" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={`/dashboard/masters/services/${serviceid}/edit`} />
                }
            
        </>
    )

}
export {ServicesAdd}