import React, { useEffect, useState } from "react";
import { FormParser, PopupModal } from "../Components";
import { MdOutlineEdit } from "react-icons/md";
import Masters from "../Components/masters.json"
import "./Pages.css";
import axiosConfig from "../Service/axiosConfig"
import { useParams } from "react-router-dom";

const BusinessInformation = ({ clientsEditData }) => {
    console.log(clientsEditData, "wjhZGDXjhuy")
    let { id } = useParams();
    const MastersModal = Masters.BusinessInformation
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [Error, setError] = useState("");
    const [formData, setFormData] = useState({
        ae_title: '',
        port_no: '',
        ip_address: '',
        website: '',
        fax: '',
        weekly_desired_hours: '',
        tat_expected_hours: '',
        volume_expected_per_day: '',
        business_type: '',
    })
    const formSubmit = (data) => {
        console.log(data)
        let formDataSend = {}
        formDataSend["ae_title"] = data.ae_title
        formDataSend["port_no"] = data.port_no
        formDataSend["ip_address"] = data.ip_address
        formDataSend["website"] = data.website
        formDataSend["fax"] = data.fax
        formDataSend["weekly_desired_hours"] = data.weekly_desired_hours
        formDataSend["tat_expected_hours"] = data.tat_expected_hours
        formDataSend["volume_expected_per_day"] = data.volume_expected_per_day
        formDataSend["business_type"] = data.business_type

        axiosConfig.patch(`/client/clients/${id}/`, formDataSend).then(res => {
            console.log(res)
            setpopupType("success")
            setReadOnly(true)
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    };
    console.log(clientsEditData)
    useEffect(() => {
        setFormData({
            ae_title: clientsEditData.ae_title,
            port_no: clientsEditData.port_no,
            ip_address: clientsEditData.ip_address,
            website: clientsEditData.website,
            fax: clientsEditData.fax,
            weekly_desired_hours: clientsEditData.weekly_desired_hours,
            tat_expected_hours: clientsEditData.tat_expected_hours,
            volume_expected_per_day: clientsEditData.volume_expected_per_day,
            business_type: clientsEditData.business_type ? clientsEditData.business_type.id : "",
        })
    }, [clientsEditData])
    return (
        <>
            {Error && (
                <div className="alert alert-danger" role="alert">
                    {Error}
                </div>
            )}
            <FormParser
                modelObject={MastersModal}
                formSubmit={formSubmit}
                formData={formData}
                buttonTitle='Save'
                readOnly={readOnly}
                setReadOnly={setReadOnly}
            />
            {readOnly &&
                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                    <div>Edit</div>
                    <MdOutlineEdit className="ms-5" />
                </button>
            }
            {popupType !== "" &&
                <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} />
            }
        </>
    )
}

export { BusinessInformation }