import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";

const FinanceList = () => {
    
    return (
        <>
            
            <div className="mt-5">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page is work in progress</p>
                </div>
                
            
        </>
    )
}
export { FinanceList }