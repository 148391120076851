import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal, DeletePopupModal } from "../Components";
import Masters from "../Components/masters.json"
import axiosConfig from "../Service/axiosConfig"
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import "./Pages.css"
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
const ReferenceDomainValue = ({ Code }) => {
    console.log(Code)
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("");
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    let navigate = useNavigate();
    const [RowId, SetRowId] = useState()
    const [DomainId, SetdomainId] = useState(0)
    let domainId = DomainId
    const [search, SetSearch] = useState({
        search: ""
    })
    const [Datafilter, SetDatafilter] = useState([])
    const [Data, SetData] = useState([])
    const [DomainName, SetDomainName] = useState("")
    const [domainData, SetDomainData] = useState([])
    const [actionPermisson, SetActionPermission] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [check, setCheck] = useState(false)
    const [currentDirection, setCurrentDirection] = useState(null);
    const [tableId,setTableId]=useState()
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data.modulescreen)
    }

    console.log(actionPermisson)
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/referencedomainvalue/?domain=${domainId}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            SetData(responce.data.results);
            setLoading(false);
            setTotalRows(responce.data.count);
        }
        catch (error) {
            console.log(error)
        }

    }
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`;
        setDataTableHeight(newHeight);
    };
    useEffect(() => {
        calculateDataTableHeight()
        GetData();
        PermitData();
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);
    const HandleSearchValue = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/masters/referencedomainvalue/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })

    }

    const HandleMouse = (row) => {
        setTableId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Code",
            selector: "code",
            cell: (row) => `${row.code}`,
            sortable: true
        },
        {
            name: "Description",
            selector: "description",
            cell: (row) => `${row.description}`,
            sortable: true
        },
        {
            name: "Display Order",
            selector: "display_order",
            cell: (row) => `${row.display_order}`,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/domains/value/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/referencedomainvalue/${id}/`).then(() => {
                        GetData()
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                const shouldShowActions = tableId === row.id
                return (
                    <>
                    {
                        shouldShowActions &&(
                            <div className="row text-center" >
                            {actionPermisson&&actionPermisson.can_read&&<div className="col-4 text-center">
                             <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                             </div>}
                             {actionPermisson&&actionPermisson.can_edit&&<div className="col-4">
                                 <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                             </div>}
                            {actionPermisson&&actionPermisson.can_delete&&<div className="col-4">
                                 <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                             </div>}
                         </div>
                        )
                    }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    const MastersModal = Masters.ReferenceDomainValue
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("masters/referencedomainvalue/", Data).then(res => {
            console.log(res.data)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const HandleSearch = (e) => {
        e.preventDefault();
        const name = e.target.name
        const value = e.target.value
        SetDomainName(value)
        axiosConfig.get(`/masters/referencedomain/?search=${value}`).then(response => {
            console.log(value, response)
            SetDomainData(response.data.results)
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleDomain = (e) => {
        e.preventDefault();
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        domainId = e.currentTarget.dataset.domainid
        SetdomainId(domainId)
        GetData()
        SetDomainName(e.currentTarget.dataset.domainname)
        SetDomainData([])
    }
    const NavLinkDomain = () => {
        navigate(`/dashboard/masters/domains/${DomainId}/value/add`)
    }
    return (
        <> 
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">ReferenceDomainValue's</h5>
                    {DomainId !== 0 &&actionPermisson&&actionPermisson.can_add&&
                    <button onClick={NavLinkDomain} className="btn btn-primary" >Add New</button>
                }
                </div>
                <div className="search-form">
                    <div className="row">
                        <div className="col-3">
                            <label>Domain Code:</label>
                        </div>
                        <div className="col-9">
                            <div className="searchinput-holder">
                                <input type="text" className="form-control" placeholder="Domain Code" name="domain" value={DomainName} onChange={HandleSearch} />
                                <div className="search-dropdowns">
                                    {domainData.map((domainItem, idx) => {
                                        return (
                                            <p onClick={HandleDomain} key={domainItem.id} data-domainid={domainItem.id} data-domainname={domainItem.code}>{domainItem.code}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tablescroll-clientbill-parent">
                    <div className="tablescroll-clientbill">
                        <div className="table-header-menu">
                            <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                <Dropdown.Toggle variant="">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onClick={handleCheckboxClick}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item>Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else here</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <MdOutlineRefresh className="sidebar-icon-table" />
                            <CiMenuKebab className="sidebar-icon-table" />
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page, totalRows) => {
                                    setCurrentPage(page);
                                    GetData(currentColumn, currentDirection);
                                }}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer
                                fixedHeader
                                customStyles={tableCustomStyles}
                                fixedHeaderScrollHeight={dataTableHeight}
                                selectableRows
                                onSelectedRowsChange={({ selectedRows }) =>
                                    console.log(selectedRows)
                                }
                                selectableRowsHighlight
                                highlightOnHover
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                subHeader
                                progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                subHeaderComponent={
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearchValue} />}>
                            </DataTable>
                        </div>
                    </div>
                </div>
                {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>}
                {deletePopupType != "" &&
                    <DeletePopupModal deletePopupType={deletePopupType} setDeletepopupType={setDeletepopupType} />
                }
                {/* <FormParser modelObject={MastersModal} formSubmit={formSubmit}/> */}
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
        </>
    )

}
export { ReferenceDomainValue }