import React, { useState } from "react"
import "./StaffLogin.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate } from 'react-router-dom';

const StaffLogin = () => {
    let navigate = useNavigate();
    const [visible, SetVisable] = useState(false)
    const inputType = visible ? "text" : "password"
    const [Data, SetData] = useState({
        email: "",
        password: ""
    })
    const [error, SetError] = useState("")
    const InputHandleer = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value)

        SetData({ ...Data, [name]: value })
    }

    const SubmitHandller = (e) => {
        e.preventDefault()

        const { email, password } = Data
        if (email === "") {
            SetError(
                <>
                    <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                    email is Required
                </>

            )
        }
        else if (password === "") {
            SetError(
                <>
                    <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                    password is Required
                </>
            )
        }
        else {
            const { email, password } = Data
            const formData = new FormData();
            formData.append("mobileno", email)
            formData.append("password", password)
            axiosConfig.post("/accounts/api/login/", formData).then((res) => {
                localStorage.setItem("roles", JSON.stringify(res.data.roles))
                localStorage.setItem("first_name", res.data?.user.first_name)
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('userId', res.data.user?.id);
                localStorage.setItem('permission', JSON.stringify(res.data.permission));
                localStorage.setItem('sidePanel', false)
                localStorage.setItem('activeSideData', null)
                localStorage.setItem('mobileno', email)
                return navigate("/dashboard");
            }).catch(e => {
                console.log(e)
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        {e.response.data.error || e.response.data.detail}
                    </>
                )
            })
        }
    }
    return (
        <>
            <div className="login-main">
                <div className="container">
                    <div className="login-container">
                        <div className="login-c-left">
                            <div className="login-image text-center">
                                <img className="vector1" src={require('./images/Logo.png')} alt="Logo" />
                                {/* <img className="vector2" src={require('./images/banner.gif')} alt="banner" /> */}
                            </div>
                        </div>
                        <div className="login-c-right">
                            <div className="login-form">
                                <div className="form-container">
                                    <h2>Login your Account</h2>
                                    <div className="Loginerror">
                                        <p>{error}</p>
                                    </div>
                                    <form>
                                        <div className="form-group">
                                            <label>Email or Phone:</label>
                                            <input type="text" className="form-control" placeholder="Email Address or Phone Number" name="email" value={Data.email} onChange={InputHandleer} />
                                        </div>
                                        <div className="form-group has-icon">
                                            <label>Password:</label>
                                            <input type={inputType} className="form-control" placeholder="Enter password" name="password" value={Data.password} onChange={InputHandleer} />
                                            {visible ?
                                                <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => SetVisable(false)} /> :
                                                <img className="inputicon" src={require('./images/eye.jpg')} alt="password" onClick={() => SetVisable(true)} />
                                            }
                                        </div>
                                        <div className="d-grid">
                                            <button type="submit" className="btn btn-primary btn-block" onKeyPress={(e) => e.key === "ENTER" ? SubmitHandller : null} onClick={SubmitHandller}>Login</button>
                                        </div>
                                    </form>
                                     <div className="f-copy text-center">
                                        {/* <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023</p> */}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { StaffLogin };