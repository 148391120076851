import React, { useEffect, useState } from "react";
import { DashboardLayout, ServiceTemplateModal, PopupModal } from "../Components";
import { NavLink, useNavigate, useParams, useNavigation,useSearchParams } from "react-router-dom";
import { ImAttachment } from "react-icons/im"
import { BiCommentEdit } from "react-icons/bi";
import { HiOutlineBriefcase } from "react-icons/hi"
import { RiUserFollowLine } from "react-icons/ri"
import { IoImageOutline } from "react-icons/io5";
import { FiArrowLeft } from "react-icons/fi";
import { BsFileEarmarkText, BsChatDots } from "react-icons/bs";
import { TiAttachment } from "react-icons/ti";
import { ReactQuillDynamicComponent } from '../Components/WorklistReports/WorklistQuillComponent';
import { MdOutlineRateReview, MdOutlineAddTask } from "react-icons/md";
import { FiUpload } from 'react-icons/fi'
import Modal from 'react-bootstrap/Modal';
import { RadiologistsDetails } from "../Components/SidePanel/RadiologistsDetails";
import Select from 'react-select';
import {
    AiOutlineArrowUp, AiOutlineMessage, AiOutlineHourglass, AiOutlineFileText, AiOutlineFile,
    AiOutlineCheck,
} from "react-icons/ai"
import { MdOutlineEdit, MdArrowDropDown, MdAttachment, MdOutlineSupervisedUserCircle, MdOutlineMessage } from "react-icons/md"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axiosConfig from "../Service/axiosConfig"
import { components } from "react-select";
import { RiSettingsFill } from "react-icons/ri";
import { CaseReports } from "./CaseReports";
import { CaseTrack } from "./CaseTrack";
import Masters from "../Components/masters.json"
import { PatientMLCInfo } from "./PatientMLCInfo";
import { CaseMessage } from "./CaseMessage";
const WorklistExpandComponent = () => {
    const [searchParams] = useSearchParams();
    const messagesParam = searchParams.get("messages");
    let user_roles = JSON.parse(localStorage.getItem("roles"))
    console.log(user_roles[0].role_name, "local")
    let { id } = useParams()
    let navigate = useNavigate();
    const [Data, setData] = useState({})
    const [assignedData, setAssignedData] = useState([])
    const [message, setMessage] = useState('')
    const [activities, setActivities] = useState([])
    const [EditQuillData, setEditQuillData] = useState([])
    const [readOnly, setreadOnly] = useState(false)
    const [showQuill, setShowQuill] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editors, setEditors] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [popupType, setpopupType] = useState("");
    const [key, setKey] = useState("")
    const [sideOpen, setSideOpen] = useState(false)
    const [step, setStep] = useState(1);
    const [assignData, setAssignData] = useState({})
    const [selectedRadiologist, setSelectedRadiologist] = useState(null);
    const [assignedRadiologist, setAssignedRadiologist] = useState(null);
    const [radiologists, setRadiologists] = useState([])
    const [count, setCount] = useState(parseInt(id))
    const [Totalcount, setTotalCount] = useState(0)
    const [smShow, setSmShow] = useState(false);
    const [reAssign, setReAssign] = useState(false)
    const [Error, setError] = useState('')
    const [preference, setPreference] = useState([])
    const [nopreference, setNopreference] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/cases/cases/${count}/`)
            console.log(responce)
            if (responce.data.case_status === 'Assigned') {
                setReAssign(true)
            }
            setFormDataTemplate({
                ...formDataTemplate, case: id,
                service_template: null,
                preview: "",
                radiologist: responce.data.radiologist,
                report_status: "Draft",
            })
            setData(responce.data)
        }
        catch (error) {
            console.log(error)

        }

    }

    const GetAssignedData = async (activities) => {
        try {
            const response = await axiosConfig.get(`/cases/assigned_cases_to_radiologist/`);
            const case_data = await axiosConfig.get(`/cases/cases/${count}/`)
            let options = [];
            let updatedAssignedData = [];
            response.data.forEach(item => {
                for (const [key, value] of Object.entries(item)) {
                    const filtered_activity = activities.filter((activity) => JSON.stringify(activity.user.id) === key);
                    if (filtered_activity.length > 0 && filtered_activity[0].is_online) {
                        value.is_online = filtered_activity[0].is_online;
                    } else {
                        value.is_online = false;
                    }

                    value.id = key;
                    options.push({
                        label: value.name,
                        value: key,
                        is_online: value.is_online
                    });

                    updatedAssignedData.push(value);
                }
            });
            setAssignedData(updatedAssignedData);
            setRadiologists(options)
            let assigned_radiologist = updatedAssignedData.filter(item => (item.id === JSON.stringify(case_data.data.radiologist)));
            {
                assigned_radiologist.length > 0 &&
                setAssignedRadiologist({
                    label: assigned_radiologist[0].name,
                    value: assigned_radiologist[0].id,
                    is_online: assigned_radiologist[0].is_online
                })
                setPreference(assigned_radiologist.preference_modalities)
                setNopreference(assigned_radiologist.nopreferences_modalities)
            }
        } catch (error) {
            console.log("Error getting radiologists: ", error);
        }
    };

    const getUserActivities = async () => {
        try {
            const response = await axiosConfig.get(`/account/user-activities/?page=1&page_size=250`)
            setActivities(response.data.results)
            GetAssignedData(response.data.results)
        } catch (error) {
            console.log("Error getting user activities : ", error)
        }
    }

    const [formDataTemplate, setFormDataTemplate] = useState({
        case: id,
        service_template: null,
        preview: "",
        radiologist: "",
        report_status: "Draft",
    })
    console.log(formDataTemplate)
    const Gettotalcount = async () => {
        try {
            const responce = await axiosConfig.get(`/cases/cases/`)
            console.log(responce)
            setTotalCount(responce.data.count)
        }
        catch (error) {
            console.log(error)

        }

    }
    function formatTimestamp(initialTimestamp) {
        if (!initialTimestamp) {
            return null;
        }

        const timestampDate = new Date(initialTimestamp);

        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const dayOfWeek = daysOfWeek[timestampDate.getDay()];
        const month = months[timestampDate.getMonth()];
        const day = timestampDate.getDate();
        const time = timestampDate.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });

        const currentDate = new Date();
        const minutesDifference = Math.floor((currentDate - timestampDate) / 60000); // Difference in minutes

        const formattedMinutes = String(minutesDifference % 60).padStart(2, '0');
        const formattedHours = String(Math.floor(minutesDifference / 60)).padStart(2, '0');

        const formattedTime = `${dayOfWeek}, ${day} ${month}, ${time} (${formattedHours}:${formattedMinutes} mins)`;

        return formattedTime;
    }
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        };

        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    };
    const GetPreviewData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/cases/case-reports/?case=${id}`);
            console.log(response.data.results);

            const filteredData = response.data.results.filter(result => !result.is_deleted);

            const modifiedData = filteredData.map((result, index) => ({
                id: result.id,
                content: result.report_content,
                isEditing: true,
                created_at: result.created_at,
                modified_at: result.modified_at,
                last_name: result.radiologist.last_name,
                first_name: result.radiologist.first_name,
                report_status: result.report_status,
                service_name: result.service_template.service_id.service_name,
                service_template_id: result.service_template.id
            }));

            setEditors(modifiedData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    useEffect(() => {
        getUserActivities();
        if (count !== Totalcount) {
            GetData();
        }
        if (!Totalcount) {
            Gettotalcount()
            console.log(count, Totalcount)
        }
        navigate(`/dashboard/worklist/cases/${count}`)
        GetPreviewData();
    }, [count, popupType])
    const HandleCount = () => {
        if (count !== Totalcount) {
            setCount(count + 1)
        }
    }
    const HandleDecrementCount = () => {
        if (count !== 0) {
            setCount(count - 1)
        }
    }
    const handleEditorChange = (index, content) => {
        setEditors((prevEditors) =>
            prevEditors.map((editor, i) => (i === index ? { ...editor, content } : editor))
        );
        setFormDataTemplate({
            ...formDataTemplate,
            report_content: content
        });
    };
    const handleEditClick = (index) => {
        const updatedEditors = [...editors];
        updatedEditors[index].isEditing = false;
        console.log(updatedEditors[index])
        setFormDataTemplate({
            case: id,
            radiologist: Data.radiologist,
            id: updatedEditors[index].id,
            report_content: updatedEditors[index].content,
            service_template: updatedEditors[index].service_template_id,
            report_status: "Draft"

        });
        setEditors(updatedEditors);
    };
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const handleEditorDelete = async (id) => {
        try {
            const response = await axiosConfig.delete(`/cases/case-reports/${id}/`);
            GetPreviewData();
            SetAlertDeletePopupType("error")
        } catch (error) {
            console.error('Error deleteing data:', error);
            SetAlertDeletePopupType("error")
        }
    };
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false);
        setValidateError('')
        setpopupType("")
    }
    const handleShow = () => {
        setShow(true);
        setFormData({
            case: id,
        });
        setSelectedFileName('')
    }
    const [selectedFileName, setSelectedFileName] = useState("");
    const [formData, setFormData] = useState({
        case: id,
        comments: "",
        attachment: "",
    });
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        setSelectedFileName(file.name);
        setFormData({ ...formData, [name]: value })
        console.log({ ...formData })
    }
    const [validateError, setValidateError] = useState("");
    const formSubmit = (e) => {
        e.preventDefault()
        if (!formData.attachment) {
            setValidateError("Attachment is required");
            return;
        }
        console.log(formData.case)
        console.log(formData)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.post(`/cases/case-attachments/`, formData).then(res => {
            console.log(res)
            setShow(false)
            setValidateError("")
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            console.log(error.response.data)
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.attachment;
                setValidateError(errorMessage);
            }
            setShow(true)
            setpopupType("error")
        })
    }
    const updateCaseStatus = async (caseId) => {
        try {
            const response = await axiosConfig.patch(`/cases/cases/${caseId}/`, {
                is_set_as_completed: true,
            });
            console.log(response);
            setData(response.data)
        } catch (error) {
            console.error(error);
        }
    };
    const handleSmClose = () => {
        setStep(1)
        setSmShow(false)
    }
    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps} className="d-flex align-items-center m-2">
            <div className={`online-icon ${data.is_online ? 'online' : 'offline'}`}></div>
            <span className="ms-2">{label}</span>
        </div>
    );
    const CustomSingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <div className="d-flex align-items-center">
                <div className={`online-icon ${props.data.is_online ? 'online' : 'offline'}`}></div>
                <span className="ms-2">{children}</span>
            </div>
        </components.SingleValue>
    );
    const handleAssign = async () => {
        console.log(selectedRadiologist, assignData, "selected...")
        if (!reAssign) {
            let selected = { label: assignData.name, value: assignData.id, is_online: assignData.is_online }
            setSelectedRadiologist(selected)
        }
        setStep(2)
        if ((step === 2 || reAssign)) {
            try {
                const response = await axiosConfig.post(`/cases/cases/${id}/assign_radiologist/`, {
                    user_id: selectedRadiologist.value
                })
                setSmShow(false)
                reAssign ? setMessage("Re-assigned successfully") : setMessage("Assigned successfully")
                setpopupType("success")
                setStep(1)
            } catch (error) {
                console.log("error assigning case : ", error)
                setpopupType("error")
            }
        }
    }
    const HandleReport = (e) => {
        FomrSubmit(e, formDataTemplate.id)
    }
    console.log(formDataTemplate)
    const FomrSubmit = async (e, report_id) => {
        e.preventDefault();
        try {
            let response;

            if (report_id) {
                response = await axiosConfig.put(`/cases/case-reports/${report_id}/`, formDataTemplate);
            } else {
                response = await axiosConfig.post(`/cases/case-reports/`, formDataTemplate);
            }

            GetPreviewData();
            setError("")
            setFormDataTemplate({
                case: id,
                service_template: null,
                preview: "",
                radiologist: Data.radiologist,
                report_status: "Draft",
            })
            setModalShow(false);
        } catch (error) {
            console.error('Error fetching data:', error);

            if (error.response && error.response.data) {
                const errors = [];
                Object.keys(error.response.data).forEach((fieldName) => {
                    const fieldErrors = error.response.data[fieldName];
                    errors.push(`${fieldName}: ${fieldErrors.join(', ')}`);
                });

                setError(errors.join('\n'));
            } else {
                // Handle other types of errors if needed
                setError('An error occurred while submitting the form.');
            }
        }
    };
    const [data, setDServicetmeplate] = useState([]);
    const GetService = async (id) => {
        try {
            if (id) {
                const response = await axiosConfig.get(`/masters/servicetemplates/${id}/`);
                return response.data;
            } else {
                const response = await axiosConfig.get('/masters/servicetemplates/');
                setDServicetmeplate(response.data.results);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <div className="tablescroll-clientbill-parent">
                <div className="tablescroll-clientbill table-main-worklist">
                    <div className="table-responsive">
                        <div className="">
                            <div className="row">
                                <div className="col-6">
                                    <div className="dashboard-worklist-icon">
                                        <div className="gap-icon-worklist">
                                            <span className="cursor-pointer"><FiArrowLeft onClick={() => navigate("/dashboard/Worklist/cases")} className="cursor-pointer" /></span>
                                            {/* <span className="">
                <div className="tablescroll-clientbill-parent">
                    <div className="tablescroll-clientbill table-main-worklist">
                        <div className="table-responsive">
                            <div className="">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="dashboard-worklist-icon">
                                            <div className="gap-icon-worklist">
                                                <span className="cursor-pointer"><FiArrowLeft onClick={() => navigate("/dashboard/Worklist/cases")} className="cursor-pointer" /></span>
                                                {/* <span className="">
                                    <MdOutlineEdit />
                                    </span>
                                    <span className="">
                                        <ImAttachment />
                                    </span>
                                    <span className="">   
                                        <AiOutlineFile />
                                    </span>
                                    <span className="">
                                    <AiOutlineMessage />
                                    </span>
                                    <span className="">
                                    <AiOutlineCheck />
                                    </span>
                                    <span className="">
                                    <BiCommentEdit />
                                    </span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 next-pagination">
                                    <span>{count} of {Totalcount}</span>
                                    <svg
                                        className="cursor-pointer"
                                        onClick={HandleDecrementCount}
                                        aria-label="Previous Page"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        role="presentation"
                                    >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
                                    </svg>
                                    <svg
                                        className="cursor-pointer"
                                        onClick={HandleCount}
                                        aria-label="Next Page"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        role="presentation"
                                    >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12l-4.58 4.59z" />
                                    </svg>
                                </div>
                            </div>
                            <div className={`${sideOpen ? 'client-worklist-main sidepanel' : 'row client-worklist-main'}`}>
                                <div className={`${sideOpen ? 'client-main-container sidepanel' : 'col-6 client-main-container'}`}>
                                    <div className="new-worklist-header">
                                        <div className="new-worklist-data">
                                            {Data.case_status}
                                        </div>
                                        <div className="new-worklist-stat">
                                            {Data.priority_name}
                                            <AiOutlineArrowUp />
                                        </div>
                                        <div className="new-worklist-under">
                                            {Data.case_review}
                                        </div>
                                        <div>
                                            {Data.case_review !== "completed" && <AiOutlineHourglass className="new-worklist-icon" />}
                                            {Data.case_review === "completed" && <MdOutlineAddTask className="new-worklist-icon" />}
                                        </div>
                                    </div>

                                    <div className="worklist-container">
                                        <div className="patientdata-container">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Case Upload Date / Time
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {formatTimestamp(Data.receiving_date)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Institution Group
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.instutution}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Case ID
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                ARI/2023
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Accession Number
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.accession_number}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    patient Name
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.patient_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`${sideOpen ? 'second-clientData sidepanel' : 'col-5 second-clientData'}`}>
                                    <div className="worklist-container">
                                        <div className="patientdata-container-a">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Age
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.age}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container-a">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Sex
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.sex}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container-a">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Modality
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.modality}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="worklist-container">
                                        <div className="patientdata-container-a">
                                            <div className="patientData-subcontainer">
                                                <div className="text-style-head">
                                                    Study Description
                                                </div>
                                                <div>
                                                    :
                                                </div>
                                            </div>
                                            <div className="text-style-head-list">
                                                {Data.study}
                                            </div>
                                        </div>
                                    </div>
                                    {assignedRadiologist &&
                                        <div className="worklist-container">
                                            <div className="patientdata-container">
                                                <div className="patientData-subcontainer">
                                                    <div className="text-style-head">
                                                        Assigned Doctor
                                                    </div>
                                                    <div>
                                                        :
                                                    </div>
                                                </div>
                                                <div className="text-style-head-list">
                                                    {assignedRadiologist.label}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="client-form"><Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="" title={
                                        <div className="tab-title">
                                            <div><IoImageOutline className="worklist-icons" /></div>
                                            <div>Images <span className="worklist-span">9 New</span></div>
                                        </div>}>
                                        {
                                            Array.isArray(Data.images) && Data.images.length > 0 ? <>
                                                <div className="d-flex justify-space-between flex-wrap col-8">
                                                    {Data.images.map((images, ind) => {
                                                        return (
                                                            <div>
                                                                <img className="image-files" src={require('../Components/StaffLogin/images/ph_image.png')} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </> : <>
                                                <div>
                                                    <img className="image-files" src={Data.images} />
                                                </div>
                                            </>
                                        }
                                    </Tab>
                                    <Tab eventKey="Business Information" title={
                                        <div className="tab-title">
                                            <div><MdAttachment className="worklist-icons" /></div>
                                            <div>Attachments</div>
                                        </div>}>
                                        Tab content for Attachments
                                        Tab content for business information
                                    </Tab>
                                    <Tab eventKey="Tariff" title={
                                        <div className="tab-title">
                                            <div>< BsFileEarmarkText className="worklist-icons" /></div>
                                            <div>Reports</div>
                                        </div>}>
                                        {modalShow && <ServiceTemplateModal modalShow={modalShow} setModalShow={setModalShow} FormSubmit={FomrSubmit} Error={Error} setError={setError} setEditors={setEditors} editors={editors} params={id} cases={Data} setreadOnly={setreadOnly} readOnly={readOnly} GetData={GetService} formDataTemplate={formDataTemplate} setFormDataTemplate={setFormDataTemplate} data={data} GetPreviewData={GetPreviewData} />}
                                        {editors.length > 0 ? null : (
                                            (Data.case_status === "Assigned" || Data.case_status === "Read Draft") && (
                                                <button type="submit" className="btn btn-primary" onClick={() => setModalShow(true)}>
                                                    Add New Report
                                                </button>
                                            )
                                        )}
                                        {
                                            loading ? (
                                                <div className="loader-container">
                                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                                </div>
                                            ) : (
                                                editors.length > 0 && (
                                                    editors.map((res, ind) => (
                                                        <div key={res.id}>
                                                            {ind === 0 && Error && (  // Display error only for the first item
                                                                <div className="Loginerror">
                                                                    <p>{Error}</p>
                                                                </div>
                                                            )}
                                                            <ReactQuillDynamicComponent
                                                                index={ind}
                                                                key={res.id}
                                                                value={res.content}
                                                                SetAlertDeletePopupType={SetAlertDeletePopupType}
                                                                AlertDeletePopupType={AlertDeletePopupType}
                                                                modalShow={modalShow}
                                                                setreadOnly={setreadOnly}
                                                                readOnly={readOnly}
                                                                setModalShow={setModalShow}
                                                                responce={res}
                                                                HandleReport={HandleReport}
                                                                isEditing={res.isEditing}
                                                                formDataTemplate={formDataTemplate}
                                                                setFormDataTemplate={setFormDataTemplate}
                                                                handleEditorChange={handleEditorChange}
                                                                GetData={GetService}
                                                                handleEditorDelete={handleEditorDelete}
                                                                handleEditClick={handleEditClick}
                                                                formatDate={formatDate}
                                                            />
                                                        </div>
                                                    ))
                                                )
                                            )
                                        }

                                        {
                                            editors.length > 0 &&
                                            <div className='d-flex justify-content-end my-4' onClick={() => setModalShow(true)}>
                                                <button type="submit" className="btn btn-primary" >Add New Report</button>
                                            </div>
                                        }
                                        <CaseReports />
                                    </Tab>
                                    <Tab eventKey="Institutions" title={
                                        <div className="tab-title">
                                            <div>< HiOutlineBriefcase className="worklist-icons" /></div>
                                            <div>Case Track</div>
                                        </div>}>
                                        <CaseTrack />
                                    </Tab>
                                    <Tab eventKey="Printing Preferences" title={
                                        <div className="tab-title">
                                            <div><MdOutlineSupervisedUserCircle className="worklist-icons" /></div>
                                            <div>Patient & MLC Info</div>
                                        </div>}>
                                        <PatientMLCInfo />
                                    </Tab>
                                    <Tab eventKey="Billing Information" title={
                                        <div className="tab-title">
                                            <div><MdOutlineMessage className="worklist-icons" /></div>{console.log(messagesParam)}
                                            <div>Messages <span className="worklist-span">{messagesParam}</span></div>
                                        </div>}>
                                        <CaseMessage/>
                                    </Tab>
                                </Tabs>

                                </div>
                                <div className="row w-100">
                                    <hr className="horizontalruler" />
                                    <div className="images-icon-attch">
                                        <div className="images-table-attach" onClick={() => navigate("/dashboard/Work-list/my-work-list")}><span className="icon-image"><FiArrowLeft /></span>Back</div>
                                        <div className="images-table-attach" onClick={() => setSmShow(true)}><span className="icon-image"><RiUserFollowLine /></span>{reAssign ? 'Re-assign' : 'Assign'}</div>
                                        {Data.is_set_as_completed === false && (
                                            <div className="images-table-attach" onClick={() => updateCaseStatus(id)} >
                                                <span className="icon-image"><AiOutlineCheck /></span>Set as complete
                                            </div>
                                        )}
                                        {Data.case_status === "Report Final" && (
                                            <div className="images-table-attach" onClick={handleShow}>
                                                <span className="icon-image"><MdOutlineRateReview /></span>Review Requests
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Review Requests
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => formSubmit(e)}>
                        {validateError && (
                            <div className="alert alert-danger" role="alert">
                                {validateError}
                            </div>
                        )}
                        <div className="form-group">
                            <label>Query for Review Requests:</label>
                            <textarea className="form-control" rows="2"
                                placeholder="Query for Review Requests"
                                value={formData.comments}
                                onChange={(e) => setFormData({ ...formData, comments: e.target.value })} required ></textarea>
                        </div>
                        <div className="form-group attachment-layout">
                            <label>Add Attachments:</label>
                            <label className="form-control" htmlFor="fileInput">
                                <div className="upload-file">
                                    <input id="fileInput"
                                        className="image-upload"
                                        type="file"
                                        name="attachment"
                                        accept=".jpg, .jpeg, .png, .dicom"
                                        multiple
                                        onChange={HandleFiles}
                                    />
                                    <FiUpload className="drag-drop" size={40} />
                                    <span className="file-text">
                                        {selectedFileName ? selectedFileName : "Drag & Drop files here / Select from Computer"}
                                    </span>
                                </div>
                            </label>
                        </div>
                        <button className="btn btn-primary btn-block"  >Confirm Review Requests</button>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                show={smShow}
                onHide={() => handleSmClose()}
                centered
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Header className="radiologist-header" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {reAssign ? 'Re-Assign' : 'Assign'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="radiologist-modal">
                    {step === 1 && !reAssign && <RadiologistsDetails smShow={smShow} setAssignData={setAssignData} setRadiologists={setRadiologists} />}
                    {step === 2 && !reAssign && <>
                        <div className="form-group w-100">
                            <label>Select Radiologists:</label>
                            <Select
                                options={radiologists}
                                onChange={(selectedOption) => setSelectedRadiologist(selectedOption)}
                                value={selectedRadiologist}
                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                            />
                            {selectedRadiologist && !selectedRadiologist.is_online &&
                                <div className="LoginError mt-2">Selected Doctor is Offline</div>
                            }
                        </div>
                    </>}
                    {reAssign &&
                        <div className="form-group w-100">
                            {assignedRadiologist && <div className="radiologist-name">Assigned Radiologist : {assignedRadiologist.label}</div>}
                            <div className="radiologist-pref">
                                {preference && <p className="pref-modality">{preference.join(',')}</p>}
                                {nopreference && <p className="pref-modality">({nopreference.join(',')})</p>}
                            </div>
                            <label>Select Radiologists:</label>
                            <Select
                                options={radiologists}
                                onChange={(selectedOption) => setSelectedRadiologist(selectedOption)}
                                value={selectedRadiologist ? selectedRadiologist : null}
                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                            />
                            {selectedRadiologist && !selectedRadiologist.is_online &&
                                <div className="LoginError mt-2">Selected Doctor is Offline</div>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className="radiologist-footer">
                    <button variant="primary" className="btn btn-primary btn-block" onClick={() => handleAssign()}>
                        {reAssign ? 'Confirm Re-Assign' : 'Confirm Assign'}
                    </button>
                </Modal.Footer>
            </Modal>
            {popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} />
            }
        </>
    )
}
export { WorklistExpandComponent }