import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import "./Pages.css";
import axiosConfig from "../Service/axiosConfig"
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';

const PrintingPreference = ({ key }) => {
    let { id } = useParams();
    const [popupType, setpopupType] = useState("");
    const [readOnly, setReadOnly] = useState(true);
    const [formData, setFormData] = useState({
        default_preference: 'without header and footer',
        margin_top: '',
        margin_bottom: '',
        margin_left: '',
        margin_right: '',
        header: `<div><img src='https://apolloradiologyintl.com/wp-content/uploads/2019/07/cropped-logo.png' style="width: 200px; height: 100px;" /><br/><br/><h1 style="text-align: center;"><strong>OM DIGITAL X-RAY</strong></h1><br/><h1 style="text-align: center;"><strong>KARGIL CHOWK BY PASS ROAD SITAMARHI-843302</strong></h1><div>`,
        footer: `<img src='https://apolloradiologyintl.com/wp-content/uploads/2019/07/cropped-logo.png' style="width: 200px; height: 100px;" />`,
        client: id
    });
    const [height, setHeight] = useState(0)
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
        [{ 'direction': 'rtl' }], // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean'] // remove formatting button
    ];
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        console.log({ formData })
        setFormData({ ...formData, [name]: value })
    };
    const handleFormRadio = (name, value) => {
        setFormData({ ...formData, [name]: value })
    };
    const handleheaderChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["header"]: value,
        }));
    };
    const handleFooterChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["footer"]: value,
        }));
    };
    const handleEdit = (e) => {
        e.preventDefault()
        setReadOnly(false)
    }
    const [passwordError, setPasswordError] = useState("");
    const formSubmit = (e) => {
        e.preventDefault();
        console.log(formData.id)
        if (formData.id) {
            axiosConfig.put(`/cases/printingpreferences/${formData.id}/`, formData).then(res => {
                console.log(res.data)
                setFormData(res.data)
                setReadOnly(true)
                setPasswordError("");
                setpopupType("success")
            })
                .catch(error => {
                    console.log(error)
                    if (error.response && error.response.status === 400) {
                        if (error.response.data.margin_right) {
                            setPasswordError('margin right is required.');
                        }
                        if (error.response.data.margin_bottom) {
                            setPasswordError('margin bottom is required.');
                        }
                        if (error.response.data.margin_left) {
                            setPasswordError('margin left is required.');
                        }
                        if (error.response.data.margin_top) {
                            setPasswordError('margin top is required.');
                        }
                    }
                    setpopupType("error")
                })
            setReadOnly(false)
        }
        else {
            axiosConfig.post(`/cases/printingpreferences/`, formData).then(res => {
                console.log(res.data)
                setFormData(res.data)
                setPasswordError("");
                setpopupType("success")
                setReadOnly(true)
            })
                .catch(error => {
                    console.log(error)
                    if (error.response && error.response.status === 400) {
                        if (error.response.data.margin_right) {

                            setPasswordError('margin right is required.');
                        }
                        if (error.response.data.margin_bottom) {

                            setPasswordError('margin bottom is required.');
                        }
                        if (error.response.data.margin_left) {
                            setPasswordError('margin left is required.');
                        }
                        if (error.response.data.margin_top) {
                            setPasswordError('margin top is required.');
                        }
                    }
                    setpopupType("error")
                })
            setReadOnly(false)
        }
    }
    const GetData = () => {
        axiosConfig.get(`/cases/printingpreferences/?client=${id}`).then(res => {
            console.log(res.data.results[0])
            const client_id = res.data.results[0].client.id
            setFormData({ ...res.data.results[0], "client": client_id })
        }).catch(error => {
            console.log(error)
        })

    }
    const quillBoxRef = useRef(null);
    const verticalLineRef = useRef(null);
    const updateVerticalLineWidth = () => {
        let quill = document.getElementsByClassName('quill-box')
        console.log('Element height:', quill[0].clientHeight);
        setHeight(quill[0].clientHeight);
    };
    useEffect(() => {
        updateVerticalLineWidth();
        verticalLineRef.current.style.width = height + 'px';
        window.addEventListener('resize', updateVerticalLineWidth);
        return () => {
            window.removeEventListener('resize', updateVerticalLineWidth);
        };
    }, [formData.header, formData.footer, height, key]);


    useEffect(() => {
        GetData()
    }, [])
    console.log(formData)
    return (
        <>       <div className="container-form">
            <form>
                <div className="form-group">
                    <label>Without Header & Footer:</label>
                </div>
                <div className="form-group">
                    <label>Margins:</label>
                </div>
                {passwordError && (
                    <div className="alert alert-danger" role="alert">
                        {passwordError}
                    </div>
                )}
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="margin_top">Top:</label>
                            <input className="form-control"
                                type="number"
                                placeholder="px"
                                name="margin_top"
                                value={formData.margin_top}
                                onChange={(e) => handleFormChange(e)}
                                required
                                disabled={readOnly} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="margin_left">Left:</label>
                            <input className="form-control"
                                type="number"
                                placeholder="px"
                                name="margin_left"
                                value={formData.margin_left}
                                onChange={(e) => handleFormChange(e)}
                                required
                                disabled={readOnly} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="form-field">
                            <label htmlFor="margin_bottom">Bottom:</label>
                            <input className="form-control"
                                type="number"
                                placeholder="px"
                                name="margin_bottom"
                                value={formData.margin_bottom}
                                onChange={(e) => handleFormChange(e)}
                                required
                                disabled={readOnly} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="margin_right">Right:</label>
                            <input className="form-control" type="number"
                                placeholder="px"
                                name="margin_right"
                                value={formData.margin_right}
                                onChange={(e) => handleFormChange(e)}
                                required
                                disabled={readOnly} />
                        </div>
                    </div>
                </div>
                <div class="arrow-line vertical-line" ref={verticalLineRef}>
                    <p class="line-label">H: 842 px A4 Size</p>
                </div>
                <label>With Header & Footer:</label>
                <div className="quill-box form-group">
                    <ReactQuill
                        modules={{ toolbar: toolbarOptions }}
                        className="header"
                        name="header"
                        value={formData.header}
                        onChange={handleheaderChange}
                        readOnly={readOnly}
                        onLoad={updateVerticalLineWidth}
                        required
                    />
                    <img src={require('./images/printing.png')} className="printing-image" />
                    <ReactQuill
                        modules={{ toolbar: toolbarOptions }}
                        className="footer"
                        name="footer"
                        value={formData.footer}
                        onChange={handleFooterChange}
                        readOnly={readOnly}
                        required
                    />
                </div>

                <div class="arrow-line">
                    <p class="line-label">W: 595 px</p>
                </div>
                <div className="form-group">
                    <label className="download-preference">Default Download Preference:</label>
                </div>
                <div className="form-check-inline">
                    {console.log(formData.default_preference === "without header and footer")}
                    <label className="form-check-label ari-radio-label">
                        <input type="radio" className="ari-radio"
                            name="default_preference"
                            checked={formData.default_preference === "without header and footer"}
                            onChange={(e) => handleFormRadio("default_preference", "without header and footer")}
                            disabled={readOnly} />Without Header & Footer
                    </label>
                </div>
                <div className="form-check-inline">
                    <label className="form-check-label ari-radio-label">
                        <input type="radio" className="ari-radio"
                            name="default_preference"
                            checked={formData.default_preference === "with header and footer"}
                            onChange={(e) => handleFormRadio("default_preference", "with header and footer")}
                            disabled={readOnly} />With Header & Footer
                    </label>
                </div>
                {readOnly ? (
                    <button className={`btn btn-primary d-flex align-items-center justify-content-between`} onClick={(e) => handleEdit(e)}>
                        <div>Edit</div>
                        <MdOutlineEdit className="ms-5" />
                    </button>
                ) : (
                    <button className={`btn btn-primary d-flex align-items-center`} onClick={(e) => formSubmit(e)}>
                        Save
                    </button>
                )}
            </form>
        </div>

            {popupType !== "" &&
                <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} />
            }
        </>
    )

}
export { PrintingPreference }
