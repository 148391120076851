import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const DepartmentAdd = () => {
    const[Error,SetError]=useState("")
    const[CustomError,SetCustomError]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.Department
    const formSubmit=(Data,setFieldErrors)=>{
        console.log(Data)
        axiosConfig.post("/masters/departments/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error.response.data)
            Object.keys(error.response.data).forEach(fieldName => {
                switch (fieldName) {
                    case 'department_code':
                        if (error.response.data[fieldName][0] === "This field must be unique.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                        }
                        case 'department_name':
                        if (error.response.data[fieldName][0] === "This field must be unique.") {
                            setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
                        }
                        break;
                    default:
                        break;
                }
            });
            setpopupType("error")
        })
      }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Department</h5>
                    <NavLink to="/dashboard/masters/departments" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} CustomError={CustomError}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/departments"/>
                }
            
        </>
    )

}
export {DepartmentAdd}