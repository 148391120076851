import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal, EditPopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import RolePermission from "../Components/DashboardLayout/RolePermissionData";
import Select from 'react-select';
import { MdOutlineEdit } from "react-icons/md"
const RoleEdit = () => {
    const MastersModal = Masters.Role
    const [roledata, setRoleData] = useState({ permission: false })
    const [popupType, setpopupType] = useState("")
    const[navid,setId]=useState()
    const [RolePermission, setRolePermission] = useState([]) //post
    const [Error, setError] = useState("") //post
    const [search, SetSearch] = useState({
        search: ""
    })
    const formSubmit = (e) => {
        e.preventDefault();
        if (id) {
            axiosConfig.put(`/account/roleview/${id}/`, {...Data,actions: RolePermission}).then(res => {
                console.log(res)
                setpopupType("success")
                setReadOnly(true)
            }).catch(error => {
                console.log(error)
                if(error.response.data.error){
                    setError(error.response.data.error)
                }
                setpopupType("error")
            })
        }
        else {
            axiosConfig.post(`/account/roleview/`, { ...Data, actions: RolePermission }).then(res => {
                console.log(res)
                setId(res.data.role.id)
                setReadOnly(true)
                setpopupType("success")
            }).catch(error => {
                console.log(error)
                if(error.response.data.error){
                    setError(error.response.data.error)
                }
                setpopupType("error")
            })
        }
    }
    let { id } = useParams();
    const [readOnly, setReadOnly] = useState(id?true:false);
    const [Data, SetData] = useState({
        role_name:"",
        is_client_specific:false
    })
    console.log(Data.role_name)
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/account/roleview/${id}/`)
            console.log(responce.data.module_permissions)
            responce.data&&responce.data.module_permissions.forEach(action => {
                action.actions.forEach(actionname => {
                  console.log(actionname);
                  
                  if (!RolePermission.includes(actionname.id)) {
                    setRolePermission((prevRolePermission) => [...prevRolePermission, actionname.id]);
                  }
                });
              });
            SetData({
                role_name:responce.data.role_name,
                is_client_specific:responce.data.is_client_specific, 
            })
        }
        catch (error) {
            console.log(error)
        }

    }
    const [pmsdata, setpmsdata] = useState([]) //masters
    const [Datafilter, SetDatafilter] = useState([])
    const [ExistingPermissions, SetExistingPermissions] = useState([]) //get existing permissions
    const Rolepermission = async () => {
        try {
            const response = await axiosConfig.get(`/account/module-actions/?page_size=1000`);
            const data = [];
               
            response.data.results.forEach(res => {
                const existingModuleIndex = data.findIndex(item => item.module === res.sub_module.module.id);
                if (existingModuleIndex === -1) {
                    data.push({
                        module: res.sub_module.module.id,
                        module_name:res.sub_module.module.name,
                        subModules: [
                            {
                                id: res.sub_module.id,
                                code: res.sub_module.code,
                                name: res.sub_module.name,
                                description: res.sub_module.description,
                                actions: [
                                    {
                                        action_id: res.id,
                                        action_name: res.action_name,
                                    },
                                ],
                            },
                        ],
                    });
                } else {
                    const existingSubModuleIndex = data[existingModuleIndex].subModules.findIndex(
                        subModule => subModule.id === res.sub_module.id
                    );

                    if (existingSubModuleIndex === -1) {
                        data[existingModuleIndex].subModules.push({
                            id: res.sub_module.id,
                            code: res.sub_module.code,
                            name: res.sub_module.name,
                            description: res.sub_module.description,
                            actions: [
                                {
                                    action_id: res.id,
                                    action_name: res.action_name,
                                },
                            ],
                        });
                    } else {
                        // Check if the action is already present for the sub-module
                        const existingActionIndex = data[existingModuleIndex].subModules[
                            existingSubModuleIndex
                        ].actions.findIndex(action => action.action_id === res.id);

                        if (existingActionIndex === -1) {
                            data[existingModuleIndex].subModules[existingSubModuleIndex].actions.push({
                                action_id: res.id,
                                action_name: res.action_name,
                            });
                        }
                    }
                }
            });
            const uniqueData = data.map(item => ({
                ...item,
                subModules: item.subModules.reduce((acc, current) => {
                    const existingIndex = acc.findIndex(item => item.id === current.id);
                    if (existingIndex === -1) {
                        acc.push(current);
                    } else {
                        acc[existingIndex].actions = [
                            ...acc[existingIndex].actions,
                            ...current.actions,
                        ];
                    }
                    return acc;
                }, []),
            }));
            console.log(uniqueData)
            setpmsdata(uniqueData);
            SetDatafilter(uniqueData)
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (id) {
            GetData();
        }
        Rolepermission();
    }, [])
    const [rolepmtData, setRolePmtdata] = useState([])
    const HandleChange = (e) => {
        const { name, value } = e.target;
      
        if (name === "role_name") {
          SetData({ ...Data, [name]: value });
        }
       else if(name === 'is_client_specific'){
        SetData({...Data,[name]:!Data.is_client_specific})
        } else {
          const floatValue = parseFloat(value);
      
          if (RolePermission.includes(floatValue)) {
            setRolePermission((prevValues) => prevValues.filter(item => item !== floatValue));
          } else {
            setRolePermission((prevValues) => [...prevValues, floatValue]);
          }
        }
      };
    console.log(RolePermission)
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Role {id}</h5>
                    <NavLink to="/dashboard/masters/role" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="tablescroll-clientbill-parent">
                <div className="tablescroll-clientbill">
                    <form onSubmit={formSubmit} className="p-2">
                    <div className="row">
                    <div className="Loginerror">
                            <p>{Error}</p>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="validationCustom01" >Role:</label>
                            <input type="text" className="form-control" id="validationCustom01" placeholder="Role" name="role_name"  onChange={HandleChange} value={Data.role_name}  required disabled={readOnly} />
                        </div>
                        <div className="form-group col-12">
                            <label className="form-check-label ari-radio-label">
                                <input className="ari-radio" type="checkbox" name="is_client_specific" value="True" onChange={HandleChange} checked={Data.is_client_specific}  disabled={readOnly} /> is_client_specific
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end my-4 mx-3">
                        {readOnly &&
                            <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                                <div>{id?"Edit":"Add"}</div>
                                <MdOutlineEdit className="ms-5" />
                            </button>
                        }

                        {!readOnly &&
                            <div>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        }
                    </div>
                    </form>
                    {
                        pmsdata.map((head, id) => (
                            <div key={id}>
                                <div className="role-menu-header">
                                    <h5 className="dashboard-subtitle">{head.module_name}</h5>
                                </div>
                                <div className="role-actions">
                                    {head.subModules.map((subModule, subIndex) => (
                                        <div key={subIndex} className="row my-4">
                                            <div className="col-2">
                                                <h5 className="dashboard-subtitle">{subModule.name}</h5>
                                            </div>
                                            {
                                                subModule.actions.map((actionsName, index) => (
                                                    <div className="col-2">
                                                        <div className="form-check form-check-inline d-flex align-items-center gap-4">
                                                            <input
                                                                className="ari-radio"
                                                                name={actionsName.action_name}
                                                                type="checkbox"
                                                                id={`inlineCheckbox1-${index}`}
                                                                value={actionsName.action_id}
                                                                checked={RolePermission.includes(actionsName.action_id)}
                                                                onChange={HandleChange}
                                                                disabled={readOnly}
                                                            />
                                                            <label>{actionsName.action_name}</label>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} setpopupType={setpopupType}  Navigation={id?"/dashboard/masters/role":`/dashboard/masters/role/${navid}/edit`} Message={id?"Role Permission Edited SuccessFully":"Role Permission Added SuccessFully"} />
                }
            
        </>
    )

}
export { RoleEdit }