import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const ChildNav=({childData, childNavClick, ChildNavs})=>{
    const[subModule,setSubModule]=useState([])
    const[url,setUrl]=useState("")
    function OnChildNavClicked(name){
        childNavClick(name)
        setUrl(name)
    }
    console.log(url)
    const PermitData=()=>{
        const Permmitdata=localStorage.getItem("permission")
        const data=JSON.parse(Permmitdata)
        let moduledata=[]
        data.forEach(pms => {
            if(!moduledata.includes(pms.modulescreen.code)){
                moduledata.push(pms.modulescreen.code)
                
            }
        });
        setSubModule(moduledata)
    }
    useEffect(()=>{
        // PermitData()
    },[])
    return(
        <>
        {
            childData.map((childItem,index)=>{
                return(                                                    
                    <div key={index}>
                        <h5>{childItem.SubTitle}</h5>                        
                            {
                                childItem.NavItems.map((item,idx)=>{
                                    return(
                                        //  subModule.includes(item.submodule_code)&&
                                        <div key={idx} className={ ChildNavs==item.path ? "Configiration-list active" : "Configiration-list"}>
                                         <NavLink to={item.path} className={ChildNavs==item.path?"colorChange":"NoColorChange"}  onClick={() => OnChildNavClicked(item.path)}> 
                                              <span className="configiration-icon">{item.icon}</span>
                                            <span className="list-name">{item.label}</span>
                                        </NavLink>
                                    </div>
                                    )
                                })
                            }
                    </div>
                )
            })
        }
            
        </>
    )
}
export {ChildNav}