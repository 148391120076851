import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineModeEditOutline } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import DataTable from "react-data-table-component";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh, MdOutlineEdit, MdDeleteOutline } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json"
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import { ExpandedComponent } from "./ExpandedComponents";
import { AiOutlineSearch } from 'react-icons/ai';
import { GrView } from 'react-icons/gr';
import { CgAttachment } from 'react-icons/cg';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BiSearch } from "react-icons/bi";
const AddStaff = ({ Code }) => {
    let { id } = useParams();
    const [visible, SetVisable] = useState([])
    const [action, setaction] = useState(0)
    const [action2, setaction2] = useState(0)
    const [action3, setaction3] = useState(0)
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Datafilter, SetDatafilter] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [ErrorValue, setErrorValue] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [tableId, setTableId] = useState([])
    const [check, setCheck] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const [count, setCount] = useState(1)
    const [stateID, setStateId] = useState("")
    const [users, setUsers] = useState([])
    const [Data,SetData]=useState({})
    const [Tilte,Settitle]=useState("Add Staff")
    const masterModal =Tilte==="Add Staff"? Masters.AddNewClient:Masters.EditNewClient;
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();
    const expandedRowClassName = 'expanded-row';
    const collapsedRowClassName = 'collapsed-row';
    const [ClientData, SetClientData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const GetData = async () => {
        setLoading(true);
        await axiosConfig.get(`/client/clients/${id}/`).then((res) => {
            console.log("get data ", res.data.user)
            SetClientData(res.data.user)
            setUsers(res.data.user)
        }).catch((error) => {
            console.log("get data", error)
        })
    };
    const formSubmit = (Data) => {
        console.log(Data)
        SetError([])
            if(Data.user_id){
                axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        
                axiosConfig.put(`/client/clients/${id}/edit_staff/`, Data).then(res => {
                    console.log(res)
                    setpopupType("success")
                    setMessage("Record updated Successfully !")
                    setModalShow(false)
                    GetData()
                }).catch(error => {
                    console.log(error)
                    if (error.response.data.message === "Email or mobile number already exists") {
                        SetError("Email or mobile number already exists")
                    }
        
                    setpopupType("error")
                })
            }
            else{
                if(Data.password !==Data.confirm_password){
                    SetError("Password and Confirm Password do not match'")
                }
                else{
                    axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        
                    axiosConfig.post(`/client/clients/${id}/add_staff/`, Data).then(res => {
                        console.log(res)
                        setpopupType("success")
                        setMessage()
                        setModalShow(false)
                        GetData()
                    }).catch(error => {
                        console.log(error)
                        if (error.response.data.message === "Email or mobile number already exists") {
                            SetError("Email or mobile number already exists")
                        }
            
                        setpopupType("error")
                    })
                }
                }
    
    }
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        const newHeight = `${screenHeight - 180}px`;
        setDataTableHeight(newHeight);
    };
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const hanldeClose=()=>{
        Settitle("Add Staff")
        SetData({})
        setModalShow(false)
    }
    const HandleCount = (num) => {
        console.log(num)
        setCount(num)
    }
    const HandleMouse = (row) => {
        setTableId(row.id);
    };
const[Message,setMessage]=useState()
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase(); // Convert query to lowercase
        setSearchQuery(query);
      
        const filteredUsers = users.filter((user) => (
          (user.roles &&
            user.roles[0] &&
            user.roles[0].role_name.toLowerCase().includes(query)) ||
          (user.first_name && user.first_name.toLowerCase().includes(query)) ||
          (user.last_name && user.last_name.toLowerCase().includes(query)) ||
          (user.email && user.email.toLowerCase().includes(query)) ||
          (user.mobileno && user.mobileno.toLowerCase().includes(query))
        ));
    SetClientData(filteredUsers)
    };
    const columns = [
        {
            name: "ID",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
        },
        {
            name: "CLIENT ROLE",
            selector: "roles",
            cell: (row) => row.roles[0] ? row.roles[0].role_name : '',
            sortable: true,
        },
        {
            name: "NAME",
            selector: "first_name",
            cell: (row) => `${row.first_name} ${row.last_name}`,
            sortable: true,

        },
        {
            name: "PHONE NUMBER",
            selector: "mobileno",
            cell: (row) => `${row.mobileno}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "EMAIL",
            selector: "email",
            cell: (row) => `${row.email}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },

    ]
    const customColumns = tableId
        ? columns.concat({
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    Settitle("Edit Staff")
                    setModalShow(true)
                    console.log(row)
                    SetData({user_id:row.id,role: row.roles.length>0?row.roles[0].id:null,
                        first_name: row.first_name,
                        last_name: row.last_name,
                        phone: row.mobileno,
                        email: row.email,
                        password: row.password,
                        confirm_password: row.confirm_password})
                }
                const DeleteAlert = () => {

                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (user_id) => {
                    await axiosConfig.post(`/client/clients/${id}/delete_staff/`,{user_id:user_id}).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                const shouldShowActions = tableId === row.id
                console.log(shouldShowActions, actionPermisson)

                return (
                    <>
                        {
                            shouldShowActions && (
                                <div className="row" >

                                    {<div className="col-4">
                                        <AiOutlineEye className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                    </div>}
                                    <div className="col-3">
                                        <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                    </div>
                                    <div className="col-3">
                                        <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                    </div>
                                </div>

                            )

                        }
                        {shouldShowActions || (<p className="Active-class">Active</p>)}
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
            }
        })
        : columns;
    const handleRowsPerPageChange = (event) => {
        console.log(event.target.value);
        setCurrentPage(1);
    };
    const HandleModal = () => {
        setModalShow(true)
    }
    const CustomStyles = {
        rows: {
            style: {
                justifyContent: "center",
            },
        },
        cells: {
            style: {
                justifyContent: "center",
            },
        },
        headCells: {
            style: {
                backgroundColor: "#F6FDFF",
                justifyContent: "center",
                color: "rgb(33, 37, 41)",
                fontWeight: 700,
                lineHeight: "24px",
                textTransform: "uppercase",
            },
        },
    };

    return (
        <>
            <div className="d-flex justify-content-end mb-4 mx-4">
                <button className="btn btn-white1" onClick={HandleModal}>+ Add Staff</button>
            </div>
            <div className="d-flex flex-row justify-content-end pe-3">
                <div className="form-control d-flex align-items-center justify-space-between w-25 mb-5">
                    <input type="text" placeholder="Search" className="input-search" name="search" value={searchQuery} onChange={handleSearch} />
                    <div className="search-icon1">
                        <AiOutlineSearch size={24} />
                    </div>
                </div>
            </div>
            <div className="table-actions">

            </div>
            <div className="table-header-menu">
                <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                    <Dropdown.Toggle variant="">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={handleCheckboxClick}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                        <Dropdown.Item>Action</Dropdown.Item>
                        <Dropdown.Item>Another action</Dropdown.Item>
                        <Dropdown.Item>Something else here</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item>Separated link</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <MdOutlineRefresh className="sidebar-icon-table" />
                <CiMenuKebab className="sidebar-icon-table" />
            </div>
            <div className="table-responsive">
                {ClientData && <DataTable columns={customColumns} data={ClientData}
                    fixedHeader
                    customStyles={tableCustomStyles}
                    fixedHeaderScrollHeight="450px"
                    onRowMouseEnter={HandleMouse}

                    onSelectedRowsChange={({ selectedRows }) =>
                        console.log(selectedRows)
                    }
                    selectableRowsHighlight
                >
                </DataTable>}
            </div>

            <Modal
                show={modalShow}
                onHide={hanldeClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="modalHeading">
                       {Tilte}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={masterModal}  formData={Data} button="btn-block" buttonTitle="Create Staff" formSubmit={formSubmit} error={Error} setCount={setCount} count={count} />
                </Modal.Body>
            </Modal>
            {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message={Message} handpopup={hanldeClose}/>
            }
        </>
    )
}
export { AddStaff }