import React, { useEffect, useState, useRef } from "react";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import { DeletePopupModal, PopupModal } from "../Components";
import { MdOutlineRefresh, MdOutlineHourglassEmpty, MdOutlineInsertDriveFile, MdOutlineAvTimer } from "react-icons/md"
import { IoMdArrowUp, IoIosCheckmarkCircleOutline } from "react-icons/io"
import { CiMenuKebab } from "react-icons/ci"
import { DashboardLayout, Pagination } from "../Components";
import { NavLink, json, useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
import { Button, Dropdown } from 'react-bootstrap';
import { GrNewWindow, GrInProgress, GrAttachment } from "react-icons/gr"
import { HiOutlineUsers } from "react-icons/hi"
import { BiSpreadsheet } from "react-icons/bi"
import { ImFilesEmpty } from "react-icons/im"
import { VscFiles } from "react-icons/vsc"
import { RiUserFollowLine } from "react-icons/ri"
import { GrGallery } from "react-icons/gr"
import { MdOutlineAddTask, MdCopyright, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { TiAttachment } from "react-icons/ti"
import { AiOutlineUserAdd, AiOutlineMessage, AiOutlineFileText } from "react-icons/ai"
import './Worklists.css'

const Worklists = ({ Code }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tableRef = useRef(null);
    let month = searchParams.get("month")
    console.log(month)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [deletePopupType, setDeletepopupType] = useState("")
    const [popupType, setpopupType] = useState("")
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [buttonType, setButtonType] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])
    const [tableId, setTableId] = useState()
    const [hoveId, setHoverId] = useState()
    const [details, setDetails] = useState({
        Total_Clients: 0,
        Total_Cases: 0,
        Total_Price: 0.0,
    });
    const [ActiveClass, setActiveClass] = useState("New")
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [search, SetSearch] = useState({
        search: "",
        month: ""
    })
    const [select, Setselct] = useState({
        month: ""
    })

    const [Datafilter, SetDatafilter] = useState([])
    const [page, NextPage] = useState(1)
    console.log(page)
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const GetData = async () => {
        setLoading(true)
        try {

            const responce = await axiosConfig.get(`/cases/cases/?page=${currentPage}&page_size=${RowPerPage}&status=${ActiveClass}`);
            console.log(responce.data.results, "case details...")
            SetData(responce.data.results);
            setLoading(false);
            setTotalRows(responce.data.count);
        }
        catch (error) {
            console.log(error)
        }
    }
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        const find_data = data.find(res => res.modulescreen.code === Code)
        console.log(find_data)
        SetActionPermission(find_data.modulescreen)
    }
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData();
    }, [currentPage, RowPerPage, currentColumn, currentDirection, ActiveClass]);
    const HandleNextPage = () => {
        NextPage(page + 1)
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        const response = Datafilter.filter(data => data["Inst.Group"] && data["Inst.Group"].toLowerCase().includes(value.toLowerCase()) || data.client_code && data.client_code.toLowerCase().includes(value.toLowerCase()) || data.client_name && data.client_name.toLowerCase().includes(value.toLowerCase()) || data.total_amount && data.total_amount.toString().includes(value) || data.no_of_cases && data.no_of_cases.toString().includes(value) || data.client_id && data.client_id.toString().includes(value))
        console.log(response)
        SetData(response)
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        month = value
        setSearchParams({ 'month': value });
        GetData();
    }
    const HandleMouse = (row) => {
        setTableId(row.patient_id)
        setHoverId(row.patient_id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`;
        setDataTableHeight(newHeight);
    };
    const TimeChange = (time) => {
        if (time) {
            const timeParts = time.split('T')[1].split(':');
            let hours = parseInt(timeParts[0]);
            const minutes = timeParts[1];
            let ampm = 'am';

            if (hours >= 12) {
                ampm = 'pm';
                if (hours > 12) {
                    hours -= 12;
                }
            }
            return `${hours}:${minutes}${ampm}`;
        }
        else {
            return null
        }
    }
    const handleRowClick = (row) => {
        console.log(row, "row...")
        { actionPermisson && actionPermisson.can_add && navigate(`/dashboard/worklist/cases/${row.id}`) }
    }
    const convertTo12HourFormat = (time24) => {
        const [hours, minutes, seconds] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'pm' : 'am';
        const adjustedHours = (hours % 12 || 12).toString().padStart(2, '0');
        const adjustedMinutes = minutes.toString().padStart(2, '0');
        return `${adjustedHours}:${adjustedMinutes} ${period}`;
    }
    const convertToDate = (date) => {
        const inputDate = new Date(date);
        const options = { day: '2-digit', month: 'short' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
        return formattedDate
    }
    const columns = [
        {
            name: "Flexible Column",
            selector: "flexible_column",
            cell: (row) => (
                <div className="flexible-column-container" onClick={() => handleRowClick(row)}>
                    <div className="d-flex flex-column">
                        <div className="client-name">{row.client_name}</div>
                        <div className="client-institution">{row.instutution_name}</div>
                    </div>
                    <div className="flexible-sub-container">
                        <div className="flexible-sub-top">
                            <div className="case-status">{row.case_status}</div>
                            <div className="patient-name">
                                <img src={require('./images/patienticon.png')} className="patient-img" />
                                <div className="d-flex flex-column">
                                    <div>({row.patient_name})</div>
                                    {row.age && row.sex && <div>{`${row.age.split('')[0]}Y/${row.sex}`}</div>}
                                </div>
                            </div>
                            {row.patient_id ? <div className="patient-name">{row.patient_id}</div> : <div></div>}
                            {row.referring_physician && row.referring_physician !== 'null' && row.referring_physician !== '' ?
                                <div className="patient-name">
                                    <img src={require('./images/doctoricon.png')} className="patient-img" />
                                    {row.radiologist_name}
                                </div> : <div></div>
                            }
                            <div className="patient-study">
                                <div>{row.modality_name}</div>
                                <div className="ellipsis">{row.study}</div>
                            </div>
                            <div>
                                {tableId === row.patient_id ?
                                    <div className="hover-icons">
                                        {actionPermisson && actionPermisson.can_read &&
                                            <>
                                                <GrAttachment className="dashboard-tabe-data-actions" />
                                                <MdOutlineInsertDriveFile className="dashboard-tabe-data-actions" />
                                                <AiOutlineMessage className="dashboard-tabe-data-actions" />
                                            </>
                                        }
                                    </div> :
                                    <div className="d-flex justify-content-between">
                                        <div className="stat">Stat<IoMdArrowUp /></div>
                                        {row.is_set_as_completed ?
                                            <div className="set-as">Set As<MdOutlineAddTask color="green" /></div>
                                            :
                                            <div className="set-as">Set As<MdOutlineHourglassEmpty color="red" /></div>
                                        }
                                        <div className="d-flex flex-column">
                                            <div className="receiving-date">{convertToDate(row.receiving_date.split('T')[0])} {convertTo12HourFormat(row.receiving_date.split('T')[1])}</div>
                                            <div className="receive-time"><MdOutlineAvTimer />02:30hr</div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className="flexible-sub-bottom">
                            <div className="patient-attachment"><GrGallery />Total images recived |<span className="images-count">200</span></div>
                            <div className="patient-attachment"><GrAttachment />Attachments</div>
                            <div className="patient-attachment"><MdOutlineInsertDriveFile />History</div>
                        </div>
                    </div>
                </div>
            ),
            sortable: false,
        }
    ]
    const HandleAcitvetab = (status) => {
        setActiveClass(status)
    }

    const checkStatus = (array) => {
        let newCount = 0;
        let assignedCount = 0;
        if (array.length === 0) {
            setButtonType(null)
        }
        setSelectedRows(array)
        for (let i = 0; i < array.length; i++) {
            if (array[i].case_status === 'New') {
                newCount++;
            } else if (array[i].case_status === 'Assigned') {
                assignedCount++;
            }
        }
        if (newCount === array.length) {
            setButtonType('Assign')
        } else if (assignedCount === array.length) {
            setButtonType('Reassign/Unassign')
        } else {
            setButtonType(null)
        }
    }

    return (
        <>
            {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Final Bills</h5>
                    <input type="month" name="month" className="form-control month-selector" value={month} onChange={HandleSelect} />
                </div> */}
            <div className="tablescroll-clientbill-parent">
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        {buttonType !== null && selectedRows.length > 0 ? <div className="patient-attachment">{buttonType}</div> : <>
                            <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                <Dropdown.Toggle variant="">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onClick={handleCheckboxClick}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item>Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else here</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <MdOutlineRefresh className="sidebar-icon-table" />
                            <CiMenuKebab className="sidebar-icon-table" />
                        </>}
                    </div>
                    <div className="table-responsive table-header-main-none">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page, totalRows) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer
                            fixedHeader
                            customStyles={tableCustomStyles}
                            fixedHeaderScrollHeight={dataTableHeight}
                            subHeader
                            selectableRows
                            onSelectedRowsChange={({ selectedRows }) => {
                                console.log(selectedRows)
                                checkStatus(selectedRows)
                            }}
                            selectableRowsHighlight
                            highlightOnHover
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            subHeaderComponent={
                                <div className="serach-class">
                                    <div className={ActiveClass === "New" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("New")}><span><GrNewWindow size={20} /></span>New</div>
                                    <div className={ActiveClass === "inprogress" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("inprogress")}><span><GrInProgress size={20} /></span>In Progress</div>
                                    <div className={ActiveClass === "completed" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("completed")}><span><IoIosCheckmarkCircleOutline size={20} /></span>Completed</div>
                                </div>
                            } />
                    </div>
                </div>
            </div>
            {/* <div className="copyright">
                    <MdCopyright /> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                </div> */}
            {popupType != "" &&
                <PopupModal popupType={popupType} />
            }
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }

        </>
    )

}
export { Worklists }