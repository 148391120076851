import React, { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { DeletePopupModal, PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig"

const Institution = ({ clientData, GetData }) => {
    const [readOnly, setReadOnly] = useState(true);
    const [search, setSearch] = useState("");
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [uncheckedInstitutions, setUncheckedInstitutions] = useState([])
    const [filteredChecked, setFilteredChecked] = useState([])
    const [filteredUnchecked, setFilteredUnchecked] = useState([])
    const [checkedInstitutions, setCheckedInstitutions] = useState([])
    const [clientInfo, setClientInfo] = useState()
    const [institutionId, setInstitutionId] = useState('')
    const [popupType, setpopupType] = useState("")
    const handleCheckboxChange = async (e, id) => {
        let checked = e.target.checked
        let patchData = { ...clientInfo }
        if (!checked) {
            setInstitutionId(id)
            DeleteAlert()
        } else {
            console.log(patchData, "inside handle checkbox change")
            patchData.institution.push({ id: id })
            setClientInfo(patchData)
        }
    };
    const DeleteAlert = () => {
        SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = async (id) => {
        let patchData = { ...clientInfo }
        let filteredArray = patchData.institution.filter(item => item.id !== id).map(item => item.id)
        console.log({ ...patchData, institution: filteredArray }, "inside handledelete...")
        await axiosConfig.put(`client/clients/${clientData.id}/update_institutions/`, { institutions: filteredArray }).then((res) => {
            setClientInfo(res.data)
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")
            GetData()
            setReadOnly(true)
        }).catch((error) => {
            setDeletepopupType("error")
            console.log(error)
        })
    }

    const handleSave = async (client_id) => {
        setReadOnly(true);
        console.log(clientInfo, "client info")
        let patchData = { institutions: clientInfo.institution.map(item => item.id) }
        try {
            const response = await axiosConfig.put(`client/clients/${client_id}/update_institutions/`, patchData);
            console.log('Patch request successful', response.data);
            setpopupType("success")
            GetData();
        } catch (error) {
            console.error('Error making patch request', error);
            setpopupType("error")
        }
    };
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        let checked_results = checkedInstitutions.filter(institution =>
            institution.institution_name.toLowerCase().search(event.target.value) !== -1
        )
        let unchecked_results = uncheckedInstitutions.filter(institution =>
            institution.institution_name.toLowerCase().search(event.target.value) !== -1
        )
        setFilteredChecked(checked_results)
        setFilteredUnchecked(unchecked_results)
    };

    const GetInstitutions = async () => {
        try {
            const response = await axiosConfig.get(`/client/clients/${clientData.id}/get_institutions/`)
            setCheckedInstitutions(response.data)
            setFilteredChecked(response.data)
            console.log(response.data, "checked institutions...")
        } catch (error) {
            console.log("Error getting checked institutions : ", error)
        }
    }

    const GetUncheckedInstitutions = async () => {
        try {
            const response = await axiosConfig.get(`/client/clients/get_new_institutions/`)
            setUncheckedInstitutions(response.data)
            setFilteredUnchecked(response.data)
            console.log(clientData, "client data...")
        } catch (error) {
            console.log("Error getting institutions : ", error)
        }
    }

    useEffect(() => {
        GetInstitutions()
        GetUncheckedInstitutions()
        clientData && setClientInfo(clientData)
    }, [clientData])

    return (
        <>
            <div className="form-control d-flex align-items-center search-block">
                <input
                    type="text"
                    placeholder="Search"
                    className="input-search"
                    name="search"
                    value={search}
                    onChange={handleSearchChange}
                />
                <div className="search-icon1">
                    <AiOutlineSearch size={24} />
                </div>
            </div>
            <div className="row insti_checkbox">
                {filteredChecked && filteredChecked.map((institution) => {
                    return (
                        <label key={institution.id} className="col-3 form-check-label insti-label">
                            <input
                                type="checkbox"
                                className="ari-radio"
                                checked={clientInfo.institution ? clientInfo.institution.some(item => item.id === institution.id) : false}
                                onChange={(e) => handleCheckboxChange(e, institution.id)}
                                disabled={readOnly}
                            />
                            <div className="institution-name">
                                {institution.institution_name}
                            </div>
                        </label>
                    )
                })}
            </div>
            <div className="centered-container">
                <hr className="horizontal-line" />
                <div className="centered-text">Unmapped Institutions</div>
                <hr className="horizontal-line" />
            </div>
            <div className="row insti_checkbox">
                {filteredUnchecked && filteredUnchecked.map((institution) => {
                    return (
                        <label key={institution.id} className="col-3 form-check-label insti-label">
                            <input
                                type="checkbox"
                                className="ari-radio"
                                checked={clientInfo.institution ? clientInfo.institution.some(item => item.id === institution.id) : false}
                                onChange={(e) => handleCheckboxChange(e, institution.id)}
                                disabled={readOnly}
                            />
                            <div className="institution-name">
                                {institution.institution_name}
                            </div>
                        </label>
                    )
                })}
            </div>
            <div>
                {readOnly ? (
                    <button className="btn btn-primary insti-button" onClick={() => setReadOnly(false)}>
                        <div>Edit</div>
                        <MdOutlineEdit className="ms-5" />
                    </button>
                ) : (
                    <button className="btn btn-primary insti-button" onClick={() => handleSave(clientData.id)}>
                        Save
                    </button>
                )}
            </div>
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={institutionId} />
            }
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message="Institutions Updated" />
            }
        </>
    );
};

export { Institution };
