import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import { useParams } from "react-router-dom";
import { DashboardLayout, FormParser, PopupModal } from "../Components";


const ClientBillingImformation = () => {
let { id } = useParams();
  const MastersModal = Masters.ClientBillInformation;
  const [readOnly, setReadOnly] = useState(true);
  const [popupType, setpopupType] = useState("");
  const [Data, SetData] = useState([])
  const [Loading,setLoading]=useState(false)
  const [Error,SetError]=useState("")

  const [ClientsBillinfo, SetClientBillinfo] = useState({
    credit_limit:"",
    credit_terms:"",
    gst_number:"",
    pan_number:"",
    })
    // const [ClientsInfoEdit, SetClientInfoEdit] = useState({
    //     credit_limit:"",
    //     credit_terms:"",
    //     gst_number:"",
    //     pan_number:"",
    // })

  const GetData = () => {
    setLoading(true)
    
    axiosConfig.get(`/client/clients/${id}/`).then(res => {
        SetData(res.data)
        SetClientBillinfo((prev) => ({
          ...prev,
          credit_limit:res.data.credit_limit,
          credit_terms:res.data.credit_terms,
          gst_number:res.data.gst_number,
          pan_number:res.data.pan_number
        }))
        console.log(res.data,"data")
        setLoading(false)

    }).catch(error => {
        console.log(error)
    })
}

const [clientsEditData, SetClientEditData] = useState([])
const clientEditData = () => {
    axiosConfig.get(`/client/clients/${id}/`).then(res => {
        SetClientEditData({ "credit_limit":res.data.credit_limit,
        "credit_terms":res.data.credit_terms.id,
        "gst_number":res.data.gst_number,
        "pan_number":res.data.pan_number})
    }).catch(error => {
        console.log(error)
    })
}


  
  const formSubmit = (Data) => {
    console.log(Data)
    axiosConfig.put(`/client/clients/${id}/`, { "credit_limit":Data.credit_limit,
    "credit_terms":Data.credit_terms,
    "gst_number":Data.gst_number,
    "pan_number":Data.pan_number}).then(res => {
        console.log(res)
        setpopupType("success")
        setReadOnly(true)
        SetError("")
    }).catch(error => {
        if(error.response.data.pan_number[0]==="Ensure this field has no more than 10 characters."){
          SetError("Ensure Pan Card Number field has no more than 10 characters.")
        }
        else if(error.response.data.gst_number[0]==="Ensure this field has no more than 10 characters."){
          SetError("Ensure GST Number field has no more than 10 characters.")
        }
        

        setpopupType("error")
    })
}
useEffect(() => {
    GetData()
    clientEditData()
}, [])

  return (
    <>
      <FormParser
        modelObject={MastersModal}
        formSubmit={formSubmit}
        formData={clientsEditData}
        error={Error}
        buttonTitle="Save"
        readOnly={readOnly}
        setReadOnly={setReadOnly}
      />
      {readOnly && (
        <button
          className={`btn btn-primary d-flex align-items-center`}
          style={{ marginLeft: "30px" }}
          onClick={() => setReadOnly(false)}
        >
          <div>Edit</div>
          <MdOutlineEdit className="ms-5" />
        </button>
      )}
      {popupType != "" &&
             <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message="Record Updated Sucessfully" />
        }
    </>
  );
};

export default ClientBillingImformation;
