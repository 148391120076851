import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import AlertDeletePopupModal from "../PopupModal/AlertDeletePopupModal";
import { AiOutlineEye } from "react-icons/ai"
import {TiTick} from "react-icons/ti"
const ReactQuillDynamicComponent = ({ value, key, handleEditorChange,HandleReport,SetAlertDeletePopupType,AlertDeletePopupType, index,readOnly,setreadOnly, handleEditorDelete, isEditing, handleEditClick, formatDate, responce ,GetData, modalShow, setModalShow,formDataTemplate,setFormDataTemplate}) => {
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
        [{ 'direction': 'rtl' }], // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from the theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean'], // remove formatting button
    ];
    const HandleEdit = async() => {
        let editData = await GetData(responce.service_template_id);
        console.log(editData)
        setModalShow(!modalShow)
        setFormDataTemplate({ ...formDataTemplate,id:responce.id, preview:editData.summary,service_template:editData.id});
    }
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")

    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const  handleDisble=()=>{
        setreadOnly(true)
       HandleEdit();
       }
    const editorStyles = {
        flexShrink: 0,
        opacity: 0.5,
        background: '#000',
        color: "white"
    };
    return (
        <div>
             <div>
                        <div className="d-flex justify-content-between my-4">
                            <div>
                                <p>
                                    <b>{responce.service_name}  </b>
                                    <b style={{ color: "#FF0404" }}>({responce.report_status})</b>
                                </p>
                                <p><b>Reported by {responce.first_name} {responce.last_name}</b></p>
                                <p><b>Created at: {formatDate(responce.created_at)},</b> <b>Last Modified: {formatDate(responce.modified_at)}</b></p>
                            </div>
                            <div className="style-edit-worklist">
                                <div className="row text-center" >
                                    <div className="col-6">
                                        <AiOutlineEye className="dashboard-tabe-data-actions" onClick={handleDisble} />
                                    </div>
                                    <div className="col-6">
                                        <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={() => DeleteAlert(responce.id)} />
                                    </div>
                                </div>
                                {!isEditing && <div>
                                    <button type="submit" className="btn btn-primary" onClick={HandleReport}><TiTick/> Click to Finish Report</button>
                                </div>}
                            </div>
                        </div>
                        <div className="position-relative">
                            <ReactQuill key={key} modules={{ toolbar: toolbarOptions }} value={value} onChange={(content) => handleEditorChange(index, content)} readOnly={isEditing} style={isEditing ? editorStyles : { background: "#FFF" }} />
                            {isEditing && <div className="postion-finish">
                                <button type="submit" className="btn btn-primary" onClick={() => handleEditClick(index)}><MdOutlineEdit /> Click to Edit Report</button>
                            </div>}
                        </div>
                    </div>
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={handleEditorDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
        </div>
    )
}
export { ReactQuillDynamicComponent }