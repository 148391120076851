import React, { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DashboardLayout } from "../Components";
import { RadiologistInfo } from "./RadiologistInfo";
import { MdArrowBack, MdOutlineEdit } from "react-icons/md";
import { IoClipboardOutline } from "react-icons/io5";
import { BiMessageEdit, BiUndo } from "react-icons/bi";
import { ProfessionalDetails } from "./ProfessionalDetails";
import { Remuneration } from "./Remunaration";
import { Signature } from "./Signature";
import { Preference } from "./Preference";
import { RadiologistAvailability } from "./RadiologistAvailability";
import { useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";

const RadiologistTabs = () => {
    const [key, setKey] = useState('Personal/Basic Information');
    const { id } = useParams()
    const [user, setUser] = useState([])
    const [percentage, setPercentage] = useState()
    const GetUser = async () => {
        try {
            const response = await axiosConfig.get(`/account/users/${id}/`)
            setUser(response.data)
        } catch (error) {
            console.log('Error getting user : ', error)
        }
    }
    const GetPercentageStatus = async () => {
        try {
            const response = await axiosConfig.get(`/account/radiologist/${id}/get_percentage/`)
            setPercentage(response.data.percentage)
        } catch (error) {
            console.log("Error getting percentage : ", error)
        }
    }

    useEffect(() => {
        GetUser();
        GetPercentageStatus();
    }, [])
    const [radiologist, setRadiologist] = useState({});
    const navigate = useNavigate();
    const GetRadiologist = async () => {
        try {
            const response = await axiosConfig.get(`/account/users/${id}/`)
            console.log(response.data, "insidde Get RADio...")
            setRadiologist(response.data)
        } catch (error) {
            console.log("Error getting radiologist : ", error)
        }
    }

    useEffect(() => {
        GetRadiologist();
    }, [])

    return (
        
            <div className="tablescroll-clientbill-parent">
                <div className="tablescroll-clientbill">
                    <div>
                        <div className="options-container">
                            <MdArrowBack className="option-icon" onClick={() => navigate("/dashboard/radiologist")} />
                            <MdOutlineEdit className="option-icon" />
                            <IoClipboardOutline className="option-icon" />
                            <BiMessageEdit className="option-icon" />
                            <BiUndo className="option-icon" />
                        </div>
                        <div></div>
                    </div>
                    <div className="client-info">
                        <div className="row">
                            <div className="col-3 d-flex justify-content-between">
                                <div className="client-field">Radiologist Name</div>
                                <div>:</div>
                            </div>
                            <div className="col-9">{radiologist.first_name}</div>
                        </div>
                        <div className="row">
                            <div className="col-3 d-flex justify-content-between">
                                <div className="client-field">Phone Number</div>
                                <div>:</div>
                            </div>
                            <div className="col-9">{radiologist.mobileno}</div>
                        </div>
                        <div className="row">
                            <div className="col-3 d-flex justify-content-between">
                                <div className="client-field">Email</div>
                                <div>:</div>
                            </div>
                            <div className="col-9">{radiologist.email}</div>
                        </div>
                        <div className="row">
                            <div className="col-3 d-flex justify-content-between">
                                <div className="client-field">Profile Status</div>
                                <div>:</div>
                            </div>
                            <div className="col-9">{percentage ? percentage.toFixed(2) : ''}% Completed</div>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Personal/Basic Information" title="Personal/Basic Information">
                            <RadiologistInfo />
                        </Tab>
                        <Tab eventKey="Professional Details" title="Professional Details">
                            <ProfessionalDetails />
                        </Tab>
                        <Tab eventKey="Signature" title="Signature">
                            <Signature />
                        </Tab>
                        <Tab eventKey="Preference" title="Preference">
                            <Preference />
                        </Tab>
                        <Tab eventKey="Reset Password" title="Reset Password">
                            Tab content for Reset Password
                        </Tab>
                        <Tab eventKey="Remuneration" title="Remuneration">
                            <Remuneration />
                        </Tab>
                        <Tab eventKey="Radiologist Available Timings" title="Radiologist Available Timings">
                            <RadiologistAvailability />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        
    )
}

export { RadiologistTabs }