import React,{useState} from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const TariffTypeAdd = () => {
    const[Error,SetError]=useState("")
    const [popupType, setpopupType] = useState("");

    const MastersModal=Masters.TariffType
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/tarifftype/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error.response.data)
            const ErrorData=[]
            ErrorData.push({
                tarifftype_code:error.response.data.tarifftype_code||["null"],
                state_id:error.response.data.state_id||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].tarifftype_code[0]==="This field must be unique."){
                SetError("Code Already Exists")
            }
            setpopupType("error")
        })
      }
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add TariffType</h5>
                    <NavLink to="/dashboard/masters/tarifftype" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/tarifftype"/>
                }
            
        </>
    )

}
export {TariffTypeAdd}