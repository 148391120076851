import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css";
import "./DetailedView.css";
import { MdOutlineEdit } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack, BiCommentEdit } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { AiOutlineFile, AiOutlineMessage, AiOutlineCheck } from "react-icons/ai";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AddStaff } from "./AddStaff";
import { AccountSetting } from "./AccountSetting";
import { Institution } from "./Institution";
import { PrintingPreference } from "./PrintingPreference";
import { BusinessInformation } from "./BusinessInformation";
import { ClientTariffData } from "./ClientTariff";
import ClientBillingImformation from "./ClientBillImformation";
const ClientEdit = ({ Code }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [key, setKey] = useState('Client Information');
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const MastersModal = Masters.ClientEdit
    const [EditData, SetEditData] = useState()
    const [check, setCheck] = useState(false)
    const [clientTypes, setClientTypes] = useState([])
    const [percentage, setPercentage] = useState()
    const [Clientsinfo, SetClientinfo] = useState({
        client_role: "",
        name: "",
        area: "",
        address: "",
        phone: "",
        email: ""
    })
    const [ClientsEdit, SetClientEdit] = useState({
        client_role: "",
        name: "",
        area: "",
        address: "",
        phone: "",
        email: "",
    })
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        SetClientinfo({ ...Clientsinfo, [name]: value })
    }
    const HandleClientEdit = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        SetClientEdit({ ...ClientsEdit, [name]: value })
    }
    console.log(ClientsEdit)
    const [Error, SetError] = useState("")
    // const ClientinfonSubmit = (e) => {
    //     e.preventDefault()
    //     const regx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    //     if (!Clientsinfo.email.match(regx)) {
    //         SetError("Email is Not Valid")
    //     }
    //     else {
    //         axiosConfig.post("/client/client-info/", {
    //             client_role: Clientsinfo.client_role,
    //             name: Clientsinfo.name,
    //             area: Clientsinfo.area,
    //             address: Clientsinfo.address,
    //             phone: Clientsinfo.phone,
    //             email: Clientsinfo.email, client: id
    //         }).then(res => {
    //             SetClientinfo({
    //                 name: "",
    //                 area: "",
    //                 address: "",
    //                 phone: "",
    //                 email: ""
    //             })
    //             SetError("")
    //             setpopupType("success")
    //             GetData()
    //             setShow(!Show)
    //         }).catch(error => {
    //             setpopupType("error")
    //         })
    //     }
    // }
    const formSubmit = (Data) => {
        console.log(Data, "inside form submit ...")
        let postData = {
            clienttype: Data.clienttype,
            client_name: Data.client_name,
            client_alias_name: Data.client_alias_name,
            client_description: Data.client_description,
            contact_person: Data.contact_person,
            phone: Data.phone,
            email: Data.email,
            additional_email: Data.additional_email,
            state: Data.state,
            city: Data.city,
            address: Data.address,
        }
        if (postData.clienttype === "DOMESTIC") {
            postData.clienttype = 1
        }
        if (postData.clienttype === "INTERNATIONAL") {
            postData.clienttype = 2
        }
        console.log(postData, "postData")
        axiosConfig.patch(`/client/clients/${id}/`, postData).then(res => {
            console.log(res)
            setpopupType("success")
            setReadOnly(true)
            setReadOnly(true)
        }).catch(error => {
            console.log(error.response.data.error)
            const ErrorData = []
            ErrorData.push({
                client_code: error.response.data.error || ["null"],
            })
            if (ErrorData[0].client_code === "Oops, the client Fields already exists. Please choose to enter a different Field Value.") {
                SetError("Code Already Exists")
            }
            setpopupType("error")
        })
    }
    const [Data, SetData] = useState([])
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/client/clients/${id}/`).then(res => {
            let client_data = {
                ...res.data,
                state: res.data.state.id,
                city: res.data.city.id
            };
            if (res.data.clienttype) {
                client_data.clienttype = res.data.clienttype.tarifftype_code;
            }
            SetData(client_data)
            console.log(client_data, "inside GetData...")
            setLoading(false)
            GetClientTypes()
        }).catch(error => {
            console.log(error)
        })
    }
    const GetPercentageStatus = async () => {
        try {
            const response = await axiosConfig.get(`/client/clients/${id}/get_percentage/`)
            setPercentage(response.data.percentage)
        } catch (error) {
            console.log("Error getting percentage : ", error)
        }
    }
    // const clientinfoEdit = (clientinfoid) => {
    //     const { client_role,
    //         name,
    //         area,
    //         address,
    //         phone,
    //         email, } = ClientsEdit
    //     axiosConfig.put(`/client/client-info/${clientinfoid}/`, {
    //         client_role: ClientsEdit.client_role,
    //         name: ClientsEdit.name,
    //         area: ClientsEdit.area,
    //         address: ClientsEdit.address,
    //         phone: ClientsEdit.phone,
    //         email: ClientsEdit.email,
    //         client: id
    //     }).then(res => {
    //         console.log(res.data)
    //         // SetClientEdit(res.data)
    //         SetEditData(null)
    //         GetData();
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // }
    // const [ClientData, SetClientData] = useState([])
    // const ReferenceDoamain = () => {
    //     axiosConfig.get("/masters/referencedomainvalue/?domain=1&domain__code=CLIENTROLE").then(res => {
    //         SetClientData(res.data.results)
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // }
    const [area, SetArea] = useState([])
    const Area = () => {
        axiosConfig.get("/masters/area/").then(res => {
            console.log(res)
            SetArea(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Clients, SetClients] = useState([])
    const clients = () => {
        axiosConfig.get("/client/clients/").then(res => {
            console.log(res.data)
            SetClients(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [clientsEditData, SetClientEditData] = useState([])
    const clientEditData = () => {
        axiosConfig.get(`/client/clients/${id}/`).then(res => {
            let client_data = { ...res.data, state: res.data.state.id, city: res.data.city.id }
            console.log(client_data)
            SetData(client_data)
        }).catch(error => {
            console.log(error)
        })
    }
    // const [isOpen, setIsOpen] = useState(false);
    // const dropdownRef = useRef();

    // const toggleCheckbox = () => {
    //     setIsOpen(!isOpen);
    // };

    // const handleCheckboxClick = (e) => {
    //     e.stopPropagation();
    //     setCheck(true)
    // };
    // const handleDropdownToggle = () => {
    //     setIsOpen(!isOpen);
    // };
    const GetClientTypes = async () => {
        try {
            const response = await axiosConfig.get(`/masters/tarifftype/`);
            console.log(response.data.results)
            setClientTypes(response.data.results)
        } catch (error) {
            console.log("Error getting client types : ", error)
        }
    }
    useEffect(() => {
        clientEditData()
        // clients()
        // Area()
        // ReferenceDoamain()
        GetData()
        GetPercentageStatus()
    }, [])
    // const HandleEdit = (id) => {
    //     SetEditData(id)
    //     axiosConfig.get(`/client/client-info/${id}/`).then(res => {
    //         SetClientEdit(res.data)
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // }
    // const [RowId, SetRowId] = useState()
    // const DeleteAlert = (Tariffid) => {
    //     SetRowId(Tariffid)
    //     SetAlertDeletePopupType("success")
    // }
    // const HandleDelete = (id) => {
    //     console.log(id)
    //     axiosConfig.delete(`/client/client-info/${id}/`).then(res => {
    //         GetData();
    //         SetAlertDeletePopupType("error")
    //         setDeletepopupType("success")

    //     }).catch(error => {
    //         setDeletepopupType("error")
    //         console.log(error)
    //     })
    // }
    // const CancelAlerrt = () => {
    //     SetAlertDeletePopupType("error")
    // }
    // const handleSelectChange = (selectedOption, name) => {
    //     console.log(selectedOption.value, name)
    //     const name = e.target.name
    //      const value=e.target.value
    //     //  console.log(name,value)
    //     SetClientinfo({ ...Clientsinfo, [name]: selectedOption.value })
    // };
    console.log(Clientsinfo)
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Clients</h5>
                    <NavLink to="/dashboard/users/clients" className="text-decoration-none" ><button className="btn btn-primary">+ Add New</button></NavLink>
                </div>
                <div className="tablescroll-clientbill-parent">
                    <div className="tablescroll-clientbill">
                        <div className="client-options">
                            <BiArrowBack onClick={() => navigate("/dashboard/users/clients/")} className="option-icon" />
                            <MdOutlineEdit className="option-icon" />
                            <ImAttachment className="option-icon" />
                            <AiOutlineFile className="option-icon" />
                            <AiOutlineMessage className="option-icon" />
                            <AiOutlineCheck className="option-icon" />
                            <BiCommentEdit className="option-icon" />
                        </div>
                        <div className="client-info">
                            <div className="row">
                                <div className="col-3 d-flex justify-content-between">
                                    <div className="client-field">Client Name</div>
                                    <div>:</div>
                                </div>
                                <div className="col-9">{Data.client_name}</div>
                            </div>
                            <div className="row">
                                <div className="col-3 d-flex justify-content-between">
                                    <div className="client-field">Phone Number</div>
                                    <div>:</div>
                                </div>
                                <div className="col-9">{Data.phone}</div>
                            </div>
                            <div className="row">
                                <div className="col-3 d-flex justify-content-between">
                                    <div className="client-field">Email</div>
                                    <div>:</div>
                                </div>
                                <div className="col-9">{Data.email}</div>
                            </div>
                            <div className="row">
                                <div className="col-3 d-flex justify-content-between">
                                    <div className="client-field">Profile Status</div>
                                    <div>:</div>
                                </div>
                                <div className="col-9">{percentage ? percentage.toFixed(2) : ''}% Completed</div>
                            </div>
                        </div>
                        <div className="client-form"><Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="Client Information" title="Client Information">
                                <FormParser
                                    modelObject={MastersModal}
                                    formSubmit={formSubmit}
                                    formData={Data}
                                    error={Error}
                                    buttonTitle='Save'
                                    readOnly={readOnly}
                                    setReadOnly={setReadOnly}
                                />
                                {readOnly &&
                                    <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly(false)}>
                                        <div>Edit</div>
                                        <MdOutlineEdit className="ms-5" />
                                    </button>
                                }
                            </Tab>
                            <Tab eventKey="Business Information" title="Business Information">
                                {Data && <BusinessInformation clientsEditData={Data} />}
                            </Tab>
                            <Tab eventKey="Tariff" title="Tariff">
                                <ClientTariffData Code={Code} />
                            </Tab>
                            <Tab eventKey="Institutions" title="Institutions">
                                {Data && <Institution clientData={Data} GetData={GetData} />}
                            </Tab>
                            <Tab eventKey="Printing Preferences" title="Printing Preferences">
                                <PrintingPreference key={key} />
                            </Tab>
                            <Tab eventKey="Billing Information" title="Billing Information">
                                <ClientBillingImformation />
                            </Tab>
                            <Tab eventKey="Staff" title="Staff">
                                <AddStaff />
                            </Tab>
                            <Tab eventKey="Account Settings" title="Account Settings">
                                <AccountSetting />
                            </Tab>
                        </Tabs></div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message="Record Updated Sucessfully" />
                }
            
        </>
    )

}
export { ClientEdit }